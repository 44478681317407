// @ts-nocheck - The following is obfuscated by heap
/* eslint-disable @typescript-eslint/no-unused-expressions */

import { useEffect } from 'react';

import { useCurrentOrganization, useCurrentSession } from './auth';

export const useHeap = () => {
  const { currentSession } = useCurrentSession();
  const [currentOrganization] = useCurrentOrganization();

  useEffect(() => {
    if (!import.meta.env.VITE_HEAP_ENVIRONMENT_ID) return;

    if (currentSession && currentOrganization) {
      if (window.heap) return;

      (window.heap = window.heap || []),
        (heap.load = function (e, t) {
          (window.heap.appid = e), (window.heap.config = t = t || {});
          const r = document.createElement('script');
          (r.type = 'text/javascript'),
            (r.async = !0),
            (r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js');
          const a = document.getElementsByTagName('script')[0];
          a.parentNode.insertBefore(r, a);
          for (
            let n = function (e) {
                return function () {
                  heap.push([e].concat(Array.prototype.slice.call(arguments, 0))); // eslint-disable-line prefer-rest-params
                };
              },
              p = [
                'addEventProperties',
                'addUserProperties',
                'clearEventProperties',
                'identify',
                'resetIdentity',
                'removeEventProperty',
                'setEventProperties',
                'track',
                'unsetEventProperty',
              ],
              o = 0;
            o < p.length;
            o++
          )
            heap[p[o]] = n(p[o]);
        });

      heap.load(import.meta.env.VITE_HEAP_ENVIRONMENT_ID);
      heap.identify(currentSession.email);
      heap.addUserProperties({
        id: currentSession.id,
        name: currentSession.name,
        email: currentSession.email,
        organization: currentOrganization.name,
        roles: currentSession.roles.map((role) => role.role.name),
      });
    }
  }, [currentSession, currentOrganization]);
};
