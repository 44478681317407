import { useContext } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import InputSearch from '@/design_system/InputSearch';
import Stack from '@/design_system/Stack';
import {
  type ActionTypeOrganizationWithRelations,
  type PackActionTypeOrganizationWithRelations,
  useActionTypes,
} from '@/models/actionType';
import { useCreateAction } from '@/models/article';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import useDebouncedState from '@/utils/useDebouncedState';

import { ActionsTableContext } from './ActionsTable/ActionsTable';
import {
  ActionDropdownItem,
  CustomActionDropdownItem,
} from './ActionTypeSearchSelect/ActionDropdownItem';
import { PackActionDropdownItem } from './ActionTypeSearchSelect/PackActionDropdownItem';

type ActionTypeOption =
  | PackActionTypeOrganizationWithRelations
  | ActionTypeOrganizationWithRelations;

export const ActionTypeSearchDialog = ({
  isOpen,
  setIsOpen,
  setIsOpenCustomActionDialog,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsOpenCustomActionDialog: (isOpen: boolean) => void;
}) => {
  const { _ } = useLingui();
  const { workflow } = useRequestContext();
  const { article, request } = useArticleContext();
  const [query, debouncedQuery, setQuery] = useDebouncedState<string>('', 500);

  const { mode } = useContext(ActionsTableContext);

  const actionTypeQueryMode = mode === 'need' ? 'needName' : 'both';

  const {
    data: { actionTypes, packActionTypes } = { actionTypes: [], packActionTypes: [] },
    isFetching,
  } = useActionTypes(
    {
      requestId: article.requestId,
      query: debouncedQuery || undefined,
      queryMode: debouncedQuery ? actionTypeQueryMode : undefined,
      productL1: article.productL1 ?? undefined,
      productL2: article.productL2 ?? undefined,
      productL3: article.productL3 ?? undefined,
    },
    {
      keepPreviousData: true,
    }
  );

  const actionTypeOptions = [...packActionTypes, ...actionTypes];
  const allowCustomActions = !!workflow?.config.allowCustomActions;

  const { mutate: createAction } = useCreateAction({
    articleId: article.id,
    requestId: request.id,
  });

  const handleAddAction = (id: string) => {
    if (id) {
      const newAction = actionTypes?.find(
        (actionTypeOrganization) => actionTypeOrganization.id === id
      );
      const newPackAction = packActionTypes?.find(
        (packActionTypeOrganization) => packActionTypeOrganization.id === id
      );

      if (newAction) {
        createAction({
          actionTypeOrganization: newAction,
        });
      } else if (newPackAction) {
        createAction({
          packActionTypeOrganization: newPackAction,
        });
      } else {
        createAction({
          description: '',
        });
      }

      setIsOpen(false);
    }
  };

  return (
    <Dialog
      title={_(msg({ id: 'action-type-search-dialog.actions.add', message: 'Add an action' }))}
      isOpen={isOpen}
      onOpenChange={() => setIsOpen(false)}
      fullHeight
    >
      <main>
        <Stack gap="0.5rem">
          <InputSearch
            aria-label={_(
              msg({ id: 'action-type-search-dialog.add-action.label', message: 'Add an action' })
            )}
            placeholder={_(
              mode === 'need'
                ? msg({
                    id: 'action-type-search-dialog.actions.placeholder.defect',
                    message: 'Search a need or defect...',
                  })
                : msg({
                    id: 'action-type-search-dialog.actions.placeholder',
                    message: 'Search a care or repair action...',
                  })
            )}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            isLoading={isFetching || query !== debouncedQuery}
            value={query}
            onChange={setQuery}
          />

          {actionTypeOptions.length === 0 && (
            <span className="paragraph-100-regular text-secondary" style={{ padding: '0.5rem 0' }}>
              <Trans id="action-type-search-dialog.actions.no-results">
                No results matching your search
              </Trans>
            </span>
          )}

          <ActionTypesMenu actionTypeOptions={actionTypeOptions} onAddAction={handleAddAction} />
        </Stack>
      </main>

      {allowCustomActions && (
        <footer>
          <Button variant="secondary" onPress={() => setIsOpenCustomActionDialog(true)}>
            <CustomActionDropdownItem style={{ display: 'flex', justifyContent: 'center' }} />
          </Button>
        </footer>
      )}
    </Dialog>
  );
};

const ActionTypesMenu = ({
  actionTypeOptions,
  onAddAction,
}: {
  actionTypeOptions: ActionTypeOption[];
  onAddAction: (actionTypeOptionId: string) => void;
}) => {
  return (
    <Stack>
      {actionTypeOptions.map((actionTypeOption) => (
        <ActionTypeMenuItem
          key={actionTypeOption.id}
          actionTypeOption={actionTypeOption}
          onAddAction={onAddAction}
        />
      ))}
    </Stack>
  );
};

const ActionTypeMenuItem = ({
  actionTypeOption,
  onAddAction,
}: {
  actionTypeOption: ActionTypeOption;
  onAddAction: (actionTypeOptionId: string) => void;
}) => {
  return (
    <Button
      style={{ padding: '0.5rem 1rem' }}
      variant="style-less"
      onPress={() => onAddAction(actionTypeOption.id)}
    >
      {'actions' in actionTypeOption ? (
        <PackActionDropdownItem packAction={actionTypeOption} />
      ) : (
        <ActionDropdownItem action={actionTypeOption} />
      )}
    </Button>
  );
};
