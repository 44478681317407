import { Trans } from '@lingui/macro';

import { TransitStepConfig } from '@/api';
import Stack from '@/design_system/Stack';
import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const CreateShipmentTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  const stepConfig = article.step!.config as TransitStepConfig['config'];

  return (
    <BaseMessage
      article={article}
      title={<Trans id="article.task.create-shipment.title">Ship the package</Trans>}
      message={
        <Stack gap="0.5rem" alignItems="flex-start">
          <p>
            {stepConfig.destinationType === 'client' && (
              <Trans id="article.task.create-shipment.text.to-client">
                Create a new shipment to send the item to the client.
              </Trans>
            )}
            {stepConfig.destinationType === 'store' && (
              <Trans id="article.task.create-shipment.text.to-store">
                Create a new shipment to send the item to the store.
              </Trans>
            )}
            {stepConfig.destinationType === 'workshop' && stepConfig.originType !== 'workshop' && (
              <Trans id="article.task.create-shipment.text.to-workshop">
                Create a new shipment to send the item to the workshop.
              </Trans>
            )}
            {stepConfig.destinationType === 'workshop' && stepConfig.originType === 'workshop' && (
              <Trans id="article.task.create-shipment.text.workshop-to-workshop">
                Create a new shipment to send the item to another workshop.
              </Trans>
            )}
          </p>
        </Stack>
      }
    />
  );
};
