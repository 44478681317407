import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import IconPackage from '@/icons/Package.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const WorkshopShipmentToCreateNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'workshop_shipment_to_create'>;
}) => {
  const { _ } = useLingui();

  return (
    <BaseNotification
      notificationLabel={_(
        msg({
          id: 'notification.workshop-shipment-to-create.label',
          message: `New shipment to create for request ${notification.request.reference}`,
        })
      )}
      icon={<IconPackage />}
      type="new"
      notification={notification}
      notificationHeader={
        <Trans id="notification.workshop-shipment-to-create">
          <span className="paragraph-100-medium">You</span> have a new shipment to create
        </Trans>
      }
    />
  );
};
