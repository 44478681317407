import { Plural, Trans } from '@lingui/macro';

import AlertBar from '@/design_system/AlertBar';
import Stack from '@/design_system/Stack';
import { ShipmentWithRelations } from '@/models/shipment';
import { ShipmentMessageTitle } from '@/routes/Shipments/Shipment/components/ShipmentStep/components/ShipmentMessageTitle';

const ValidatedMessage = ({
  shipment,
  hideIcon,
  className,
}: {
  shipment: ShipmentWithRelations;
  hideIcon?: boolean;
  className?: string;
}) => {
  const totalArticles = shipment.articles.length;
  const articlesWithIssue = shipment.articles.filter((article) => !!article.issue).length;

  return (
    <AlertBar
      type="success"
      size="large"
      hideIcon={hideIcon}
      className={className}
      title={
        <ShipmentMessageTitle shipment={shipment}>
          <Trans id="shipment.validated-message.title">Shipment completed</Trans>
        </ShipmentMessageTitle>
      }
    >
      <Stack gap="0.5rem" alignItems="flex-start">
        <p>
          <Trans id="shipment.validated-message.text.delivered">The package was delivered.</Trans>{' '}
          {!!articlesWithIssue && (
            <>
              {totalArticles === 1 && articlesWithIssue === 1 ? (
                <Trans id="shipment.validated-message.text.issue">
                  A problem has been reported during the verification.
                </Trans>
              ) : (
                <Trans id="shipment.validated-message.text.issue.multiple">
                  A problem has been reported on{' '}
                  <Plural value={articlesWithIssue} one="# item" other="# items" /> during the
                  verification.
                </Trans>
              )}
              <br />
              <Trans id="shipment.validated-message.text.archived">
                The shipment has been automatically archived.
              </Trans>
            </>
          )}
        </p>
      </Stack>
    </AlertBar>
  );
};

export default ValidatedMessage;
