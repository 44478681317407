import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';

import { Endpoints, MediumType } from '@/api';
import { useFetch } from '@/utils/fetch';

import { Model } from './model';

export class Medium extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  type!: MediumType;
  originalPath!: string;
  undeletable!: boolean;
  size!: number;
  url!: string;

  requestId!: string | null;
  articleId!: string | null;
  articleDefects?: { id: string }[];
  articleActions?: { id: string }[];
  userId!: string | null;

  creatorId!: string | null;
  clientCreatorId!: string | null;

  createdAt!: string;

  get createdAtDate() {
    return new Date(this.createdAt);
  }

  get isImage() {
    return /\.(jpg|jpeg|png|webp|jfif)$/.test(this.originalPath);
  }
}

export const useMedia = (params: Endpoints['GET /media']['query']) => {
  const fetch = useFetch<Endpoints['GET /media']>();

  return useQuery({
    queryKey: ['media', params],
    queryFn: () =>
      fetch('/media', params).then(({ media, meta }) => ({
        media: media.map((medium) => new Medium(medium)),
        meta,
      })),
    enabled: !!params.requestId || !!params.articleId || !!params.userId || !!params.creatorId,
    // A media is mostly never updated, so we can safely disable the automatic refetch
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
};

export const useMediaQueries = (paramsList: Endpoints['GET /media']['query'][]) => {
  const fetch = useFetch<Endpoints['GET /media']>();

  return useQueries({
    queries: paramsList.map((params) => ({
      queryKey: ['media', params],
      queryFn: () =>
        fetch('/media', params).then(({ media, meta }) => ({
          media: media.map((medium) => new Medium(medium)),
          meta,
        })),
      enabled: !!params.articleId || !!params.userId,
    })),
  });
};

export type CreateMediumBody = Endpoints['POST /media']['body'];

export const useCreateMedium = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch();

  return useMutation({
    mutationFn: (body: CreateMediumBody & { file: File }) =>
      fetch(
        '/media',
        undefined,
        {
          method: 'POST',
          body: Object.fromEntries(Object.entries(body).filter(([, value]) => value !== undefined)),
        },
        'multipart/form-data'
      ).then((medium) => new Medium(medium)),
    onSettled: (_data, _error, body) => {
      queryClient.invalidateQueries({ queryKey: ['media'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });

      if (body.articleId) {
        queryClient.invalidateQueries({ queryKey: ['requests'] });
      }
    },
  });
};

export const useDeleteMedium = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch();

  return useMutation({
    mutationFn: (id: string) => fetch(`/media/${id}`, undefined, { method: 'DELETE' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['media'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};
