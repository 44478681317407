import { useEffect, useRef } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { ErrorBoundary } from '@sentry/react';
import { isBefore, isToday } from 'date-fns';
import SimpleBar from 'simplebar-react';

import { Activity, ActivityOfType, stackSimilarActivities } from '@/components/activities/Activity';
import { ArticleAddedToShipmentActivity } from '@/components/activities/ArticleAddedToShipmentActivity';
import { ArticleAnalysisCompletedActivity } from '@/components/activities/ArticleAnalysisCompletedActivity';
import { ArticleClientCommentUpdatedActivity } from '@/components/activities/ArticleClientCommentUpdatedActivity';
import { ArticleFeedbackSubmittedActivity } from '@/components/activities/ArticleFeedbackSubmittedActivity';
import { ArticleManuallyArchivedActivity } from '@/components/activities/ArticleManuallyArchivedActivity';
import { ArticleMediumAddedActivity } from '@/components/activities/ArticleMediumAddedActivity';
import { ArticleMediumDeletedActivity } from '@/components/activities/ArticleMediumDeletedActivity';
import { ArticlePaymentChoiceActivity } from '@/components/activities/ArticlePaymentChoiceActivity';
import { ArticlePropertyUpdatedActivity } from '@/components/activities/ArticlePropertyUpdatedActivity';
import { ArticleRemovedFromShipmentActivity } from '@/components/activities/ArticleRemovedFromShipmentActivity';
import { ArticleRepairCompletedActivity } from '@/components/activities/ArticleRepairCompletedActivity';
import { ArticleRequalificationAutoValidatedActivity } from '@/components/activities/ArticleRequalificationAutoValidatedActivity';
import { ArticleRequalificationRefusedActivity } from '@/components/activities/ArticleRequalificationRefusedActivity';
import { ArticleRequalificationValidatedActivity } from '@/components/activities/ArticleRequalificationValidatedActivity';
import { ArticleRequalifiedActivity } from '@/components/activities/ArticleRequalifiedActivity';
import { ArticleSentToZendeskActivity } from '@/components/activities/ArticleSentToZendeskActivity';
import { ArticleServiceChoiceCompletedActivity } from '@/components/activities/ArticleServiceChoiceCompletedActivity';
import { ArticleValidationChoiceActivity } from '@/components/activities/ArticleValidationChoiceActivity';
import { ArticleWorkshopUpdatedActivity } from '@/components/activities/ArticleWorkshopUpdatedActivity';
import { ClientPickupCompletedActivity } from '@/components/activities/ClientPickupCompletedActivity';
import { CommentAddedActivity } from '@/components/activities/CommentAddedActivity';
import { FeedbackSubmittedActivity } from '@/components/activities/FeedbackSubmittedActivity';
import { JobAcceptedActivity } from '@/components/activities/JobAcceptedActivity';
import { JobAutoAcceptedActivity } from '@/components/activities/JobAutoAcceptedActivity';
import { JobRefusedActivity } from '@/components/activities/JobRefusedActivity';
import { JobSentForAcceptationActivity } from '@/components/activities/JobSentForAcceptationActivity';
import { PaymentCompletedActivity } from '@/components/activities/PaymentCompletedActivity';
import { PendingBillPaymentNotifiedActivity } from '@/components/activities/PendingBillPaymentNotifiedActivity';
import { PendingValidationNotifiedActivity } from '@/components/activities/PendingValidationNotifiedActivity';
import { RequestAutomaticallyArchivedActivity } from '@/components/activities/RequestAutomaticallyArchivedActivity';
import { RequestClaimedActivity } from '@/components/activities/RequestClaimedActivity';
import { RequestCompletedActivity } from '@/components/activities/RequestCompletedActivity';
import { RequestCreatedActivity } from '@/components/activities/RequestCreatedActivity';
import { RequestManuallyArchivedActivity } from '@/components/activities/RequestManuallyArchivedActivity';
import { RequestMediumAddedActivity } from '@/components/activities/RequestMediumAddedActivity';
import { RequestMediumDeletedActivity } from '@/components/activities/RequestMediumDeletedActivity';
import { RequestPropertyUpdatedActivity } from '@/components/activities/RequestPropertyUpdatedActivity';
import { RequestSentToZendeskActivity } from '@/components/activities/RequestSentToZendeskActivity';
import { ShipmentArticleVerifiedActivity } from '@/components/activities/ShipmentArticleVerifiedActivity';
import { ShipmentCreatedActivity } from '@/components/activities/ShipmentCreatedActivity';
import { ShipmentFinalisationCompletedActivity } from '@/components/activities/ShipmentFinalisationCompletedActivity';
import { ShipmentHandoverCompletedActivity } from '@/components/activities/ShipmentHandoverCompletedActivity';
import { ShipmentIssueReported } from '@/components/activities/ShipmentIssueReported';
import { ShipmentIssueResolvedActivity } from '@/components/activities/ShipmentIssueResolvedActivity';
import { ShipmentPreparationCompletedActivity } from '@/components/activities/ShipmentPreparationCompletedActivity';
import { ShipmentTransitCompletedActivity } from '@/components/activities/ShipmentTransitCompletedActivity';
import { ShipmentVerificationCompletedActivity } from '@/components/activities/ShipmentVerificationCompletedActivity';
import Stack from '@/design_system/Stack';
import { createBEMClasses } from '@/utils/classname';
import { formatDate, fromNow } from '@/utils/date';
import useViewPort from '@/utils/useViewport';

import './Activities.css';

const { block, element } = createBEMClasses('activities');

export const Activities = ({
  activities,
  isLoading,
  isError,
  isShipment,
  isArticle,
  nbOfArticles,
  loadNextPage,
}: {
  activities: Activity[];
  isLoading?: boolean;
  isError?: boolean;
  isShipment?: boolean;
  isArticle?: boolean;
  nbOfArticles?: number;
  loadNextPage?: () => void;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const loadNextPageTriggerRef = useRef(null);

  useEffect(() => {
    if (loadNextPageTriggerRef.current === null) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadNextPage?.();
        }
      },
      {
        // Root is the viewport
        root: null,
        threshold: 1.0,
      }
    );

    observer.observe(loadNextPageTriggerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [loadNextPageTriggerRef, loadNextPage]);

  if (isError) {
    return (
      <p style={{ margin: '16px 32px' }} className="paragraph-100-regular text-disabled">
        <Trans id="activities.error">An error occurred when loading activities</Trans>
      </p>
    );
  }

  if (isLoading) {
    return (
      <p style={{ margin: '16px 32px' }} className="paragraph-100-regular text-disabled">
        <Trans id="activities.loading">Loading</Trans>
      </p>
    );
  }

  if (activities.length === 0) {
    return (
      <p style={{ margin: '16px 32px' }} className="paragraph-100-regular text-disabled">
        <Trans id="activities.no-activities">No activities yet</Trans>
      </p>
    );
  }

  const activitiesGroupedByDate = activities.reduce(
    (acc, activity) => {
      const date = new Date(activity.createdAt).toDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(activity);
      return acc;
    },
    {} as Record<string, Activity[]>
  );

  return (
    <SimpleBar
      style={{
        padding: isMobile
          ? undefined
          : '0 1rem 1.5rem 1rem' /* eslint-disable-line lingui/no-unlocalized-strings */,
        maxHeight: isMobile ? undefined : '30rem',
      }}
      autoHide={false}
    >
      <Stack className={block()}>
        {Object.entries(activitiesGroupedByDate).map(([activityDate, dailyActivities]) => {
          const date = new Date(activityDate);
          let dateLabel = fromNow(date);
          if (isToday(date)) {
            dateLabel = _(msg({ id: 'activities.today', message: 'Today' }));
          }
          // if date is more than 3 days ago just display the date
          else if (isBefore(date, new Date(Date.now() - 3 * 24 * 60 * 60 * 1000))) {
            dateLabel = formatDate(date, {
              dateStyle: 'medium',
            });
          }

          return (
            <Stack key={activityDate}>
              <div className={element('section-header', {}, 'paragraph-100-medium')}>
                {dateLabel}
              </div>
              <div>
                {stackSimilarActivities(dailyActivities).map((activity) => (
                  <ErrorBoundary key={activity[0].id}>
                    <ActivityComponent
                      activities={activity}
                      isShipment={!!isShipment}
                      isArticle={!!isArticle}
                      nbOfArticles={nbOfArticles}
                    />
                  </ErrorBoundary>
                ))}
              </div>
            </Stack>
          );
        })}
        <div className={element('load_next_page_trigger')} ref={loadNextPageTriggerRef}></div>
      </Stack>
    </SimpleBar>
  );
};

const ActivityComponent = ({
  activities,
  isShipment,
  isArticle,
  nbOfArticles,
}: {
  activities: Activity[];
  isShipment: boolean;
  isArticle: boolean;
  nbOfArticles?: number;
}) => {
  const displayArticleName = !isArticle && !!nbOfArticles && nbOfArticles > 1;
  const activity = activities[0];

  switch (activity.type) {
    case 'request_created':
      return <RequestCreatedActivity activity={activity} />;

    case 'request_medium_added':
      return <RequestMediumAddedActivity activity={activity} />;

    case 'request_medium_deleted':
      return <RequestMediumDeletedActivity activity={activity} />;

    case 'article_medium_added':
      return (
        <ArticleMediumAddedActivity activity={activity} displayArticleName={displayArticleName} />
      );

    case 'article_medium_deleted':
      return (
        <ArticleMediumDeletedActivity activity={activity} displayArticleName={displayArticleName} />
      );

    case 'article_workshop_updated':
      return (
        <ArticleWorkshopUpdatedActivity
          activity={activity}
          displayArticleName={displayArticleName}
        />
      );

    case 'article_service_choice_completed':
      return (
        <ArticleServiceChoiceCompletedActivity
          activity={activity}
          displayArticleName={displayArticleName}
        />
      );

    case 'job_sent_for_acceptation':
      return (
        <JobSentForAcceptationActivity
          activities={activities as ActivityOfType<'job_sent_for_acceptation'>[]}
        />
      );

    case 'job_accepted':
      return <JobAcceptedActivity activities={activities as ActivityOfType<'job_accepted'>[]} />;

    case 'job_auto_accepted':
      return (
        <JobAutoAcceptedActivity activities={activities as ActivityOfType<'job_auto_accepted'>[]} />
      );

    case 'job_refused':
      return <JobRefusedActivity activity={activity} displayArticleName={displayArticleName} />;

    case 'payment_completed':
      return <PaymentCompletedActivity activity={activity} />;

    case 'shipment_created':
      return <ShipmentCreatedActivity activity={activity} isShipment={isShipment} />;

    case 'shipment_preparation_completed':
      return <ShipmentPreparationCompletedActivity activity={activity} />;

    case 'shipment_finalisation_completed':
      return <ShipmentFinalisationCompletedActivity activity={activity} />;

    case 'shipment_handover_completed':
      return <ShipmentHandoverCompletedActivity activity={activity} />;

    case 'shipment_transit_completed':
      return <ShipmentTransitCompletedActivity activity={activity} />;

    case 'shipment_verification_completed':
      return <ShipmentVerificationCompletedActivity activity={activity} />;

    case 'article_added_to_shipment':
      return (
        <ArticleAddedToShipmentActivity
          activity={activity}
          displayArticleName={displayArticleName}
        />
      );

    case 'article_removed_from_shipment':
      return (
        <ArticleRemovedFromShipmentActivity
          activity={activity}
          displayArticleName={displayArticleName}
        />
      );

    case 'shipment_issue_reported':
      return <ShipmentIssueReported activity={activity} displayArticleName={displayArticleName} />;

    case 'article_client_comment_updated':
      return (
        <ArticleClientCommentUpdatedActivity
          activity={activity}
          displayArticleName={displayArticleName}
        />
      );

    case 'shipment_article_verified':
      return (
        <ShipmentArticleVerifiedActivity
          activity={activity}
          displayArticleName={displayArticleName}
        />
      );

    case 'article_analysis_completed':
      return (
        <ArticleAnalysisCompletedActivity
          activity={activity}
          displayArticleName={displayArticleName}
        />
      );

    case 'article_repair_completed':
      return (
        <ArticleRepairCompletedActivity
          activity={activity}
          displayArticleName={displayArticleName}
        />
      );

    case 'request_property_updated':
      return <RequestPropertyUpdatedActivity activity={activity} />;

    case 'article_property_updated':
      return (
        <ArticlePropertyUpdatedActivity
          activity={activity}
          displayArticleName={displayArticleName}
        />
      );

    case 'comment_added':
      return (
        <CommentAddedActivity activity={activity} isShipment={isShipment} isArticle={isArticle} />
      );

    case 'client_pickup_completed':
      return <ClientPickupCompletedActivity activity={activity} nbOfArticles={nbOfArticles ?? 0} />;

    case 'request_completed':
      return <RequestCompletedActivity activity={activity} />;

    case 'request_manually_archived':
      return <RequestManuallyArchivedActivity activity={activity} />;

    case 'request_automatically_archived':
      return <RequestAutomaticallyArchivedActivity activity={activity} />;

    case 'pending_validation_notified':
      return <PendingValidationNotifiedActivity activity={activity} />;

    case 'pending_bill_payment_notified':
      return <PendingBillPaymentNotifiedActivity activity={activity} />;

    case 'feedback_submitted':
      return <FeedbackSubmittedActivity activity={activity} />;

    case 'article_feedback_submitted':
      return <ArticleFeedbackSubmittedActivity activity={activity} />;

    case 'shipment_issue_resolved':
      return (
        <ShipmentIssueResolvedActivity
          activity={activity}
          displayArticleName={displayArticleName}
        />
      );

    case 'article_manually_archived':
      return (
        <ArticleManuallyArchivedActivity
          activity={activity}
          displayArticleName={displayArticleName}
        />
      );

    case 'article_requalified':
      return (
        <ArticleRequalifiedActivity activity={activity} displayArticleName={displayArticleName} />
      );

    case 'article_requalification_refused':
      return (
        <ArticleRequalificationRefusedActivity
          activity={activity}
          displayArticleName={displayArticleName}
        />
      );

    case 'article_requalification_validated':
      return (
        <ArticleRequalificationValidatedActivity
          activity={activity}
          displayArticleName={displayArticleName}
        />
      );

    case 'article_requalification_auto_validated':
      return (
        <ArticleRequalificationAutoValidatedActivity
          activity={activity}
          displayArticleName={displayArticleName}
        />
      );

    case 'request_sent_to_zendesk':
      return <RequestSentToZendeskActivity activity={activity} />;

    case 'request_claimed':
      return <RequestClaimedActivity activity={activity} />;

    case 'article_payment_choice':
      return (
        <ArticlePaymentChoiceActivity
          activities={activities as ActivityOfType<'article_payment_choice'>[]}
          displayArticleName={displayArticleName}
        />
      );

    case 'article_validation_choice':
      return (
        <ArticleValidationChoiceActivity
          activities={activities as ActivityOfType<'article_validation_choice'>[]}
          displayArticleName={displayArticleName}
        />
      );

    case 'article_sent_to_zendesk':
      return (
        <ArticleSentToZendeskActivity activity={activity} displayArticleName={displayArticleName} />
      );

    default:
      // Exhaustive check to ensure all activity types are handled
      // eslint-disable-next-line no-case-declarations
      const _exhaustiveCheck: never = activity;
      return _exhaustiveCheck && null;
  }
};
