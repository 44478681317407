import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import FileUpload from '@/components/FileUpload';
import IconPicture from '@/icons/Picture.svg';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';

export const ArticlePhotoUpload = () => {
  const { _ } = useLingui();

  const { article, errors } = useArticleContext();

  return (
    <FileUpload
      uploadData={{
        type: 'article-photo',
        articleId: article.id,
      }}
      type="photo"
      label={_(
        msg({
          id: 'article.form.article-photo.label',
          message: 'Full item picture',
        })
      )}
      prompt={_(
        msg({
          id: 'article.form.article-photo.prompt',
          message: 'Add a full view of the item',
        })
      )}
      icon={<IconPicture />}
      maxNumberOfMedia={1}
      allowsMultiple={false}
      media={article.articlePhoto ? [article.articlePhoto] : []}
      size="large"
      error={
        errors.details?.missingArticlePhoto
          ? _(
              msg({
                id: 'requests.articles.form.error.photo',
                message: 'Please add a full item picture',
              })
            )
          : undefined
      }
      deleteWithApi
    />
  );
};
