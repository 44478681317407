import { Button as AriaButton } from 'react-aria-components';

import IconCancel from '@/icons/Cancel.svg';
import { createBEMClasses } from '@/utils/classname';

import './Chip.css';

const { block } = createBEMClasses('chip');

interface ChipProps {
  size?: 'large' | 'small' | 'x-small';
  children: React.ReactNode;
  selected?: boolean;
  onCancel?: () => void;
  isDisabled?: boolean;
  style?: React.CSSProperties;
}

const Chip = ({ size = 'small', children, selected, onCancel, isDisabled, style }: ChipProps) => {
  const className = block({
    size,
    selected,
  });

  if (onCancel) {
    return (
      <AriaButton
        id=""
        onPress={() => {
          if (!isDisabled) {
            onCancel();
          }
        }}
        className={className}
        style={style}
      >
        {children}
        {!isDisabled && <IconCancel />}
      </AriaButton>
    );
  }

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export default Chip;
