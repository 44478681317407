import { useState } from 'react';
import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import { ShipmentWithRelations, useCompleteVerification } from '@/models/shipment';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

const VerificationActions = ({
  shipment,
  onVerificationDone,
}: {
  shipment: ShipmentWithRelations;
  onVerificationDone: () => void;
}) => {
  const { isMobile } = useViewPort();
  const { mutateAsync: completeVerification, isPending, isSuccess } = useCompleteVerification();
  const [showError, setShowError] = useState(false);

  const { currentSession } = useCurrentSession();
  const canVerifyReception = shipment.canReceptionBeVerifiedBy(currentSession);

  if (!canVerifyReception) {
    return null;
  }

  const hasError = !shipment.articles.every((article) => article.verified);

  return (
    <>
      {showError && hasError && (
        <Message type="error">
          <Trans id="shipment.verification.error">
            Please verify all the items before marking the package verification as complete.
          </Trans>
        </Message>
      )}

      <Stack row={!isMobile} justifyContent="flex-end" gap={isMobile ? '0.5rem' : '1rem'}>
        <Button
          variant="primary"
          onPress={async () => {
            setShowError(true);

            if (hasError) {
              return;
            }

            await completeVerification(shipment.id);
            onVerificationDone();
          }}
          isLoading={isPending || isSuccess}
        >
          <Trans id="shipment.verification.conclude">Mark verification as completed</Trans>
        </Button>
      </Stack>
    </>
  );
};

export default VerificationActions;
