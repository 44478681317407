import { useEffect } from 'react';
import { Link } from 'react-aria-components';
import { Trans } from '@lingui/macro';
import { useQueryClient } from '@tanstack/react-query';

import AlertBar from '@/design_system/AlertBar';
import { RequestWithRelations, useActivities } from '@/models/request';

export const ArchivedStepMessage = ({ request }: { request: RequestWithRelations }) => {
  const allArticlesCompleted = request.allArticles.every(
    (article) => article?.archivalDetail?.type === 'completed' && !article.cancellationDetail
  );

  if (allArticlesCompleted) {
    return <CompletedMessage />;
  }

  const allArticlesCancelled = request.allArticles.every(
    (article) => article?.cancellationDetail?.type === 'requestor_refusal'
  );

  if (allArticlesCancelled) {
    return <CancelledMessage />;
  }

  if (request.isManuallyArchived) {
    return <ManuallyArchivedMessage request={request} />;
  }

  if (request.isAutomaticallyArchived) {
    return <AutomaticallyArchivedRequest request={request} />;
  }

  return null;
};

const CompletedMessage = () => {
  return (
    <AlertBar
      type="success"
      size="large"
      hideIcon
      title={<Trans id="requests.archived-message.completed.title">The request is completed</Trans>}
    />
  );
};

const CancelledMessage = () => {
  return (
    <AlertBar
      type="error"
      size="large"
      hideIcon
      title={
        <Trans id="requests.archived-message.client-refusal.title">
          The quote has been refused by the client
        </Trans>
      }
    >
      <p>
        <Trans id="request.archived-message.client-refusal.archive-info">
          The request has been archived automatically.
        </Trans>
      </p>
    </AlertBar>
  );
};

const ManuallyArchivedMessage = ({ request }: { request: RequestWithRelations }) => {
  const archivedDueToShipmentIssue = request.allArticles.every(
    (article) => article.step?.step === 'transit' && article.hasIssue
  );

  const exportedToZendesk = request.archivedArticles.every(
    (article) => article.archivalDetail?.reason === 'export-to-zendesk'
  );

  if (exportedToZendesk) {
    return <ExportedToZendesk request={request} type="manual" />;
  }

  return (
    <AlertBar
      type="error"
      size="large"
      hideIcon
      title={<Trans id="requests.archived-message.manual.title">The request is archived</Trans>}
    >
      <p>
        {archivedDueToShipmentIssue && (
          <>
            <Trans id="requests.archived-message.manual.text.shipment-issue">
              An issue occurred during the shipment verification.
            </Trans>{' '}
          </>
        )}
        <Trans id="requests.archived-message.manual.text">
          The request has been manually archived before its completion.
        </Trans>
      </p>
    </AlertBar>
  );
};

const AutomaticallyArchivedRequest = ({ request }: { request: RequestWithRelations }) => {
  const exportedToZendesk = request.archivedArticles.every(
    (article) => article.archivalDetail?.reason === 'export-to-zendesk'
  );

  if (exportedToZendesk) {
    return <ExportedToZendesk request={request} type="automatic" />;
  }

  return (
    <AlertBar
      type="error"
      size="large"
      hideIcon
      title={
        <Trans id="requests.archived-message.automatic.title">
          This request has been automatically archived
        </Trans>
      }
    />
  );
};

const ExportedToZendesk = ({
  request,
  type,
}: {
  request: RequestWithRelations;
  type?: 'automatic' | 'manual';
}) => {
  const { data: { activities } = { activities: [] } } = useActivities(
    {
      requestId: request.id,
      types: ['request_sent_to_zendesk', 'article_sent_to_zendesk'],
    },
    {
      refetchInterval: (query) => (query.state.data?.meta.count === 0 ? 5000 : false),
    }
  );

  const zendeskTickets =
    activities
      .flatMap((activity) => {
        if (activity.type === 'request_sent_to_zendesk') {
          return activity.data.zendeskTickets;
        } else if (activity.type === 'article_sent_to_zendesk') {
          return [activity.data];
        }
        return [];
      })
      .map((zendeskTicket) => ({
        id: zendeskTicket.ticket.id,
        url: `${request.organization.config.integrations?.zendesk?.baseUrl}/agent/tickets/${zendeskTicket.ticket.id}`,
      })) ?? [];

  const queryClient = useQueryClient();

  useEffect(() => {
    if (zendeskTickets.length > 0) {
      queryClient.refetchQueries({
        queryKey: ['activities', request.id],
        exact: true,
      });
    }
  }, [queryClient, request.id, zendeskTickets.length]);

  if (zendeskTickets.length === 0) {
    return (
      <AlertBar
        type="error"
        size="large"
        hideIcon
        title={
          type === 'automatic' ? (
            <Trans id="requests.archived-message.automatic-zendesk.title.loading">
              This request has been automatically archived and is being transferred to Zendesk
            </Trans>
          ) : (
            <Trans id="requests.archived-message.manual-zendesk.title.loading">
              This request has been manually archived and is being transferred to Zendesk
            </Trans>
          )
        }
      />
    );
  }

  return (
    <AlertBar
      type="error"
      size="large"
      hideIcon
      title={
        type === 'automatic' ? (
          <Trans id="requests.archived-message.automatic-zendesk.title">
            This request has been automatically archived and transferred to Zendesk
          </Trans>
        ) : (
          <Trans id="requests.archived-message.manual-zendesk.title">
            This request has been manually archived and transferred to Zendesk
          </Trans>
        )
      }
    >
      {zendeskTickets.length === 1 && (
        <p>
          <Trans id="requests.archived-message.zendesk.ticket">
            Please find attached the link to the related Zendesk ticket:
          </Trans>{' '}
          <Link target="_blank" href={zendeskTickets[0].url} style={{ color: 'inherit' }}>
            {zendeskTickets[0].id}
          </Link>
        </p>
      )}
      {zendeskTickets.length > 1 && (
        <>
          <p>
            <Trans id="requests.archived-message.zendesk.ticket.many">
              Please find attached the links to the related Zendesk tickets:
            </Trans>
          </p>
          <ul>
            {zendeskTickets.map((ticket) => (
              <li key={ticket.id}>
                <Link target="_blank" href={ticket.url} style={{ color: 'inherit' }}>
                  {ticket.id}
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </AlertBar>
  );
};
