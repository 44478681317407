import { ActionsTable } from '@/components/ActionsTable';
import { DefectsTable } from '@/components/DefectsTable';
import Stack from '@/design_system/Stack';
import { ArticleDispatch } from '@/routes/Requests/components/ArticleDispatch';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';

import { ServiceChoice } from './ServiceChoice';
import { ServiceExchange } from './ServiceExchange';
import { ServiceNone } from './ServiceNone';
import { ServiceRefund } from './ServiceRefund';

export const ArticleServices = () => {
  const { view } = useArticleContext();

  return (
    <Stack gap="2rem">
      {view.services.defects.shown && <DefectsTable />}

      {(view.services.choice.shown || view.services.actions.shown) && (
        <Stack gap="1rem">
          {view.services.choice.shown && <ServiceChoice />}

          {view.services.actions.shown && <ActionsTable />}
        </Stack>
      )}

      {view.services.dispatch.shown && <ArticleDispatch />}

      {view.services.none.shown && <ServiceNone />}

      {view.services.exchange.shown && <ServiceExchange />}

      {view.services.refund.shown && <ServiceRefund />}
    </Stack>
  );
};
