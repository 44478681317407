import { useState } from 'react';
import { Button as AriaButton } from 'react-aria-components';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import IconWarning from '@/icons/Warning.svg';
import { createBEMClasses } from '@/utils/classname';

import './FusalpWarrantyModal.css';

const { block, element } = createBEMClasses('fusalp-warranty-modal');

const FusalpWarrantyModal = () => {
  const { _ } = useLingui();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      title={_(
        msg({
          id: 'client.new.article.form.warranty.fusalp.title',
          message: 'Fusalp warranty conditions',
        })
      )}
      trigger={
        <AriaButton
          className={element('trigger', undefined, 'paragraph-100-regular text-secondary')}
        >
          <Trans id="client.new.article.form.warranty.fusalp.conditions">View conditions</Trans>
        </AriaButton>
      }
      className={block()}
    >
      <main className="paragraph-50-regular">
        <Stack gap="1.5rem">
          <div>
            <p>
              <Trans id="client.new.article.form.warranty.fusalp.main.1">
                All Fusalp items are covered by a{' '}
                <span className="paragraph-50-medium">
                  two-year warranty against material and manufacturing defects
                </span>{' '}
                after purchase by the consumer.
              </Trans>
            </p>
            <p>
              <Trans id="client.new.article.form.warranty.fusalp.main.2">
                Since January 2021, we offer a{' '}
                <span className="paragraph-50-medium">
                  five-year warranty extension for any products purchased after this date
                </span>{' '}
                on a list of selected items (
                <a
                  href="https://fusalp.com/blogs/faq/tagged/01-entretien-et-garantie"
                  target="_blank"
                  rel="noreferrer"
                >
                  available here
                </a>
                ).
              </Trans>
            </p>
            <p>
              <Trans id="client.new.article.form.warranty.fusalp.main.3">
                Under these 2 and 5-year warranty periods, Créations Fusalp SA is responsible for
                the repair, exchange, or credit the purchase of, the damaged or defective item(s).
              </Trans>
            </p>
          </div>
          <Stack gap="1.5rem" className={element('warnings')}>
            <div>
              <p className="paragraph-50-medium">
                <IconWarning />
                <Trans id="client.new.article.form.warranty.fusalp.warning.1.title">
                  Fusalp warranty does not cover the following cases:
                </Trans>
              </p>
              <ul>
                <li>
                  <Trans id="client.new.article.form.warranty.fusalp.warning.1.item.1">
                    Normal wear and tear
                  </Trans>
                </li>
                <li>
                  <Trans id="client.new.article.form.warranty.fusalp.warning.1.item.2">
                    Damage caused by negligence
                  </Trans>
                </li>
                <li>
                  <Trans id="client.new.article.form.warranty.fusalp.warning.1.item.3">
                    Accident
                  </Trans>
                </li>
                <li>
                  <Trans id="client.new.article.form.warranty.fusalp.warning.1.item.4">
                    Damage caused by wrong washing or drying
                  </Trans>
                </li>
                <li>
                  <Trans id="client.new.article.form.warranty.fusalp.warning.1.item.5">
                    Damage caused by misuse or abuse
                  </Trans>
                </li>
              </ul>
            </div>
            <div>
              <p className="paragraph-50-medium">
                <IconWarning />
                <Trans id="client.new.article.form.warranty.fusalp.warning.2.title">
                  The 5-year warranty does not apply to:
                </Trans>
              </p>
              <ul>
                <li>
                  <Trans id="client.new.article.form.warranty.fusalp.warning.2.item.1">
                    Products purchased in the outlets stores (2-year warranty, with the exception of
                    second choice products which are not guaranteed)
                  </Trans>
                </li>
                <li>
                  <Trans id="client.new.article.form.warranty.fusalp.warning.2.item.2">
                    Heating devices on &quot;FUTUR&quot; products( 2-year warranty)
                  </Trans>
                </li>
                <li>
                  <Trans id="client.new.article.form.warranty.fusalp.warning.2.item.3">
                    Products from the Chloé x Fusalp collaboration (2-year warranty)
                  </Trans>
                </li>
                <li>
                  <Trans id="client.new.article.form.warranty.fusalp.warning.2.item.4">
                    Leather and wool parts of products (2-year warranty)
                  </Trans>
                </li>
                <li>
                  <Trans id="client.new.article.form.warranty.fusalp.warning.2.item.5">
                    Loss of water repellency or waterproofness (2-year warranty)
                  </Trans>
                </li>
              </ul>
            </div>
          </Stack>
        </Stack>
      </main>
      <footer>
        <Button variant="brand" size="large" onPress={() => setIsOpen(false)}>
          <Trans id="client.new.article.form.warranty.modal.close">Close</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};

export default FusalpWarrantyModal;
