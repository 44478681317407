import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import RadioButtonGroup, {
  yesNoRadioOptions,
} from '@/design_system/RadioButtonGroup/RadioButtonGroup';
import IconEdit from '@/icons/Edit.svg';
import IconTrash from '@/icons/Trash.svg';
import { useDeleteAction, useUpdateAction } from '@/models/article';
import { ArticleActionWithRelations } from '@/models/request';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import useViewPort from '@/utils/useViewport';

export const BrandResponsibilityCell = ({
  action,
  size = 'large',
  isDisabled,
}: {
  action: ArticleActionWithRelations;
  size?: 'small' | 'large';
  isDisabled?: boolean;
}) => {
  const { _ } = useLingui();

  const { article, request } = useArticleContext();

  const { mutate: updateAction } = useUpdateAction({
    articleId: article.id,
    requestId: request.id,
  });

  const selectedOptionValue = action.brandResponsibility ? 'yes' : 'no';

  if (isDisabled) {
    return (
      <p className={size === 'small' ? 'paragraph-200-regular' : 'paragraph-100-regular'}>
        {_(yesNoRadioOptions.find((option) => option.value === selectedOptionValue)!.children)}
      </p>
    );
  }

  return (
    <RadioButtonGroup
      ariaLabel={_(
        msg({
          id: 'article.form.actions.table.column.brand-responsibility.label',
          message: 'Brand responsibility:',
        })
      )}
      value={selectedOptionValue}
      onChange={(option) =>
        updateAction({ actionId: action.id, body: { brandResponsibility: option === 'yes' } })
      }
      options={yesNoRadioOptions.map((option) => ({ ...option, children: _(option.children) }))}
      size={size}
    />
  );
};

export const DeleteCell = ({ action }: { action: ArticleActionWithRelations }) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const { article, request } = useArticleContext();
  const { mutate: deleteAction } = useDeleteAction({
    articleId: article.id,
    requestId: request.id,
  });

  return (
    <Button
      variant="secondary"
      size={isMobile ? 'small' : 'medium'}
      iconOnly
      tooltip={_(msg({ id: 'components.input-quantity.remove', message: 'Remove' }))}
      ariaLabel={_(msg({ id: 'components.input-quantity.remove', message: 'Remove' }))}
      onPress={() => {
        if (
          action.media.length > 0 &&
          !confirm(
            _(
              msg({
                id: 'action-tables.quantity.comfirm-deletion',
                message:
                  'Are you sure you want to delete this action? The associated photos will be deleted as well.',
              })
            )
          )
        ) {
          return;
        }

        deleteAction(action.id);
      }}
    >
      <IconTrash />
    </Button>
  );
};

export const DeleteButton = ({ action }: { action: ArticleActionWithRelations }) => {
  const { _ } = useLingui();
  const { article, request } = useArticleContext();
  const { mutate: deleteAction } = useDeleteAction({
    articleId: article.id,
    requestId: request.id,
  });

  return (
    <Button
      variant="secondary"
      size="medium"
      ariaLabel={_(msg({ id: 'action-tables.action.deletion', message: 'Remove' }))}
      onPress={() => {
        if (
          action.media.length > 0 &&
          !confirm(
            _(
              msg({
                id: 'action-tables.action.confirm-deletion',
                message:
                  'Are you sure you want to delete this action? The associated photos will be deleted as well.',
              })
            )
          )
        ) {
          return;
        }

        deleteAction(action.id);
      }}
    >
      <IconTrash />
      <Trans id="action-tables.action.deletion">Remove</Trans>
    </Button>
  );
};

export const EditButton = ({ onPress }: { onPress: () => void }) => {
  const { _ } = useLingui();

  return (
    <Button
      variant="secondary"
      size="medium"
      ariaLabel={_(
        msg({ id: 'article.form.actions.table.column.edit.label', message: 'Edit action' })
      )}
      onPress={onPress}
    >
      <IconEdit />
      <Trans id="article.form.actions.table.column.edit">Edit</Trans>
    </Button>
  );
};
