import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useQueryClient } from '@tanstack/react-query';

import FileUpload from '@/components/FileUpload';
import IconAdd from '@/icons/Add.svg';
import { Medium } from '@/models/medium';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';

export const DefectPhotos = ({
  action,
  defect,
  size = 'medium',
  isDisabled,
  isInvalid,
  isRequalification,
}: {
  action?: { id: string; media: Medium[] };
  defect?: { id: string; media: Medium[] };
  size?: 'small' | 'medium' | 'large';
  isDisabled?: boolean;
  isInvalid?: boolean;
  isRequalification?: boolean;
}) => {
  const { _ } = useLingui();
  const queryClient = useQueryClient();
  const { request } = useRequestContext();

  return (
    <FileUpload
      type="photo"
      variant="grid"
      size={size}
      uploadData={{
        type: isRequalification ? 'requalification-defect-photo' : 'defect-photo',
        articleActionId: action?.id,
        defectActionId: defect?.id,
      }}
      icon={<IconAdd />}
      ariaLabel={_(
        msg({
          id: 'article.forms.actions.defect-photo.prompt',
          message: 'Add defect photos',
        })
      )}
      prompt={<Trans id="article.forms.actions.defect-photo.prompt">Add defect photos</Trans>}
      deleteWithApi
      disabled={isDisabled}
      media={action?.media ?? defect?.media ?? []}
      onChange={() => {
        queryClient.invalidateQueries({ queryKey: ['requests', request.id] });
      }}
      isInvalid={isInvalid}
    />
  );
};
