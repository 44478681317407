import { Trans } from '@lingui/macro';

import AlertBar from '@/design_system/AlertBar';
import Stack from '@/design_system/Stack';
import IconPackageVerification from '@/icons/PackageVerification.svg';
import { ShipmentWithRelations } from '@/models/shipment';
import { ShipmentMessageTitle } from '@/routes/Shipments/Shipment/components/ShipmentStep/components/ShipmentMessageTitle';
import { useCurrentSession } from '@/services/auth';

const VerificationMessage = ({
  shipment,
  hideIcon,
  className,
}: {
  shipment: ShipmentWithRelations;
  hideIcon?: boolean;
  className?: string;
}) => {
  const { currentSession } = useCurrentSession();

  const canVerifyReception = shipment.canReceptionBeVerifiedBy(currentSession);

  if (!canVerifyReception) {
    return (
      <AlertBar
        type="info"
        size="large"
        icon={<IconPackageVerification />}
        hideIcon={hideIcon}
        className={className}
        title={
          <ShipmentMessageTitle shipment={shipment}>
            <Trans id="shipment.verification-message.waiting.title">
              The delivery is undergoing verification
            </Trans>
          </ShipmentMessageTitle>
        }
      >
        <p>
          <Trans id="shipment.verification-message.waiting.text">
            The package has arrived at its destination. Its contents are currently being checked.
          </Trans>
        </p>
      </AlertBar>
    );
  }

  return (
    <AlertBar
      type="warning"
      size="large"
      icon={<IconPackageVerification />}
      hideIcon={hideIcon}
      className={className}
      title={
        <ShipmentMessageTitle shipment={shipment}>
          <Trans id="shipment.verification-message.title">Verify the package</Trans>
        </ShipmentMessageTitle>
      }
    >
      <Stack gap="0.5rem" alignItems="flex-start">
        <p>
          <Trans id="shipment.verification-message.text.alt">
            Click on the button with the ✔ icon to confirm receipt.
          </Trans>
        </p>
      </Stack>
    </AlertBar>
  );
};

export default VerificationMessage;
