import { Trans } from '@lingui/macro';

import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const ValidateExternalPaymentTaskMessage = ({
  article,
}: {
  article: ArticleWithRelations;
}) => {
  return (
    <BaseMessage
      title={
        <Trans id="article.task.validate-external-payment.title">
          Waiting for payment information
        </Trans>
      }
      message={
        <Trans id="article.task.validate-external-payment.message">
          Please provide the required payment details on the request page to validate the step
        </Trans>
      }
      article={article}
    />
  );
};
