import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { InputSearchSelect } from '@/design_system/InputSearchSelect/InputSearchSelect';
import Stack from '@/design_system/Stack';
import { Product, useProducts } from '@/models/product';
import { useCurrentOrganization } from '@/services/auth';
import { Locale } from '@/services/i18n';
import useDebouncedState from '@/utils/useDebouncedState';

const ProductSearchSelect = ({
  showLabel = true,
  placeholder,
  value,
  onChange,
  isDisabled,
  variant = 'default',
  error,
  focusOnRender,
}: {
  showLabel?: boolean;
  placeholder?: string;
  value: Product | undefined | null;
  onChange: (product: Product | undefined) => void;
  isDisabled?: boolean;
  variant?: 'default' | 'brand';
  error?: string;
  focusOnRender?: boolean;
}) => {
  const { i18n, _ } = useLingui();
  const [organization] = useCurrentOrganization();
  const productExternalId =
    organization?.config.customWording?.productExternalId?.[i18n.locale as Locale];

  const [query, debouncedQuery, setQuery] = useDebouncedState<string>(
    value ? getOptionText(value) : '',
    500
  );

  const { data: { products } = { products: [] }, isFetching } = useProducts(
    {
      query: debouncedQuery || undefined,
      limit: 10,
    },
    {
      keepPreviousData: true,
      enabled: !isDisabled,
    }
  );

  return (
    <Stack gap="0.25rem" style={{ flex: 1, maxWidth: '100%' }}>
      <Stack row mobileColumn gap="1rem">
        <div style={{ flex: 1, maxWidth: '100%' }}>
          <InputSearchSelect
            variant="search"
            label={
              showLabel ? (
                <p>
                  <Trans id="article.form.article-reference.label">Item reference</Trans>
                  {productExternalId && <> ({productExternalId})</>}
                </p>
              ) : undefined
            }
            aria-label={
              !showLabel
                ? _(
                    msg({
                      id: 'article.form.article-reference.aria-label',
                      message: 'Enter your item reference number or the name of the item',
                    })
                  )
                : undefined
            }
            styleVariant={variant}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={focusOnRender}
            placeholder={
              placeholder ??
              _(
                msg({
                  id: 'article.form.article-reference.placeholder',
                  message: 'Search by item reference or name',
                })
              )
            }
            isDisabled={isDisabled}
            value={value}
            options={query ? products : []}
            getOptionValue={getOptionText}
            getOptionLabel={getOptionText}
            inputValue={query}
            onInputChange={setQuery}
            blurInputOnSelect
            onChange={(product) => onChange(product ?? undefined)}
            isLoading={isFetching}
            error={error}
          />
        </div>
      </Stack>
    </Stack>
  );
};

const getOptionText = (product: Product) =>
  `${product.externalId} - ${product.name}` +
  (product.data.collection ? ` (${product.data.collection})` : '');

export default ProductSearchSelect;
