import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { useUpdateArticle } from '@/models/article';
import { PRODUCT_CATEGORIES, useProductOptions } from '@/models/product';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';

export const ProductCategoryL1 = () => {
  const { _ } = useLingui();

  const { request, article, errors } = useArticleContext();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const { productL1Options } = useProductOptions(request.organization, article.productL1);

  return (
    <InputSelect
      variant="select"
      label={_(msg({ id: 'article.form.type.label', message: 'Type' }))}
      placeholder={_(
        msg({
          id: 'article.form.type.placeholder',
          message: 'Select an item type',
        })
      )}
      isDisabled={!!article.product}
      value={
        productL1Options.find((productL1Option) => article.productL1 === productL1Option.id) ?? null
      }
      isSearchable={false}
      options={productL1Options}
      getOptionValue={(productL1Option) => productL1Option.id}
      getOptionLabel={(productL1Option) => productL1Option.text}
      onChange={(productL1Option) => {
        if (productL1Option) {
          const { categories: productL2s, subCategories: productL3s } =
            PRODUCT_CATEGORIES.find((category) => category.id === productL1Option.id) ?? {};

          updateArticle({
            data: {
              productL1: productL1Option.id,
              productL2: productL2s?.length === 1 ? productL2s[0].id : null,
              productL3: productL3s?.length === 1 ? productL3s[0].id : null,
            },
          });
        }
      }}
      error={
        errors.details?.product
          ? _(
              msg({
                id: 'article.form.external-id.error',
                message: 'Please select a product reference or type',
              })
            )
          : undefined
      }
      style={{ flex: 1 }}
    />
  );
};
