import { useState } from 'react';
import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconCancel from '@/icons/Cancel.svg';
import IconTask from '@/icons/Task.svg';
import { useAnalyze } from '@/models/article';
import { ArticleActionProps } from '@/routes/Requests/Request/Article/components/ArticleActions/ArticleActions';
import { useFlags } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

import { RefuseAnalysisDialog } from './RefuseAnalysisDialog';
import RequalificationDrawer from './RequalificationDrawer';

export const AnalyzeArticleAction = ({ article, onActionDone }: ArticleActionProps) => {
  const { isMobile } = useViewPort();
  const { flags } = useFlags();
  const [isOpenRequalificationDialog, setIsOpenRequalificationDialog] = useState(false);
  const [isOpenRefusalDialog, setIsOpenRefusalDialog] = useState(false);

  const {
    mutateAsync: analyzeArticle,
    isPending: isAnalyzePending,
    isSuccess: isAnalyzeSuccess,
  } = useAnalyze({
    articleId: article.id,
  });

  const handleAnalyze = async () => {
    await analyzeArticle();
    onActionDone();
  };

  return (
    <>
      <Stack row={!isMobile} gap="0.5rem">
        {flags['enable-analysis-refusal'] && (
          <Button
            size="medium"
            variant="secondary-danger"
            onPress={() => setIsOpenRefusalDialog(true)}
          >
            <IconCancel />
            <Trans id="article.analysis.footer.action.refuse-job">Refuse the job</Trans>
          </Button>
        )}
        <Button
          size="medium"
          variant="secondary"
          onPress={() => setIsOpenRequalificationDialog(true)}
        >
          <IconTask />
          <Trans id="article.analysis.footer.action.propose-requalification">
            Propose actions re-qualification
          </Trans>
        </Button>
        <Button
          variant="primary"
          size="medium"
          onPress={handleAnalyze}
          isLoading={isAnalyzePending || isAnalyzeSuccess}
        >
          <Trans id="article.analysis.footer.action.validate">Validate analysis</Trans>
        </Button>
      </Stack>

      <RequalificationDrawer
        isOpen={isOpenRequalificationDialog}
        setIsOpen={setIsOpenRequalificationDialog}
        onRequalified={onActionDone}
      />

      <RefuseAnalysisDialog
        article={article}
        isOpen={isOpenRefusalDialog}
        setIsOpen={setIsOpenRefusalDialog}
        onActionDone={onActionDone}
      />
    </>
  );
};
