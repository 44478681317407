import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import BasicRadioGroup from '@/design_system/BasicRadioGroup';
import Button from '@/design_system/Button';
import Checkbox from '@/design_system/Checkbox';
import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import { JOB_REFUSED_REASONS, JobRefusedReason, useRefuseAnalysis } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

export const RefuseAnalysisDialog = ({
  article,
  isOpen,
  setIsOpen,
  onActionDone,
}: {
  article: ArticleWithRelations;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onActionDone: () => void;
}) => {
  const { _ } = useLingui();

  return (
    <Dialog
      title={_(msg({ id: 'refuse-analysis-modal.title', message: 'Refuse the job' }))}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
    >
      <RefuseAnalysisForm
        article={article}
        onActionDone={() => {
          setIsOpen(false);
          onActionDone();
        }}
      />
    </Dialog>
  );
};

const RefuseAnalysisForm = ({
  article,
  onActionDone,
}: {
  article: ArticleWithRelations;
  onActionDone: () => void;
}) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();

  const {
    mutateAsync: refuseAnalysis,
    isPending,
    isSuccess,
  } = useRefuseAnalysis({ articleId: article.id });

  const [showErrors, setShowErrors] = useState(false);

  const [reason, setReason] = useState<JobRefusedReason | undefined>();
  const [comment, setComment] = useState('');
  const [chargeCancellationFees, setChargeCancellationFees] = useState(false);

  const save = async () => {
    setShowErrors(true);

    if (!reason || !reasonData || (reasonData.requireComment && !comment)) {
      return;
    }

    await refuseAnalysis({
      reason,
      comment: comment || undefined,
      chargeCancellationFees,
    });

    onActionDone();
  };

  const reasonData = JOB_REFUSED_REASONS.find(({ id }) => id === reason);

  const cancellationFee = currentSession?.workshop?.organizationConfig?.fees.find(
    (fee) => fee.type === 'cancellation'
  );
  const cancellationFeeAmount = cancellationFee
    ? Math.round((cancellationFee.amountBeforeTax * (100 + cancellationFee.taxRate)) / 100)
    : 0;

  return (
    <>
      <main>
        <Stack gap="1.5rem">
          <Stack gap="1rem">
            <p className="paragraph-100-regular text-primary">
              <Trans id="refuse-analysis-model.intro">
                You&apos;re about to refuse the job. Please specify your refusal reason:
              </Trans>
            </p>
            <BasicRadioGroup
              value={reason ?? 'none'}
              onChange={(value) => {
                setReason(value as JobRefusedReason);

                if (JOB_REFUSED_REASONS.find(({ id }) => id === value)?.disableCancellationFees) {
                  setChargeCancellationFees(false);
                }
              }}
              size="small"
              aria-label={_(
                msg({ id: 'refuse-analysis-model.reason.label', message: 'Refusal reason' })
              )}
              options={JOB_REFUSED_REASONS.map(({ id, label }) => ({
                value: id,
                children: _(label),
              }))}
              boxed
              row={false}
              isInvalid={showErrors && !reason}
            />
          </Stack>
          <TextArea
            label={
              <>
                <Trans id="refuse-analysis-model.comment.label">Comment</Trans>
                {!reasonData?.requireComment && (
                  <>
                    {' '}
                    <span className="text-disabled paragraph-200-regular">
                      <Trans id="refuse-analysis-model.comment.label.optional">(Optional)</Trans>
                    </span>
                  </>
                )}
              </>
            }
            placeholder={_(
              msg({
                id: 'refuse-analysis-modal.comment.placeholder',
                message: 'Write a comment to explain your refusal...',
              })
            )}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            error={
              showErrors && !!reasonData && reasonData.requireComment && !comment
                ? _(
                    msg({
                      id: 'refuse-analysis-modal.comment-error',
                      message: 'Please write a comment',
                    })
                  )
                : undefined
            }
            rows={3}
          />
        </Stack>
      </main>
      <footer>
        <Stack
          style={{ flex: 1 }}
          {...(isMobile
            ? {
                gap: '0.75rem',
                alignItems: 'stretch',
              }
            : {
                row: true,
                gap: '1rem',
                alignItems: 'center',
                justifyContent: 'space-between',
              })}
        >
          {!!cancellationFee && !!cancellationFeeAmount && (
            <Checkbox
              isSelected={chargeCancellationFees}
              isDisabled={reasonData?.disableCancellationFees}
              onChange={setChargeCancellationFees}
              size="large"
            >
              <Trans id="refuse-analysis-modal.charge-cancellation-fee">
                Charge a {formatCurrency(cancellationFeeAmount, cancellationFee.currency)}{' '}
                cancellation fee for this item
              </Trans>
            </Checkbox>
          )}
          <Button variant="danger" size="medium" onPress={save} isLoading={isPending || isSuccess}>
            <Trans id="refuse-analysis-modal.refuse">Refuse the job</Trans>
          </Button>
        </Stack>
      </footer>
    </>
  );
};
