import Stack from '@/design_system/Stack';

export const Comment = ({ comment }: { comment: string }) => (
  <Stack
    className="bg-neutral-100"
    style={{
      padding: '8px 12px',
      borderRadius: '0.5rem',
    }}
  >
    <p
      className="paragraph-100-regular"
      style={{
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      }}
    >
      {comment}
    </p>
  </Stack>
);
