import { Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconEdit from '@/icons/Edit.svg';

export const ArticleWorkshopUpdatedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_workshop_updated'>;
  displayArticleName: boolean;
}) => {
  if (!activity.workshop) {
    return null;
  }

  return (
    <BaseActivity
      key={activity.id}
      createdAt={activity.createdAt}
      icon={<IconEdit />}
      creatorName={activity.creator?.name ?? ''}
      message={
        <>
          <BaseActivityText>
            {activity.creator?.name ? (
              <Trans id="activities.article_workshop_updated.message">
                assigned the item{' '}
                <BaseActivityArticleName
                  activity={activity}
                  displayArticleName={displayArticleName}
                />{' '}
                to the workshop:
              </Trans>
            ) : (
              <Trans id="activities.article_workshop_updated.message-no-creator">
                The item{' '}
                <BaseActivityArticleName
                  activity={activity}
                  displayArticleName={displayArticleName}
                />
                was automatically assigned to the workshop:
              </Trans>
            )}
          </BaseActivityText>{' '}
          <span className="paragraph-100-medium">{activity.workshop?.name}</span>
        </>
      }
    />
  );
};
