import IconWrapper from './IconWrapper';

const IconNoConnection = IconWrapper({
  name: 'no-connection',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53033 2.71967C3.23744 2.42678 2.76256 2.42678 2.46967 2.71967C2.17678 3.01256 2.17678 3.48744 2.46967 3.78033L19.9697 21.2803C20.2626 21.5732 20.7374 21.5732 21.0303 21.2803C21.3232 20.9874 21.3232 20.5126 21.0303 20.2197L15.9196 15.1089C15.9717 15.0778 16.0209 15.0397 16.0659 14.9948C16.3588 14.7019 16.3588 14.227 16.0659 13.9341C15.5319 13.4002 14.8981 12.9767 14.2004 12.6877C13.8712 12.5513 13.5309 12.4462 13.1838 12.3732L10.6688 9.85811C11.1078 9.78632 11.553 9.75 12 9.75C13.0834 9.75 14.1562 9.96339 15.1571 10.378C16.1581 10.7926 17.0676 11.4003 17.8336 12.1664C18.1265 12.4593 18.6014 12.4593 18.8943 12.1664C19.1872 11.8735 19.1872 11.3986 18.8943 11.1057C17.9889 10.2003 16.9141 9.48216 15.7312 8.99217C14.5482 8.50219 13.2804 8.25 12 8.25C11.1233 8.25 10.2525 8.36824 9.41075 8.60009L7.44072 6.63006C8.89008 6.04888 10.4376 5.75 12 5.75C13.6087 5.75 15.2016 6.06685 16.6879 6.68248C18.1741 7.29809 19.5245 8.20042 20.6621 9.33794C20.955 9.63083 21.4298 9.63083 21.7227 9.33794C22.0156 9.04505 22.0156 8.57017 21.7227 8.27728C20.4459 7.00047 18.9301 5.98766 17.2619 5.29666C15.5937 4.60565 13.8057 4.25 12 4.25C10.1943 4.25 8.40633 4.60566 6.73811 5.29666C6.59035 5.35786 6.44378 5.42159 6.29847 5.48781L3.53033 2.71967Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6969 7.04003C3.19707 7.4187 2.72264 7.83193 2.27729 8.27728C1.98439 8.57018 1.98439 9.04505 2.27729 9.33794C2.57018 9.63084 3.04505 9.63084 3.33795 9.33794C3.78376 8.89213 4.26227 8.48244 4.76885 8.11198L3.6969 7.04003Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.56334 9.90647C6.04108 10.2573 5.55274 10.6587 5.10571 11.1057C4.81282 11.3986 4.81282 11.8735 5.10572 12.1664C5.39861 12.4593 5.87348 12.4593 6.16638 12.1664C6.61465 11.7181 7.11202 11.3241 7.64808 10.9912L6.56334 9.90647Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48572 12.8288C8.91139 13.1081 8.38684 13.4814 7.93414 13.9341C7.64125 14.227 7.64125 14.7019 7.93414 14.9948C8.22704 15.2877 8.70191 15.2877 8.9948 14.9948C9.38945 14.6001 9.85797 14.2871 10.3736 14.0735C10.459 14.0381 10.5455 14.0056 10.6328 13.9759L9.48572 12.8288Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20C13.1046 20 14 19.1046 14 18C14 16.8954 13.1046 16 12 16C10.8954 16 10 16.8954 10 18C10 19.1046 10.8954 20 12 20Z"
      />
    </svg>
  ),
});

export default IconNoConnection;
