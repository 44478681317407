import { Trans } from '@lingui/macro';

import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { NewRequestArticles } from '@/routes/Requests/New/components/NewRequestArticles';

const Articles = () => {
  const { errors } = useRequestContext();

  return (
    <Stack gap="0.5rem">
      <h2 className="headline-400-bold">
        <Trans id="requests.new.articles.title">Items</Trans>
      </h2>
      <NewRequestArticles />
      {errors.articles?.hasError && (
        <Message type="error">
          {errors.articles?.noArticles ? (
            <Trans id="requests.new.articles.error.no-article">Please add at least one item</Trans>
          ) : (
            <Trans id="requests.new.articles.error.incomplete-article">
              Please complete all the items
            </Trans>
          )}
        </Message>
      )}
    </Stack>
  );
};

export default Articles;
