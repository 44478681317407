import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRequests } from '@/models/request';
import { useShipments } from '@/models/shipment';
import useViewPort from '@/utils/useViewport';

import Desktop from './Desktop';
import Mobile from './Mobile';

import './Scan.css';

const Scan = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate();
  const { isMobile } = useViewPort();
  const [code, setCode] = useState<string>();

  let [orgSlug, parsedCode] = parseCode(code); // eslint-disable-line prefer-const

  const parsedCodeMayBeRef = !!parsedCode?.match(/^(?:[RS]\d{2}-)?[A-Z]+\d{3}$/i);

  // Will match requests with the exact reference or reference without the RXX- prefix
  const { data: { requests } = { requests: [] }, status: statusRequests } = useRequests(
    {
      reference: parsedCode,
    },
    {
      enabled: !!parsedCode && parsedCodeMayBeRef,
    }
  );

  // Will match shipments with the exact reference
  const { data: { shipments: shipmentsByRef } = { shipments: [] }, status: statusShipmentsByRef } =
    useShipments(
      {
        reference: parsedCode,
      },
      {
        enabled: !!parsedCode && parsedCodeMayBeRef,
      }
    );

  // This is probably a Mondial Relay tracking barcode.
  // We need to remove the first 2 characters and the last 16 characters to get the tracking ID
  if (parsedCode?.match(/^\d{2}\d{8}0101\d{12}/)) {
    parsedCode = parsedCode.slice(2, 10);
  }

  const {
    data: { shipments: shipmentsByTrackingId } = { shipments: [] },
    status: statusShipmentsByTrackingId,
  } = useShipments(
    {
      trackingId: parsedCode,
    },
    {
      enabled: !!parsedCode,
    }
  );

  const result = useMemo(() => {
    const results = [...requests, ...shipmentsByRef, ...shipmentsByTrackingId]
      .filter((result) => (orgSlug ? result.organization.slug === orgSlug : true))
      .sort((resA, resB) => resB.createdAtDate.getTime() - resA.createdAtDate.getTime());

    return results[0];
  }, [requests, shipmentsByRef, shipmentsByTrackingId, orgSlug]);

  const resultStatus =
    code === undefined
      ? 'idle'
      : statusRequests === 'pending' ||
          statusShipmentsByRef === 'pending' ||
          statusShipmentsByTrackingId === 'pending'
        ? 'loading'
        : result
          ? 'success'
          : 'error';

  useEffect(() => {
    // If a user tries to scan a QR Code with `/requests/claim` in the URL, we should redirect it
    // to the dedicated page
    const isClaimRequestUrl = parsedCode?.match(/\/requests\/claim(.*)/);
    if (!!isClaimRequestUrl && isClaimRequestUrl.length > 0) {
      onClose();
      navigate(isClaimRequestUrl[0]);
    } else if (resultStatus === 'success') {
      // Show the success status for 2 seconds before redirecting
      setTimeout(() => {
        onClose();

        if (result) {
          if (result.reference.startsWith('R')) {
            navigate(`/requests/${result.id}`);
          } else {
            navigate(`/shipments/${result.id}`);
          }
        }
      }, 2000);
    }
  }, [navigate, onClose, parsedCode, result, resultStatus]);

  if (isMobile) {
    return <Mobile onClose={onClose} onResult={setCode} resultStatus={resultStatus} />;
  }

  return <Desktop onClose={onClose} onResult={setCode} resultStatus={resultStatus} />;
};

export default Scan;

function parseCode(code?: string) {
  if (!code) return [undefined, undefined];

  // Remove illegal characters
  code = code
    .replace(
      /[\0]/g,
      (char: string) =>
        ({
          '\0': '', // Null character, %00 in URL
        })[char] ?? char
    )
    .replace(/%00/g, '');

  // In some weird cases on Windows, the scanner device will use a keyboard layout that inverts all non-letter characters
  // This is a workaround to fix that
  if (code.startsWith('https/::')) {
    code = code.replace(
      /[/;:&é"'(\-è_çà]/g,
      (char: string) =>
        ({
          '/': ':',
          ';': '.',
          ':': '/',
          '&': '1',
          é: '2',
          '"': '3',
          "'": '4',
          '(': '5',
          '-': '6',
          è: '7',
          _: '8',
          ç: '9',
          à: '0',
        })[char] ?? char
    );
  }

  const codeWithoutOrigin = code.replace(window.location.origin, '');

  if (codeWithoutOrigin.startsWith('/ref/')) {
    return codeWithoutOrigin.slice(5).split('/');
  }

  return [undefined, codeWithoutOrigin];
}
