import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import {
  PaymentStepMessage,
  ValidationStepMessage,
} from '@/routes/Requests/components/StepMessage';

import { AcceptDispatchTaskMessage } from './AcceptDispatchTaskMessage';
import { AcceptRequalificationTaskMessage } from './AcceptRequalificationTaskMessage';
import { AnalysisStepMessage } from './AnalysisStepMessage';
import { AnalyzeArticleTaskMessage } from './AnalyzeArticleTaskMessage';
import { ArticleArchivedMessage } from './ArticleArchivedMessage';
import { ChooseArticleServiceTaskMessage } from './ChooseArticleServiceTaskMessage';
import { CreateShipmentTaskMessage } from './CreateShipmentTaskMessage';
import { DropoffShipmentTaskMessage } from './DropoffShipmentTaskMessage';
import { FinaliseShipmentTaskMessage } from './FinaliseShipmentTaskMessage';
import { HandleShipmentIssueTaskMessage } from './HandleShipmentIssueTaskMessage';
import { PrepareShipmentTaskMessage } from './PrepareShipmentTaskMessage';
import { RepairArticleTaskMessage } from './RepairArticleTaskMessage';
import { RepairStepMessage } from './RepairStepMessage';
import { ServiceChoiceStepMessage } from './ServiceChoiceStepMessage';
import { TransitStepMessage } from './TransitStepMessage';
import { ValidateExternalPaymentTaskMessage } from './ValidateExternalPaymentTaskMessage';
import { VerifyShipmentTaskMessage } from './VerifyShipmentTaskMessage';

const ArticleTaskOrStepMessage = ({
  article,
  request,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
}) => {
  if (article.archived) {
    return <ArticleArchivedMessage article={article} />;
  }

  if (article.task) {
    switch (article.task.type) {
      case 'choose_article_service':
        return <ChooseArticleServiceTaskMessage article={article} />;
      case 'accept_dispatch':
        return <AcceptDispatchTaskMessage article={article} />;
      case 'create_shipment':
        return <CreateShipmentTaskMessage article={article} />;
      case 'prepare_shipment':
        return <PrepareShipmentTaskMessage article={article} />;
      case 'finalise_shipment':
        return <FinaliseShipmentTaskMessage article={article} />;
      case 'dropoff_shipment':
        return <DropoffShipmentTaskMessage article={article} />;
      case 'verify_shipment_reception':
        return <VerifyShipmentTaskMessage article={article} />;
      case 'handle_shipment_issue':
        return <HandleShipmentIssueTaskMessage article={article} />;
      case 'analyze_article':
        return <AnalyzeArticleTaskMessage article={article} />;
      case 'accept_requalification':
        return <AcceptRequalificationTaskMessage article={article} />;
      case 'repair_article':
        return <RepairArticleTaskMessage article={article} />;
      case 'validate_external_payment':
        return <ValidateExternalPaymentTaskMessage article={article} />;
      default:
        return null;
    }
  }

  // To simplify when TECH-898 is done, the status should be in the step directly
  const stepStatus = article.steps?.find((step) => step.id === article.step?.id)?.status;

  if (article.step && !article.step.sync) {
    switch (article.step.step) {
      case 'service-choice':
        return <ServiceChoiceStepMessage article={article} />;
      case 'transit':
        return <TransitStepMessage article={article} />;
      case 'analysis':
        return <AnalysisStepMessage article={article} />;
      case 'repair':
        return <RepairStepMessage article={article} />;
      case 'validation':
        return stepStatus !== 'pending' ? (
          <ValidationStepMessage article={article} request={request} />
        ) : null;
      case 'payment':
        return stepStatus !== 'pending' ? (
          <PaymentStepMessage article={article} request={request} />
        ) : null;
      default:
        return null;
    }
  }

  return null;
};

export default ArticleTaskOrStepMessage;
