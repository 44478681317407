import { Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconZendesk from '@/icons/Zendesk.svg';

export const ArticleSentToZendeskActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_sent_to_zendesk'>;
  displayArticleName: boolean;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="neutral"
      icon={<IconZendesk />}
      creatorName={''}
      message={
        <>
          <BaseActivityText>
            <Trans id="activities.article_sent_to_zendesk.message">
              The item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />
              has been transferred to Zendesk
            </Trans>
          </BaseActivityText>
        </>
      }
    />
  );
};
