import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityDetailedContent,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import Stack from '@/design_system/Stack';
import IconRefusal from '@/icons/Refusal.svg';
import { JOB_REFUSED_REASONS } from '@/models/article';

export const JobRefusedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'job_refused'>;
  displayArticleName: boolean;
}) => {
  const { _ } = useLingui();

  const workshopName = activity.workshop.name;
  const reasonDetail = JOB_REFUSED_REASONS.find(({ id }) => id === activity.data.reason);
  const reason = !reasonDetail || reasonDetail.id === 'custom' ? undefined : _(reasonDetail.label);
  const comment = activity.data.comment;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="warning"
      icon={<IconRefusal />}
      creatorName={
        activity.data.onBehalfOfWorkshop
          ? activity.creator.name
          : `${activity.creator.name} (${workshopName})`
      }
      message={
        <BaseActivityText>
          {!activity.data.step ||
            (activity.data.step === 'service-choice' &&
              (activity.data.onBehalfOfWorkshop ? (
                <Trans id="activities.job_refused.message.service_choice.on-behalf">
                  refused the job on behalf of {workshopName} for the item{' '}
                  <BaseActivityArticleName
                    activity={activity}
                    displayArticleName={displayArticleName}
                  />{' '}
                  during the service choice
                </Trans>
              ) : (
                <Trans id="activities.job_refused.message.service_choice">
                  refused the job for the item{' '}
                  <BaseActivityArticleName
                    activity={activity}
                    displayArticleName={displayArticleName}
                  />{' '}
                  during the service choice
                </Trans>
              )))}
          {activity.data.step === 'analysis' && (
            <Trans id="activities.job_refused.message.analysis">
              refused the job for the item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />{' '}
              during the analysis
            </Trans>
          )}
          {!!reason && (
            <>
              <Trans id="_general.colon">:</Trans>{' '}
              <span className="paragraph-100-medium">{reason}</span>
            </>
          )}
        </BaseActivityText>
      }
    >
      <Stack style={{ flex: 1, paddingTop: '0.25rem' }} gap="0.5rem">
        {!!comment && <BaseActivityDetailedContent>{comment}</BaseActivityDetailedContent>}
        {activity.data.chargeCancellationFees && (
          <p className="paragraph-200-regular">
            <Trans id="activities.job_refused.message.fees">Cancellation fees were applied.</Trans>
          </p>
        )}
      </Stack>
    </BaseActivity>
  );
};
