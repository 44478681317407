import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import cn from 'classnames';
import { differenceInBusinessDays } from 'date-fns/differenceInBusinessDays';

import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconCalendar from '@/icons/Calendar.svg';
import IconCalendarOverdue from '@/icons/CalendarOverdue.svg';
import IconCalendarReminder from '@/icons/CalendarReminder.svg';
import { formatDate } from '@/utils/date';

export const StatusDueDate = ({
  date,
  variant,
  displayDayMonthOnly,
}: {
  date: Date;
  variant: 'info' | 'row' | 'card';
  displayDayMonthOnly?: boolean;
}) => {
  const { _ } = useLingui();
  const label = _(msg({ id: 'components.status-due-date.label', message: 'Status due date' }));
  const dayLabel = _(msg({ id: 'components.status-due-date.day-label', message: 'd' }));

  const referenceDate = new Date();
  referenceDate.setUTCHours(18, 0, 0, 0);

  const difference = differenceInBusinessDays(referenceDate, date);
  const isDueToday = difference === 0;
  const isOverdue = difference > 0;

  const content = (
    <Stack>
      {variant === 'info' && <span className="paragraph-200-regular text-secondary">{label}</span>}
      <Stack
        row
        gap={variant === 'info' ? '0.25rem' : '0.5rem'}
        alignItems="center"
        className={isDueToday ? 'text-warning' : isOverdue ? 'text-danger' : undefined}
        flexWrap="nowrap"
      >
        {isDueToday ? (
          <IconCalendarReminder style={{ fontSize: '1rem' }} />
        ) : isOverdue ? (
          <IconCalendarOverdue style={{ fontSize: '1rem' }} />
        ) : (
          <IconCalendar style={{ fontSize: '1rem' }} />
        )}
        <Stack row gap="0.25rem" alignItems="center" flexWrap="nowrap">
          <span
            className={
              variant === 'info'
                ? 'paragraph-100-medium'
                : variant === 'card'
                  ? 'paragraph-200-regular'
                  : 'paragraph-100-regular'
            }
            aria-label={label}
            style={{ whiteSpace: 'nowrap' }}
          >
            {formatDate(
              date,
              displayDayMonthOnly ? { day: 'numeric', month: 'short' } : { dateStyle: 'medium' }
            )}
          </span>
          {isOverdue && (
            <span className="paragraph-200-medium">{`(+${difference}${dayLabel})`}</span>
          )}
        </Stack>
      </Stack>
    </Stack>
  );

  if (variant === 'card') {
    return (
      <Box padding="0.25rem 0.5rem" style={{ flex: 'none' }}>
        {content}
      </Box>
    );
  }

  return content;
};

export const RequestDueDate = ({
  date,
  estimatedDate,
  variant,
  displayDayMonthOnly,
}: {
  date: Date;
  estimatedDate: Date | null;
  variant: 'info' | 'row' | 'card';
  displayDayMonthOnly?: boolean;
}) => {
  const { _ } = useLingui();
  const label = _(msg({ id: 'components.request-due-date.label', message: 'Request due date' }));
  const dayLabel = _(msg({ id: 'components.request-due-date.day-label', message: 'd' }));

  const difference = estimatedDate ? differenceInBusinessDays(estimatedDate, date) : 0;
  const isInAdvance = difference < 0;
  const isOverdue = difference > 0;

  return (
    <Stack>
      {variant === 'info' && <span className="paragraph-200-regular text-secondary">{label}</span>}
      <Stack
        row
        gap={variant === 'row' ? '0.5rem' : '0.25rem'}
        alignItems="center"
        flexWrap="nowrap"
      >
        <IconCalendar style={{ fontSize: '1rem' }} />
        <Stack row gap="0.25rem" alignItems="center">
          <span
            className={
              variant === 'info'
                ? 'paragraph-100-medium'
                : variant === 'card'
                  ? 'paragraph-200-medium'
                  : 'paragraph-100-regular'
            }
            aria-label={label}
          >
            {formatDate(
              date,
              displayDayMonthOnly ? { day: 'numeric', month: 'short' } : { dateStyle: 'medium' }
            )}
          </span>
          {!!estimatedDate && (isInAdvance || isOverdue) && (
            <Tooltip
              content={
                <span>
                  <Trans id="components.request-due-date.tooltip">Estimated completion date:</Trans>{' '}
                  {formatDate(
                    estimatedDate,
                    displayDayMonthOnly
                      ? { day: 'numeric', month: 'short' }
                      : { dateStyle: 'medium' }
                  )}
                </span>
              }
            >
              <Button variant="style-less">
                <span
                  className={cn(
                    'paragraph-200-medium',
                    isOverdue ? 'text-danger' : 'color-primary-800'
                  )}
                >
                  {isOverdue ? `(+${difference}${dayLabel})` : `(${difference}${dayLabel})`}
                </span>
              </Button>
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
