import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import { useRepair } from '@/models/article';

import { ArticleActionProps } from './ArticleActions';

export const RepairArticleAction = ({ article, onActionDone }: ArticleActionProps) => {
  const {
    mutateAsync: repair,
    isPending: isPendingRepair,
    isSuccess: isSuccessRepair,
  } = useRepair({
    articleId: article.id,
  });

  const handleRepair = async () => {
    await repair();
    onActionDone();
  };

  return (
    <Button
      variant="primary"
      size="medium"
      onPress={handleRepair}
      isLoading={isPendingRepair || isSuccessRepair}
    >
      <Trans id="article.actions.repair_article.validate">Mark as repaired</Trans>
    </Button>
  );
};
