import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import { GoToCommentsButton } from '@/components/comments/GoToCommentsButton/GoToCommentsButton';
import Stack from '@/design_system/Stack';
import { useArticleName } from '@/models/article';
import {
  type ArticleWithRelations,
  type RequestWithRelations,
  useComments,
} from '@/models/request';
import { PrintItemSheetButton } from '@/routes/Requests/Request/components/RequestHeader/PrintsButton';

export const RequestBreadcrumb = ({
  request,
  article,
  onCommentButtonPress,
}: {
  request: RequestWithRelations;
  article?: ArticleWithRelations;
  onCommentButtonPress?: () => void;
}) => {
  const articleName = useArticleName({ article });

  const { data: { comments } = { comments: [] } } = useComments({
    requestId: request.id,
    articleId: article?.id,
  });

  return (
    <Stack
      row
      gap="0.5rem"
      alignItems="center"
      justifyContent="space-between"
      style={{ minHeight: '2rem' }}
    >
      <Stack row gap="0.5rem" alignItems="center">
        <div className="paragraph-100-regular text-disabled">
          <Link to={`/requests`} className="reset-link">
            <Trans id="request.breadcrumb.requests">Requests</Trans>
          </Link>
        </div>

        <div className="paragraph-100-regular text-disabled">&gt;</div>

        {!article && <div className="paragraph-100-regular">{request.reference}</div>}

        {article && (
          <>
            <div className="paragraph-100-regular text-disabled">
              <Link to={`/requests/${request.id}`} className="reset-link">
                {request.reference}
              </Link>
            </div>

            <div className="paragraph-100-regular text-disabled">&gt;</div>

            <div className="paragraph-100-regular">{articleName}</div>
          </>
        )}
      </Stack>
      <Stack row gap="0.5rem" alignItems="center">
        {article && onCommentButtonPress && (
          <GoToCommentsButton
            onCommentButtonPress={onCommentButtonPress}
            nbOfComments={comments.length}
            size="medium"
            variant="tertiary"
          />
        )}
        {!!article?.serviceChosenAt && !article?.archived && (
          <PrintItemSheetButton
            request={request}
            article={article}
            size="medium"
            variant="tertiary"
          />
        )}
      </Stack>
    </Stack>
  );
};
