import { useEffect, useState } from 'react';
import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Endpoints } from '@/api';
import { useFetch } from '@/utils/fetch';

let stripePromise: Promise<Stripe> | undefined;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const STRIPE_ACCOUNT_STATUSES = ['none', 'onboarding', 'pending', 'complete'] as const;
export type StripeAccountStatus = (typeof STRIPE_ACCOUNT_STATUSES)[number];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const STRIPE_SETUP_INTENT_STATUSES = [
  'canceled',
  'processing',
  'requires_action',
  'requires_confirmation',
  'requires_payment_method',
  'succeeded',
] as const;
export type StripeSetupIntentStatus = (typeof STRIPE_SETUP_INTENT_STATUSES)[number];

export const useStripe = () => {
  const [stripe, setStripe] = useState<Stripe>();

  useEffect(() => {
    if (!import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY) {
      return;
    }

    if (!stripePromise) {
      stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string).then(
        (stripe) => {
          if (!stripe) {
            throw new Error('Stripe failed to load');
          }

          return stripe;
        }
      );
    }

    stripePromise.then(setStripe);
  }, []);

  return stripe;
};

export const useStripeAccount = ({
  type,
  id,
}: {
  type: 'organization-country' | 'workshop';
  id: string;
}) => {
  const fetch = useFetch<Endpoints['GET /providers/stripe/organization-country/:id']>();

  return useQuery({
    queryKey: ['stripeAccount', { type, id }],
    queryFn: () => fetch(`/providers/stripe/${type}/${id}`),
  });
};

export const useOnboardingLink = () => {
  const fetch =
    useFetch<Endpoints['POST /providers/stripe/organization-country/:id/onboarding-link']>();

  return useMutation({
    mutationFn: ({
      type,
      id,
      accountTokenId,
    }: {
      type: 'organization-country' | 'workshop';
      id: string;
      accountTokenId?: string;
    }) =>
      fetch(`/providers/stripe/${type}/${id}/onboarding-link`, undefined, {
        method: 'POST',
        body: { accountTokenId },
      }),
  });
};

export const useSetupDebitPaymentLink = () => {
  const fetch =
    useFetch<
      Endpoints['POST /providers/stripe/organization-country/:id/setup-debit-payment-link']
    >();

  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      fetch(`/providers/stripe/organization-country/${id}/setup-debit-payment-link`, undefined, {
        method: 'POST',
      }),
  });
};
