import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import { HelpForm } from '@/components/HelpForm/HelpForm';
import { Account } from '@/routes/Account/Account';
import Profile from '@/routes/Profile/Profile';
import { RequestClaim } from '@/routes/Requests/Claim/RequestClaim';

import AppLayout from './layouts/App';
import AuthLayout from './layouts/Auth';
import BrandLayout from './layouts/Brand';
import BrandError from './routes/Brand/Error';
import BrandRequestNew from './routes/Brand/Requests/New';
import BrandRequest from './routes/Brand/Requests/Request';
import ForgottenPassword from './routes/ForgottenPassword';
import Join from './routes/Join';
import Organization from './routes/Join/Organization';
import Login from './routes/Login';
import NoOrganizations from './routes/NoOrganizations';
import Reference from './routes/Reference/Reference';
import Requests from './routes/Requests';
import RequestNew, { NewArticle as RequestNewArticle } from './routes/Requests/New';
import { Article } from './routes/Requests/Request/Article/Article';
import { Request } from './routes/Requests/Request/Request';
import Settings from './routes/Settings';
import Shipments from './routes/Shipments';
import ShipmentNew from './routes/Shipments/New';
import { Shipment } from './routes/Shipments/Shipment';
import ShipmentEdit from './routes/Shipments/Shipment/Edit';
import { AuthProvider } from './services/auth';

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthProvider />,
    children: [
      {
        path: '/',
        element: <AppLayout />,
        handle: {
          authenticated: true,
        },
        children: [
          {
            path: '/',
            element: <Navigate to="/requests" />,
          },
          {
            path: '/ref/:brand/:ref',
            element: <Reference />,
          },
          {
            path: '/requests',
            element: <Requests />,
          },
          {
            path: '/requests/claim',
            element: <RequestClaim />,
          },
          {
            path: '/requests/new/:id',
            element: <RequestNew />,
            handle: {
              displayOnFullPage: true,
            },
            children: [
              {
                path: 'articles/:articleId',
                element: <RequestNewArticle />,
              },
            ],
          },
          {
            path: '/requests/:id',
            element: <Request />,
            handle: {
              displayOnFullPage: true,
            },
          },
          {
            path: '/requests/:requestId/articles/:articleId',
            element: <Article />,
            handle: {
              displayOnFullPage: true,
            },
          },
          {
            path: '/shipments',
            element: <Shipments />,
          },
          {
            path: '/shipments/new',
            element: <ShipmentNew />,
            handle: {
              displayOnFullPage: true,
            },
          },
          {
            path: '/shipments/:id/edit',
            element: <ShipmentEdit />,
            handle: {
              displayOnFullPage: true,
            },
          },
          {
            path: '/shipments/:id',
            element: <Shipment />,
            handle: {
              displayOnFullPage: true,
            },
          },
          {
            path: '/account',
            element: <Account />,
          },
          {
            path: '/profile',
            element: <Profile />,
          },
          {
            path: '/settings',
            element: <Settings />,
          },
          {
            path: '/help',
            element: <HelpForm />,
          },
          {
            path: '*',
            element: <Navigate to="/" />,
          },
        ],
      },
      {
        path: '/',
        element: <AuthLayout />,
        children: [
          {
            path: '/login',
            element: <Login />,
          },
          {
            path: '/join',
            element: <Join />,
          },
          {
            path: '/join/:organization',
            element: <Organization />,
            handle: {
              authenticated: true,
            },
          },
          {
            path: '/no-organizations',
            element: <NoOrganizations />,
            handle: {
              authenticated: true,
            },
          },
          {
            path: '/forgotten-password',
            element: <ForgottenPassword />,
          },
        ],
      },
      {
        path: '/brand/:organization',
        element: <BrandLayout />,
        handle: {
          authenticated: false,
        },
        children: [
          {
            path: 'requests/new',
            element: <BrandRequestNew />,
          },
          {
            path: 'requests/:id',
            element: <BrandRequest />,
          },
          {
            path: '*',
            index: true,
            element: <BrandError />,
          },
        ],
      },
    ],
  },
]);

export const Router = () => {
  return <RouterProvider router={router} />;
};
