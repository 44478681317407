import { Trans } from '@lingui/macro';

import { ArticleWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';

import { BaseMessage } from './BaseMessage';

export const AcceptDispatchTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  const { isWorkshop } = useCurrentSession();

  if (!isWorkshop) {
    return (
      <BaseMessage
        title={
          <Trans id="article.task.accept-dispatch.on-behalf.title">
            Workshop validation required
          </Trans>
        }
        message={
          <Trans id="article.task.accept-dispatch.on-behalf.message">
            Please contact the workshop to validate the job on its behalf.
          </Trans>
        }
        article={article}
      />
    );
  }

  return (
    <BaseMessage
      title={<Trans id="article.task.accept-dispatch.title">Your expertise is required</Trans>}
      message={
        <Trans id="article.task.accept-dispatch.message">
          Please review the item details and confirm the acceptance of this job.
        </Trans>
      }
      article={article}
    />
  );
};
