import { Trans } from '@lingui/macro';

import AlertBar from '@/design_system/AlertBar';
import Stack from '@/design_system/Stack';
import {
  ArticleWithRelations,
  RequestWithRelations,
  useNotifyPendingValidation,
} from '@/models/request';
import { PendingClientActionReminder } from '@/routes/Requests/Request/components/PendingClientActionReminder/PendingClientActionReminder';
import { useCurrentSession } from '@/services/auth';

export const ValidationStepMessage = ({
  request,
  article: inputArticle,
}: {
  request: RequestWithRelations;
  article?: ArticleWithRelations;
}) => {
  const article =
    inputArticle ?? request.articles.find((article) => article.step?.step === 'validation');

  const { currentSession } = useCurrentSession();

  const isResponsibleWorkshop = currentSession?.isResponsibleWorkshop(article);

  const hasSendValidationReminderPermission = currentSession?.hasPermission(
    'send_validation_reminder',
    [
      {
        organizationId: request.organizationId,
        storeId: null,
      },
      {
        organizationId: request.organizationId,
        storeId: request.storeId,
      },
    ]
  );

  const isWaitingShippingChoice =
    article?.step?.step === 'validation' &&
    !!article?.step.config?.requireShippingChoice &&
    !!article?.quoteAcceptedAt &&
    !request.client?.shippingChoice;

  const canSendValidationReminder = !isWaitingShippingChoice && hasSendValidationReminderPermission;

  let messageContent;
  if (isWaitingShippingChoice) {
    messageContent = (
      <Trans id="request.client-validation.waiting-shipping-choice.text">
        The client has validated the estimate. They now need to choose a shipping option.
      </Trans>
    );
  } else if (isResponsibleWorkshop) {
    messageContent = (
      <Trans id="request.client-validation.waiting-workshop.text">
        The estimate has been sent to the client for validation. Once they validate it, the items
        will be sent to you.
      </Trans>
    );
  } else if (canSendValidationReminder) {
    messageContent = (
      <Trans id="request.client-validation.waiting-with-reminder.text">
        The estimate has been sent to the client for validation. If they don&apos;t respond, feel
        free to send them an email reminder.
      </Trans>
    );
  } else {
    messageContent = (
      <Trans id="request.client-validation.waiting.text">
        The estimate has been sent to the client for validation.
      </Trans>
    );
  }

  return (
    <AlertBar
      title={<Trans id="request.client-validation.waiting.title">Waiting for client</Trans>}
      type="info"
      size="large"
    >
      <Stack gap="0.5rem">
        {messageContent}
        {canSendValidationReminder && (
          <PendingClientActionReminder
            id={request.id}
            activityType="pending_validation_notified"
            mutationFunction={useNotifyPendingValidation}
          />
        )}
      </Stack>
    </AlertBar>
  );
};
