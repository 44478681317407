import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import { RequestOrganizationPrice } from '@/components/RequestOrganizationPrice';
import { RequestWorkshopPrice } from '@/components/RequestWorkshopPrice';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack';
import IconAdd from '@/icons/Add.svg';
import { useCreateDraftRequestArticle } from '@/models/request';
import {
  CreationArticlesCardList,
  CreationArticlesTable,
} from '@/routes/Requests/components/ArticlesTable';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import useViewPort from '@/utils/useViewport';

export const NewRequestArticles = () => {
  const { isMobile, isWidescreen } = useViewPort();
  const {
    request,
    view: { price, cost },
  } = useRequestContext();

  const hasArticles = request.articles.length > 0;
  const navigate = useNavigate();

  const {
    mutateAsync: createArticle,
    isPending: isPendingCreateArticle,
    isSuccess: isSuccessCreateArticle,
    reset: resetCreateArticle,
  } = useCreateDraftRequestArticle(request.id);

  const handleCreateArticle = async () => {
    const { id } = await createArticle();

    navigate(`/requests/new/${request.id}/articles/${id}`, {
      state: { from: 'request', isNew: true, skipRouterBlocker: true },
      replace: true,
    });
    resetCreateArticle();
  };

  return (
    <Stack gap="1rem">
      <Box padding="0" removeStyleOnMobile>
        {hasArticles && (
          <Stack>
            {isMobile ? (
              <CreationArticlesCardList />
            ) : (
              <div style={{ borderBottom: '1px solid var(--color-neutral-300)' }}>
                <CreationArticlesTable />
              </div>
            )}
          </Stack>
        )}

        <Stack
          padding={isMobile ? undefined : '1rem'}
          alignItems={isMobile ? 'stretch' : 'flex-start'}
          gap={isMobile ? '0.5rem' : '1rem'}
        >
          {!hasArticles && (
            <p className="paragraph-100-regular paragraph-200-regular-mobile text-secondary">
              <Trans id="requests.new.articles.no-article">No items added yet</Trans>
            </p>
          )}
          {request.draft && (
            <Button
              style={{ flex: isMobile ? 1 : undefined }}
              variant="secondary"
              size="medium"
              onPress={handleCreateArticle}
              isLoading={isPendingCreateArticle || isSuccessCreateArticle}
              dataTrackingId="requests.new.articles.add"
            >
              <IconAdd />
              <Trans id="requests.new.articles.add">Add an item</Trans>
            </Button>
          )}
        </Stack>
      </Box>
      {hasArticles && (
        <Stack row={isWidescreen} gap="1rem">
          {cost.enabled && (
            <Stack style={{ flex: 1 }} gap="1rem">
              {request.cost?.amountPerCurrency.map((amount) => (
                <RequestWorkshopPrice
                  key={amount.currency}
                  currency={amount.currency}
                  label={
                    request.cost!.amountPerCurrency.length > 1
                      ? `${cost.labelWithoutVATInfo} ${amount.currency}`
                      : cost.labelWithoutVATInfo
                  }
                  priceAggregate={request.cost!}
                  articles={request.allArticles}
                />
              ))}
            </Stack>
          )}
          {price.enabled && (
            <div style={{ flex: 1 }}>
              <RequestOrganizationPrice
                price={request.price?.amountPerCurrency[0]}
                label={price.labelWithoutVATInfo}
              />
            </div>
          )}
        </Stack>
      )}
    </Stack>
  );
};
