import { createContext, useContext, useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ActionTypeSearchDialog } from '@/components/ActionTypeSearchDialog';
import ActionName from '@/components/ActionTypeSearchSelect/ActionName';
import { ActionTypeSearchSelect } from '@/components/ActionTypeSearchSelect/ActionTypeSearchSelect';
import CustomActionDialog from '@/components/ActionTypeSearchSelect/CustomActionDialog';
import PackActionName from '@/components/ActionTypeSearchSelect/PackActionName';
import { BaseMoneyCell } from '@/components/ArticlesTableCells';
import { DefectPhotos } from '@/components/DefectPhotos';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import {
  Body,
  Cell,
  Column,
  Footer,
  Header,
  InputMoneyCell,
  Row,
  Table,
  TextAreaCell,
} from '@/design_system/Table/Table';
import IconAdd from '@/icons/Add.svg';
import IconTools from '@/icons/Tools.svg';
import {
  ArticleAction,
  computeDuplicateActionNumber,
  getCustomPriceAmount,
  getPackActionTypeOrganizationWithRefashionStatus,
  useActionName,
} from '@/models/actionType';
import { useUpdateAction } from '@/models/article';
import { computeDuplicateDefectNumber, useGetDefectName } from '@/models/defectType';
import { type ArticleActionWithRelations, type ArticleWithRelations } from '@/models/request';
import { useWorkshops } from '@/models/workshop';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { RequestContextData } from '@/routes/Requests/contexts/interfaces';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import { Currency, formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import { BrandResponsibilityCell, DeleteButton, DeleteCell, EditButton } from './Cells';

import './ActionsTable.css';

const { block, element } = createBEMClasses('actions-table');

interface ActionsTableContextData {
  showOldActions: boolean;
  actions: ArticleActionWithRelations[];
  oldActions?: ArticleActionWithRelations[];
  handleStartEditCustomAction: (customAction: ArticleActionWithRelations) => void;
  mode: 'need' | 'both';
  showWarrantyCover?: boolean;
  isRequalification?: boolean;
  customActionWorkshopPriceCurrency: Currency;
  customActionOrganizationPriceCurrency: Currency;
}

export const ActionsTableContext = createContext({} as ActionsTableContextData);

export const ActionsTable = () => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();
  const { isMobile } = useViewPort();

  const { request, view: requestView } = useRequestContext();
  const { article, view: articleView } = useArticleContext();

  const showOldActions = article.step?.step === 'analysis' && !!article.requalifiedAt;
  const actions = article.snapshot.articleActions;
  const oldActions = (showOldActions ? article.previousSnapshot?.articleActions : []) ?? [];

  const [isOpenCustomActionDialog, setIsOpenCustomActionDialog] = useState(false);
  const [customActionToEdit, setCustomActionToEdit] = useState<ArticleActionWithRelations>();
  const [showAddActionOptions, setShowAddActionOptions] = useState(false);

  const handleCloseCustomActionDialog = () => {
    setIsOpenCustomActionDialog(false);
    setShowAddActionOptions(false);
    setCustomActionToEdit(undefined);
  };

  const handleStartEditCustomAction = (customAction: ArticleActionWithRelations) => {
    setIsOpenCustomActionDialog(true);
    setCustomActionToEdit(customAction);
  };

  const mode =
    article.step?.step === 'creation'
      ? !!article.step.config.requireCost || !!article.step.config.requirePrice
        ? 'both'
        : 'need'
      : 'both';

  const showWarrantyCover =
    article.step?.step !== 'creation' &&
    articleView.warranty.shown &&
    !currentSession?.workshop?.external;

  const isRequalification = article.task?.type === 'analyze_article';

  const { data: { workshops: [internalWorkshop] } = { workshops: [] } } = useWorkshops(
    {
      internal: true,
      limit: 1,
    },
    {
      enabled: !article.workshopId,
    }
  );

  const customActionWorkshopPriceCurrency =
    article.workshop?.currency ?? internalWorkshop?.currency ?? 'EUR';
  const customActionOrganizationPriceCurrency = request.defaultPriceCurrency ?? 'EUR';

  const contextValue = {
    showOldActions,
    actions,
    oldActions,
    handleStartEditCustomAction,
    mode,
    showWarrantyCover,
    isRequalification,
    customActionWorkshopPriceCurrency,
    customActionOrganizationPriceCurrency,
  } satisfies ActionsTableContextData;

  const columnWidths = {
    actionName: 'minmax(250px, 1fr)',

    defects: articleView.services.defects.shown && 'minmax(250px, 1.5fr)',

    defectPhotos: !articleView.services.defects.shown && 'minmax(250px, 1fr)',
    warrantyCover: showWarrantyCover && '120px',
    cost: requestView.cost.enabled && '120px',
    price: requestView.price.enabled && '120px',
    deleteColumn: articleView.services.actions.canAddRemoveAction && 'auto',
  };

  return (
    <ActionsTableContext.Provider value={contextValue}>
      <Stack gap="0.25rem" className={block()}>
        {!articleView.services.choice.shown && (
          <p className="label-100 text-primary">
            {mode === 'need' ? (
              <Trans id="article.form.actions.label.defect">Defects</Trans>
            ) : (
              <Trans id="article.form.actions.label">Care & repair actions</Trans>
            )}
          </p>
        )}

        {isMobile ? (
          <Stack gap="0.5rem">
            {[...oldActions, ...actions].length === 0 ? (
              <span className="paragraph-100-regular text-secondary">
                <Trans id="article.form.actions.table.no-actions-added">No actions added yet</Trans>
              </span>
            ) : (
              <>
                {oldActions.map((action) => (
                  <ActionCard
                    key={action.id}
                    action={action}
                    duplicateActionNumber={computeDuplicateActionNumber(action, oldActions)}
                    old
                  />
                ))}
                {actions.map((action) => (
                  <ActionCard
                    key={action.id}
                    action={action}
                    duplicateActionNumber={computeDuplicateActionNumber(action, actions)}
                  />
                ))}
              </>
            )}
            {articleView.services.actions.editable && (
              <CustomActionDialog
                isOpen={isOpenCustomActionDialog}
                onClose={handleCloseCustomActionDialog}
                initialCustomAction={customActionToEdit}
              />
            )}
            {articleView.services.actions.canAddRemoveAction && (
              <>
                <ActionTypeSearchDialog
                  isOpen={showAddActionOptions}
                  setIsOpen={setShowAddActionOptions}
                  setIsOpenCustomActionDialog={setIsOpenCustomActionDialog}
                />

                <AddActionButton setShowAddActionsOptions={setShowAddActionOptions} />
              </>
            )}
          </Stack>
        ) : (
          <Table
            ariaLabel={_(
              msg({
                id: 'article.form.actions.table',
                message: 'Actions',
              })
            )}
            columnWidths={[
              columnWidths.actionName,
              columnWidths.defects,
              columnWidths.defectPhotos,
              columnWidths.warrantyCover,
              columnWidths.cost,
              columnWidths.price,
              columnWidths.deleteColumn,
            ]}
            bordered
            variant="grid"
          >
            <Header>
              <Row>
                {![...oldActions, ...actions].length ? (
                  <Cell isWholeRow>
                    <span className="paragraph-200-regular">
                      <Trans id="article.form.actions.table.no-actions-added">
                        No actions added yet
                      </Trans>
                    </span>
                  </Cell>
                ) : (
                  <>
                    <Column>
                      <Trans id="article.form.actions.table.column.name.title.action">Action</Trans>
                    </Column>

                    {articleView.services.defects.shown && (
                      <Column>
                        <Trans id="article.form.actions.table.column.defects.title">Defects</Trans>
                      </Column>
                    )}

                    {!articleView.services.defects.shown && (
                      <Column>
                        <Trans id="article.form.actions.table.column.defect-photos.title">
                          Defect photos
                        </Trans>
                      </Column>
                    )}

                    {showWarrantyCover && (
                      <Column justifyContent="center">
                        <Trans id="article.form.actions.table.column.warranty-cover.title">
                          Warranty cover?
                        </Trans>
                      </Column>
                    )}
                    {requestView.cost.enabled && (
                      <Column justifyContent="end">{requestView.cost.label}</Column>
                    )}
                    {requestView.price.enabled && (
                      <Column justifyContent="end">{requestView.price.label}</Column>
                    )}
                    {articleView.services.actions.canAddRemoveAction && (
                      <Column justifyContent="flex-end" />
                    )}
                  </>
                )}
              </Row>
            </Header>

            <Body>
              {oldActions.map((action) => (
                <ActionRow
                  key={action.id}
                  action={action}
                  duplicateActionNumber={computeDuplicateActionNumber(action, oldActions)}
                  old
                />
              ))}
              {actions.map((action) => (
                <ActionRow
                  key={action.id}
                  action={action}
                  duplicateActionNumber={computeDuplicateActionNumber(action, actions)}
                />
              ))}
            </Body>
            {articleView.services.actions.canAddRemoveAction && (
              <Footer>
                <Row>
                  <Cell isWholeRow>
                    {!showAddActionOptions ? (
                      <AddActionButton setShowAddActionsOptions={setShowAddActionOptions} />
                    ) : (
                      <ActionTypeSearchSelect onBlur={() => setShowAddActionOptions(false)} />
                    )}
                  </Cell>
                </Row>
              </Footer>
            )}
          </Table>
        )}

        <ActionsTableErrors />
      </Stack>
    </ActionsTableContext.Provider>
  );
};

const OLD_ACTION_STYLE = {
  textDecoration: 'line-through',
  color: 'var(--color-text-disabled-black)',
};

const ActionCard = ({
  action,
  duplicateActionNumber,
  old,
}: {
  action: ArticleActionWithRelations;
  duplicateActionNumber?: number;
  old?: boolean;
}) => {
  const { view: requestView } = useRequestContext();
  const { view: articleView, errors } = useArticleContext();
  const { showWarrantyCover, handleStartEditCustomAction, isRequalification } =
    useContext(ActionsTableContext);

  const actionName = useActionName({ action, mode: 'action' });

  const hasError = !!errors.services?.actions?.actions?.find((act) => act.id === action.id)
    ?.hasError;

  const showEditButton = !old && action.isCustom && articleView.services.actions.editable;
  const showDeleteButton = !old && articleView.services.actions.canAddRemoveAction;

  return (
    <Box
      padding="0"
      ariaLabel={actionName}
      style={{
        ...(old ? OLD_ACTION_STYLE : undefined),
        // eslint-disable-next-line lingui/no-unlocalized-strings
        ...(hasError ? { border: '1px solid var(--color-danger-700)' } : undefined),
      }}
    >
      <Stack padding="16px" gap="0.75rem" className="bg-neutral-0" style={{ borderRadius: '8px' }}>
        {old && (
          <span className="visually-hidden">
            <Trans id="article.form.actions.old-action">Previous action</Trans>
          </span>
        )}

        <CardActionName action={action} duplicateActionNumber={duplicateActionNumber} />

        <hr />

        <Stack gap="0.5rem">
          {articleView.services.defects.shown && <CardDefects action={action} old={old} />}

          {!articleView.services.defects.shown && (
            <DefectPhotos
              action={action}
              size="medium"
              isDisabled={!articleView.services.actions.editable || old}
              isInvalid={
                !old &&
                errors.services?.actions?.actions?.find((act) => act.id === action.id)
                  ?.missingPhotos
              }
              isRequalification={isRequalification}
            />
          )}
          {showWarrantyCover && (
            <CardBrandResponsibility
              action={action}
              isEditActionDisabled={!articleView.services.actions.editable || old}
            />
          )}

          {(requestView.cost.enabled || requestView.price.enabled) && (
            <CardPrices action={action} />
          )}
        </Stack>

        {(showEditButton || showDeleteButton) && (
          <>
            <Stack gap="0.5rem">
              {showEditButton && <EditButton onPress={() => handleStartEditCustomAction(action)} />}

              {showDeleteButton && <DeleteButton action={action} />}
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
};

const AddActionButton = ({
  setShowAddActionsOptions,
}: {
  setShowAddActionsOptions: (show: boolean) => void;
}) => {
  return (
    <Button
      size="medium"
      variant="secondary"
      // eslint-disable-next-line lingui/no-unlocalized-strings
      style={{ flex: '0 auto' }}
      onPress={() => setShowAddActionsOptions(true)}
    >
      <IconAdd />
      <Trans id="actions-table.column.footer.add-action">Add an action</Trans>
    </Button>
  );
};

const CardDefects = ({ action, old }: { action: ArticleActionWithRelations; old?: boolean }) => {
  return (
    <Stack gap="0.25rem">
      <span className="label-100 paragraph-100-regular">
        <Trans id="article.form.actions.table.column.defects.title">Defects</Trans>
      </span>
      <Defects action={action} old={old} />
    </Stack>
  );
};

const CardBrandResponsibility = ({
  action,
  isEditActionDisabled,
}: {
  action: ArticleActionWithRelations;
  isEditActionDisabled?: boolean;
}) => {
  return (
    <Stack gap="0.25rem">
      <span className="label-100 paragraph-100-regular">
        <Trans id="article.form.actions.table.column.brand-responsibility.title">Brand Resp.</Trans>
      </span>
      <BrandResponsibilityCell action={action} size="small" isDisabled={isEditActionDisabled} />
    </Stack>
  );
};

const CardPrices = ({ action }: { action: ArticleActionWithRelations }) => {
  const { view: requestView } = useRequestContext();

  return (
    <>
      {requestView.cost.enabled && (
        <Stack gap="0.25rem">
          <span className="label-100 paragraph-100-regular">{requestView.cost.label}</span>
          <CardPrice type="cost" action={action} />
        </Stack>
      )}

      {requestView.price.enabled && (
        <Stack gap="0.25rem">
          <span className="label-100 paragraph-100-regular">{requestView.price.label}</span>
          <CardPrice type="price" action={action} />
        </Stack>
      )}
    </>
  );
};

const ActionRow = ({
  action,
  duplicateActionNumber,
  old,
}: {
  action: ArticleActionWithRelations;
  duplicateActionNumber?: number;
  old?: boolean;
}) => {
  const { view: requestView } = useRequestContext();
  const { view: articleView, errors } = useArticleContext();
  const { actions, showWarrantyCover, isRequalification } = useContext(ActionsTableContext);

  return (
    <Row>
      <ActionNameCell action={action} old={old} duplicateActionNumber={duplicateActionNumber} />

      {articleView.services.defects.shown && <DefectsCell action={action} old={old} />}

      {!articleView.services.defects.shown && (
        <Cell stretch>
          <DefectPhotos
            action={action}
            size="small"
            isDisabled={!articleView.services.actions.editable || old}
            isInvalid={
              !old &&
              errors.services?.actions?.actions?.find((act) => act.id === action.id)?.missingPhotos
            }
            isRequalification={isRequalification}
          />
        </Cell>
      )}
      {showWarrantyCover && (
        <Cell justifyContent="center" style={old ? OLD_ACTION_STYLE : undefined}>
          <BrandResponsibilityCell
            action={action}
            isDisabled={!articleView.services.actions.editable || old}
          />
        </Cell>
      )}

      {requestView.cost.enabled && <PriceCell type="cost" action={action} old={old} />}

      {requestView.price.enabled && <PriceCell type="price" action={action} old={old} />}

      {actions.length > 0 && articleView.services.actions.canAddRemoveAction && (
        <Cell
          justifyContent="flex-end"
          style={{ gap: '0.5rem', ...(old ? OLD_ACTION_STYLE : undefined) }}
        >
          {!old && <DeleteCell action={action} />}
        </Cell>
      )}
    </Row>
  );
};

const DefectsCell = ({ action, old }: { action: ArticleActionWithRelations; old?: boolean }) => {
  return (
    <Cell isEditable>
      <Defects action={action} old={old} />
    </Cell>
  );
};

const Defects = ({ action, old }: { action: ArticleActionWithRelations; old?: boolean }) => {
  const { _ } = useLingui();
  const { article, request, view: articleView } = useArticleContext();

  const { mutate: updateAction } = useUpdateAction({
    articleId: article.id,
    requestId: request.id,
  });

  const getDefectName = useGetDefectName();

  const defectOptions = article.snapshot.articleDefects;

  const actionDefectIds = action.articleDefects.map((defect) => defect.id);

  const addDefectsLabel = _(
    msg({ id: 'article.form.actions.defects.add-defects.label', message: 'Add defects' })
  );

  const isDisabled =
    !articleView.services.actions.editable || !articleView.services.actions.canEditDefects || old;

  return (
    <InputSelect
      className={element('defects-select')}
      variant="select"
      isMulti
      aria-label={!isDisabled ? addDefectsLabel : undefined}
      placeholder={
        !isDisabled
          ? addDefectsLabel
          : _(msg({ id: 'article.form.actions.defects.add-defects.none', message: 'None' }))
      }
      isSearchable={false}
      isDisabled={isDisabled}
      options={defectOptions}
      value={defectOptions.filter((defectOption) => actionDefectIds.includes(defectOption.id))}
      getOptionValue={(defectOption) => defectOption.id}
      getOptionLabel={(defectOption) => {
        const duplicateDefectNumber = computeDuplicateDefectNumber(defectOption, defectOptions);
        return `${getDefectName(defectOption)}${duplicateDefectNumber ? ` #${duplicateDefectNumber}` : ''}`;
      }}
      onChange={(defectOptions) => {
        updateAction({
          actionId: action.id,
          body: {
            defectIds: defectOptions.map((defectOption) => defectOption.id),
          },
        });
      }}
      onRemoveValue={(defectOptionId) => {
        updateAction({
          actionId: action.id,
          body: {
            defectIds: actionDefectIds.filter(
              (actionDefectId) => defectOptionId !== actionDefectId
            ),
          },
        });
      }}
    />
  );
};

const ActionNameCell = ({
  action,
  old,
  duplicateActionNumber,
}: {
  action: ArticleActionWithRelations;
  old?: boolean;
  duplicateActionNumber?: number;
}) => {
  const { currentSession } = useCurrentSession();
  const { _ } = useLingui();
  const { request, article, view, errors } = useArticleContext();

  const refashionStatus = currentSession?.workshop
    ? undefined
    : currentSession
      ? action.priceRefashionStatus
      : action.costRefashionStatus;

  const packActionType = currentSession?.workshop
    ? action.packActionTypeOrganization
    : currentSession
      ? getPackActionTypeOrganizationWithRefashionStatus(action, action.price)
      : getPackActionTypeOrganizationWithRefashionStatus(action, action.cost);

  const { mutateAsync: updateAction } = useUpdateAction({
    articleId: article.id,
    requestId: request.id,
  });

  const [customDescription, setCustomDescription] = useState(action.customDescription ?? '');
  const trimmedCustomDescription = customDescription.trim();

  return (
    <TextAreaCell
      cellProps={{
        style: old ? OLD_ACTION_STYLE : undefined,
        isReadonly: !action.isCustom || !view.services.actions.editable || old,
        isInvalid: errors.services?.actions?.actions?.find((act) => act.id === action.id)
          ?.missingDescription,
      }}
      ariaLabel={_(
        msg({
          id: 'article.form.actions.custom-description',
          message: 'Description of custom action',
        })
      )}
      onChange={(event) => setCustomDescription(event.target.value)}
      value={customDescription}
      onBlur={() => {
        const currentDescription = action.customDescription;
        updateAction({
          actionId: action.id,
          body: {
            description: trimmedCustomDescription,
          },
        }).catch(() => setCustomDescription(currentDescription ?? ''));
      }}
    >
      {old && (
        <span className="visually-hidden">
          <Trans id="article.form.actions.old-action">Previous action</Trans>
        </span>
      )}
      {!!action.actionTypeOrganization && (
        <ActionName
          actionType={action.actionTypeOrganization.actionType}
          refashionStatus={refashionStatus}
          duplicateActionNumber={duplicateActionNumber}
        />
      )}
      {!!packActionType && (
        <PackActionName
          packActionType={packActionType}
          duplicateActionNumber={duplicateActionNumber}
        />
      )}
      {action.isCustom &&
        (trimmedCustomDescription ? (
          <span>
            {trimmedCustomDescription} {!!duplicateActionNumber && ` #${duplicateActionNumber}`}
          </span>
        ) : (
          <span className="text-disabled">
            <Trans id="defects-table.column.defect.custom-placeholder">
              Write a description...
            </Trans>
          </span>
        ))}
    </TextAreaCell>
  );
};

const CardActionName = ({
  action,
  duplicateActionNumber,
}: {
  action: ArticleActionWithRelations;
  duplicateActionNumber?: number;
}) => {
  const { isWorkshop, currentSession } = useCurrentSession();
  const { errors } = useArticleContext();

  const refashionStatus = isWorkshop
    ? undefined
    : currentSession
      ? action.priceRefashionStatus
      : action.costRefashionStatus;

  const packActionType = isWorkshop
    ? action.packActionTypeOrganization
    : currentSession
      ? getPackActionTypeOrganizationWithRefashionStatus(action, action.price)
      : getPackActionTypeOrganizationWithRefashionStatus(action, action.cost);

  const trimmedCustomDescription = action.customDescription?.trim();

  const isMissingDescription = !!errors.services?.actions?.actions?.find(
    (act) => act.id === action.id
  )?.missingDescription;

  return (
    <Stack row gap="0.5rem" flexWrap="nowrap">
      <IconTools style={{ fontSize: '1.5rem' }} />

      {!!action.actionTypeOrganization && (
        <ActionName
          actionType={action.actionTypeOrganization.actionType}
          refashionStatus={refashionStatus}
          duplicateActionNumber={duplicateActionNumber}
        />
      )}

      {!!packActionType && (
        <PackActionName
          packActionType={packActionType}
          duplicateActionNumber={duplicateActionNumber}
        />
      )}

      {action.isCustom &&
        (trimmedCustomDescription ? (
          <p className="paragraph-100-regular">
            {trimmedCustomDescription}
            {!!duplicateActionNumber && ` #${duplicateActionNumber}`}
          </p>
        ) : (
          <p
            className="paragraph-100-regular"
            style={isMissingDescription ? { color: 'var(--color-danger-700)' } : undefined}
          >
            -
          </p>
        ))}
    </Stack>
  );
};

const CardPrice = ({ type, action }: { type: 'cost' | 'price'; action: ArticleAction }) => {
  const { article, errors } = useArticleContext();
  const { view: requestView } = useRequestContext();
  const { isWorkshop } = useCurrentSession();

  const { price, customFieldAmount, showAmountBeforeTaxes } = computePriceFromAction({
    action,
    type,
    article,
    requestView,
    isWorkshop,
  });

  const isInvalid =
    type === 'cost'
      ? errors.services?.actions?.actions?.find((act) => act.id === action.id)?.missingCost
      : errors.services?.actions?.actions?.find((act) => act.id === action.id)?.missingPrice;

  return action.isCustom && !price && customFieldAmount !== undefined ? (
    <span className="paragraph-100-regular text-disabled">
      {formatCurrency(customFieldAmount, 'EUR')}
    </span>
  ) : (
    <BaseMoneyCell
      price={price}
      className="paragraph-100-regular"
      isInvalid={isInvalid}
      showAmountBeforeTaxes={showAmountBeforeTaxes}
    />
  );
};

const PriceCell = ({
  type,
  action,
  old,
}: {
  type: 'cost' | 'price';
  action: ArticleAction;
  old?: boolean;
}) => {
  const { isWorkshop } = useCurrentSession();
  const { view: requestView } = useRequestContext();
  const { article, view: articleView, errors } = useArticleContext();

  const { mutate: updateAction } = useUpdateAction({
    articleId: article.id,
    requestId: article.requestId,
  });

  const { price, customFieldAmount, customField, showAmountBeforeTaxes } = computePriceFromAction({
    action,
    type,
    article,
    requestView,
    isWorkshop,
  });

  const canEdit =
    type === 'cost'
      ? articleView.services.actions.canEditCost
      : articleView.services.actions.canEditPrice;

  if (!canEdit) {
    return (
      <Cell justifyContent="flex-end" style={old ? OLD_ACTION_STYLE : undefined}>
        <BaseMoneyCell price={price} showAmountBeforeTaxes={showAmountBeforeTaxes} />
      </Cell>
    );
  }

  const isInvalid =
    type === 'cost'
      ? errors.services?.actions?.actions?.find((act) => act.id === action.id)?.missingCost
      : errors.services?.actions?.actions?.find((act) => act.id === action.id)?.missingPrice;

  return (
    <InputMoneyCell
      ariaLabel={requestView[type].label}
      style={{
        ...(old ? OLD_ACTION_STYLE : {}),
        textAlign: 'right',
      }}
      currency={price?.currency ?? 'EUR'}
      defaultValue={customFieldAmount}
      cellProps={{
        justifyContent: 'right',
        isReadonly: !action.isCustom || !articleView.services.actions.editable || old,
        isInvalid,
      }}
      onChange={(value) => {
        // The reason we are not using a state:
        // onBlur is called just after onChange, but before the state is updated
        // Therefore, we do not have the most recent value in onBlur
        action[customField] = isNaN(value)
          ? null
          : showAmountBeforeTaxes
            ? { amountBeforeTaxes: value }
            : { amount: value };
      }}
      onBlur={() => {
        updateAction({
          actionId: action.id,
          body: {
            [customField]: action[customField],
          },
        });
      }}
    >
      {action.isCustom && !price && customFieldAmount !== undefined ? (
        <span className="text-disabled">{formatCurrency(customFieldAmount, 'EUR')}</span>
      ) : (
        <BaseMoneyCell price={price} showAmountBeforeTaxes={showAmountBeforeTaxes} />
      )}
    </InputMoneyCell>
  );
};

const ActionsTableErrors = () => {
  const { errors } = useArticleContext();

  const tableErrors = [];

  if (errors.services?.actions?.noActions)
    tableErrors.push(
      <Trans id="article.form.actions.table.errors.no-actions">
        Please add at least one action.
      </Trans>
    );

  if (errors.services?.actions?.actions?.some((action) => action.missingDescription)) {
    tableErrors.push(
      <Trans id="article.form.actions.table.errors.missing-description">
        Please add a description for each action.
      </Trans>
    );
  }

  if (errors.services?.actions?.actions?.some((action) => action.missingPhotos)) {
    tableErrors.push(
      <Trans id="article.form.actions.table.errors.missing-photos">
        Please add at least one defect photo per action.
      </Trans>
    );
  }

  if (errors.services?.actions?.actions?.some((action) => action.missingCost)) {
    tableErrors.push(
      <Trans id="article.form.actions.table.errors.missing-cost">
        Please add the cost for each action.
      </Trans>
    );
  }

  if (errors.services?.actions?.actions?.some((action) => action.missingPrice)) {
    tableErrors.push(
      <Trans id="article.form.actions.table.errors.missing-price">
        Please add the price for each action.
      </Trans>
    );
  }

  if (tableErrors.length === 0) {
    return null;
  }

  return (
    <Stack gap="0.25rem" style={{ paddingTop: '0.25rem' }}>
      {tableErrors.map((error, index) => (
        <Message key={index} type="error">
          {error}
        </Message>
      ))}
    </Stack>
  );
};

const computePriceFromAction = ({
  action,
  type,
  article,
  requestView,
  isWorkshop,
}: {
  action: ArticleAction;
  type: 'cost' | 'price';
  article: ArticleWithRelations;
  requestView: RequestContextData['view'];
  isWorkshop: boolean;
}) => {
  const price =
    type === 'cost'
      ? action.cost?.amountPerEntity.find((amount) => amount.entityId === article.workshopId)
      : action.price?.amountPerEntity[0];

  const customField: 'customWorkshopPrice' | 'customOrganizationPrice' =
    type === 'cost'
      ? 'customWorkshopPrice'
      : isWorkshop
        ? 'customWorkshopPrice'
        : 'customOrganizationPrice';

  const showAmountBeforeTaxes =
    type === 'cost'
      ? requestView.cost.showAmountBeforeTaxes
      : requestView.price.showAmountBeforeTaxes;

  const customFieldAmount = getCustomPriceAmount({
    customPrice: action[customField],
    showAmountBeforeTaxes,
  });

  return { price, customField, customFieldAmount, showAmountBeforeTaxes };
};
