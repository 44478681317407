import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export const getLocalStorageValue = <T>(key: string) => {
  const valueString = window.localStorage.getItem(key);

  if (!valueString) {
    return null;
  }

  try {
    const value = JSON.parse(valueString) as T;

    return value;
  } catch (_) {
    window.localStorage.removeItem(key);

    console.warn(`Corrupted local storage value found for key ${key}: ${valueString}`);

    return null;
  }
};

export const useLocalStorageState = <T>(
  key: string,
  initialValue: T,
  forceInitialValue = false
) => {
  const [value, _setValue] = useState<T>(() => {
    const localStorageValue = getLocalStorageValue<T>(key);
    const initialState = forceInitialValue ? initialValue : (localStorageValue ?? initialValue);

    if (initialState !== localStorageValue) {
      localStorage.setItem(key, JSON.stringify(initialState));
    }

    return initialState;
  });

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (newValue: SetStateAction<T>) =>
      _setValue((prev) => {
        if (typeof newValue === 'function') {
          newValue = (newValue as (prevState: T) => T)(prev);
        }

        if (newValue === undefined) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, JSON.stringify(newValue));
        }

        return newValue;
      }),
    [key, _setValue]
  );

  return [value, setValue] as const;
};
