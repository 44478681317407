import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { PriceAggregate, PriceFeeDetail } from '@/api';
import { usePriceDetailsRows } from '@/components/PriceWithDetails/usePriceDetailsRows';
import Button from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack';
import Toggle from '@/design_system/Toggle';
import Tooltip from '@/design_system/Tooltip';
import IconInfo from '@/icons/Info.svg';
import { useArticleName } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';
import { useWorkshops, Workshop } from '@/models/workshop';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import { Currency, formatCurrency } from '@/utils/number';

import './RequestWorkshopPrice.css';

const { block, element } = createBEMClasses('request-workshop-price');

export const RequestWorkshopPrice = ({
  currency,
  priceAggregate,
  label,
  articles,
}: {
  currency: Currency;
  priceAggregate: PriceAggregate;
  label?: string;
  articles: ArticleWithRelations[];
}) => {
  const { _ } = useLingui();
  const { isWorkshop } = useCurrentSession();
  const { view: requestView } = useRequestContext();
  const [showDetails, setShowDetails] = useState(false);

  const showAmountBeforeTaxes = isWorkshop
    ? requestView.price.showAmountBeforeTaxes
    : requestView.cost.showAmountBeforeTaxes;
  const price = priceAggregate?.amountPerCurrency.find((amount) => amount.currency === currency);
  const priceDetailsRows = usePriceDetailsRows(price, {
    showTaxDetails: showDetails,
    showAmountBeforeTaxes,
  });

  if (!price) {
    return null;
  }

  return (
    <Stack
      className={block({}, 'bg-neutral-200')}
      gap="0.5rem"
      padding="0.75rem 1rem"
      style={{ borderRadius: '0.5rem' }}
      ariaLabel={label}
    >
      <Stack row gap="0.5rem" justifyContent="space-between">
        <p>{label && <span className="paragraph-100-medium text-primary">{label}</span>}</p>
        <Toggle
          label={<Trans id="request.articles.cost-details">Details</Trans>}
          size="small"
          isSelected={showDetails}
          onChange={() => setShowDetails(!showDetails)}
        />
      </Stack>

      {priceDetailsRows.map((section, sectionIndex) => {
        const isTotalSection = sectionIndex === priceDetailsRows.length - 1;

        return (
          <Stack gap="0.125rem" key={sectionIndex}>
            {priceDetailsRows.length > 1 && isTotalSection && (
              <div className={element('separator')} />
            )}
            {section.map((row, rowIndex) => {
              return (
                <Stack
                  ariaLabel={_(row.label)}
                  key={rowIndex}
                  row
                  flexWrap="nowrap"
                  justifyContent="space-between"
                  className={element(
                    'row',
                    {},
                    `text-secondary ${row.highlight ? (isTotalSection ? 'paragraph-100-regular' : 'paragraph-200-regular') : 'paragraph-400-regular'}`
                  )}
                >
                  <Stack row alignItems="center" gap="0.25rem">
                    {_(row.label)}
                    {row.type === 'fee' && !!row.subType && (
                      <RequestFeesDetails
                        priceAggregate={priceAggregate}
                        currency={currency}
                        articles={articles}
                        feeType={row.subType}
                        showAmountBeforeTaxes={showAmountBeforeTaxes || showDetails}
                      />
                    )}
                  </Stack>
                  <p>{formatCurrency(row.amount, currency)}</p>
                </Stack>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
};

const RequestFeesDetails = ({
  priceAggregate,
  currency,
  articles,
  feeType,
  showAmountBeforeTaxes,
}: {
  priceAggregate: PriceAggregate;
  currency: Currency;
  articles: ArticleWithRelations[];
  feeType: 'shipping-management' | 'cancellation';
  showAmountBeforeTaxes: boolean;
}) => {
  const { currentSession } = useCurrentSession();

  const articlesAmounts = priceAggregate.components
    .filter((componentPriceAggregate) => componentPriceAggregate.subType === 'article')
    .map((componentPriceAggregate) => {
      return {
        article: articles.find((article) => article.id === componentPriceAggregate.componentId)!,
        fees: componentPriceAggregate.amountPerEntity
          // Remove fees that are not of the correct type or currency
          .filter(
            (amount) =>
              amount.currency === currency &&
              amount.details.some((detail) => detail.type === 'fee' && detail.subType === feeType)
          )
          .map((amount) => ({
            // For both 'shipping-management' and 'cancellation' fees, the `entityId` is the workshop id if user is not a workshop user
            workshopId: currentSession?.workshop ? currentSession?.workshop.id : amount.entityId!,
            priceDetail: amount.details.find(
              (detail) => detail.type === 'fee' && detail.subType === feeType
            ) as PriceFeeDetail,
          })),
      };
    })
    .filter(({ fees }) => fees.length > 0);

  const workshopsIds = [
    ...new Set(articlesAmounts.map(({ fees }) => fees.map(({ workshopId }) => workshopId)).flat()),
  ];

  const { data: { workshops } = { workshops: [] }, isFetching: isFetchingWorkshops } = useWorkshops(
    {
      ids: workshopsIds,
    }
  );

  if (isFetchingWorkshops) {
    return null;
  }

  return (
    <Tooltip
      content={
        <Stack gap="0.25rem">
          {articlesAmounts.map(({ article, fees }) => (
            <ArticleFees
              key={article.id}
              article={article}
              fees={fees.map(({ workshopId, priceDetail }) => ({
                workshop: workshops.find((workshop) => workshop.id === workshopId)!,
                priceDetail,
              }))}
              showAmountBeforeTaxes={showAmountBeforeTaxes}
            />
          ))}
        </Stack>
      }
      textAlign="start"
    >
      <Button variant="style-less">
        <IconInfo style={{ fontSize: '1rem' }} className="text-disabled" />
      </Button>
    </Tooltip>
  );
};

const ArticleFees = ({
  article,
  fees,
  showAmountBeforeTaxes,
}: {
  article: ArticleWithRelations;
  fees: {
    workshop: Workshop;
    priceDetail: PriceFeeDetail;
  }[];
  showAmountBeforeTaxes: boolean;
}) => {
  const articleName = useArticleName({ article });

  return (
    <Stack key={article.id}>
      <p className="paragraph-200-regular text-secondary text-disabled">{articleName}</p>
      {fees.map(({ workshop, priceDetail }) => (
        <Stack key={workshop.id} row gap="0.25rem">
          <p className="paragraph-200-regular text-secondary text-neutral-0">
            <Trans id="client.articles.table.cost.fees-details">
              • {workshop.name}:{' '}
              {formatCurrency(
                showAmountBeforeTaxes ? priceDetail.amountBeforeTaxes : priceDetail.amount,
                priceDetail.currency
              )}
            </Trans>
          </p>
        </Stack>
      ))}
    </Stack>
  );
};
