import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import IconStoreRepair from '@/icons/StoreRepair.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import { useArticleName } from '@/models/article';
import type { TNotificationOfType } from '@/models/notification';

export const AnalysisRefusedNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'analysis_refused'>;
}) => {
  const { _ } = useLingui();

  const articleName = useArticleName({ article: notification.article });

  return (
    <BaseNotification
      notificationLabel={_(
        msg({
          id: 'notification.analysis-refused.label',
          message: `${notification.data.workshopName} has refused the job for the item ${articleName} of request ${notification.request.reference}`,
        })
      )}
      icon={<IconStoreRepair />}
      type="warning"
      notification={notification}
      notificationHeader={
        <Trans id="notification.analysis-refused">
          <span className="paragraph-100-medium">{notification.data.workshopName}</span> has refused
          the job for the item {articleName}
        </Trans>
      }
    />
  );
};
