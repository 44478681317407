import { RequestWithRelations } from '@/models/request';
import {
  PaymentStepMessage,
  ValidationStepMessage,
} from '@/routes/Requests/components/StepMessage';
import {
  ArchivedStepMessage,
  TransitStepMessage,
} from '@/routes/Requests/Request/components/RequestStepMessage/components';

export const RequestStepMessage = ({ request }: { request: RequestWithRelations }) => {
  if (request.isArchived) {
    return <ArchivedStepMessage request={request} />;
  }

  const atLeastOneItemInProgressValidation = request.articles.some(
    (article) =>
      article.step?.step === 'validation' &&
      article.steps?.find((step) => step.id === article.step?.id)?.status === 'in-progress'
  );

  if (atLeastOneItemInProgressValidation) {
    return <ValidationStepMessage request={request} />;
  }

  const atLeastOneItemInProgressPayment = request.articles.some(
    (article) =>
      article.step?.step === 'payment' &&
      article.steps?.find((step) => step.id === article.step?.id)?.status === 'in-progress'
  );

  if (atLeastOneItemInProgressPayment) {
    return <PaymentStepMessage request={request} />;
  }

  const atLeastOneItemInPendingClientPickup = request.articles.some(
    (article) =>
      article.step?.step === 'transit' &&
      article.step?.config.originType === 'store' &&
      article.step?.config.destinationType === 'client'
  );

  // request.isInPendingClientDeliveryStep is still used to handle pre-workflow requests.
  // It should be removed when all legacy requests are archived.

  if (atLeastOneItemInPendingClientPickup || request.isInLegacyPendingClientDeliveryStep) {
    return <TransitStepMessage request={request} />;
  }

  return null;
};
