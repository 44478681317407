import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import { useShowToast } from '@/design_system/Toast';
import IconError from '@/icons/Error.svg';
import { useAcceptDispatch, useRefuseDispatch } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

import { ArticleActionProps } from './ArticleActions';

export const AcceptDispatchAction = ({ article, request, onActionDone }: ArticleActionProps) => {
  const { isMobile } = useViewPort();
  const [isOpenRefuseDialog, setIsOpenRefuseDialog] = useState(false);

  const {
    mutateAsync: acceptDispatch,
    isPending: isAcceptDispatchPending,
    isSuccess: isAcceptDispatchSuccess,
  } = useAcceptDispatch({
    articleId: article.id,
  });

  const handleAcceptDispatch = async () => {
    await acceptDispatch();
    onActionDone();
  };

  return (
    <>
      <Stack row={!isMobile} gap="0.5rem">
        <Button
          size="medium"
          variant="secondary-danger"
          onPress={() => setIsOpenRefuseDialog(true)}
        >
          <IconError />
          <Trans id="article.actions.accept_dispatch.refuse">Refuse the job</Trans>
        </Button>
        <Button
          variant="primary"
          size="medium"
          onPress={handleAcceptDispatch}
          isLoading={isAcceptDispatchPending || isAcceptDispatchSuccess}
        >
          <Trans id="article.actions.accept_dispatch.accept">Accept the job</Trans>
        </Button>
      </Stack>

      <RefuseDispatchDialog
        article={article}
        isOpen={isOpenRefuseDialog}
        setIsOpen={setIsOpenRefuseDialog}
        // If the workshop is refusing the only item of the request, after the refusal it won't
        // be able to see the request anymore.
        onActionDone={() => onActionDone({ navigateToRequests: request.articles.length === 1 })}
      />
    </>
  );
};

const RefuseDispatchDialog = ({
  article,
  isOpen,
  setIsOpen,
  onActionDone,
}: {
  article: ArticleWithRelations;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onActionDone: () => void;
}) => {
  const { _ } = useLingui();
  const showToast = useShowToast();
  const { isWorkshop } = useCurrentSession();

  const {
    mutateAsync: refuseDispatch,
    isPending: isRefuseDispatchPending,
    isSuccess: isRefuseDispatchSuccess,
  } = useRefuseDispatch({
    article,
  });

  const [showErrors, setShowErrors] = useState(false);

  const [reason, setReason] = useState('');

  const save = async () => {
    setShowErrors(true);

    if (!reason) {
      return;
    }

    await refuseDispatch({ refusalReason: reason });

    showToast({
      type: 'success',
      text: _(
        msg({
          id: 'dispatch-refusal-modal.actions.refuse.success',
          message: 'The item has been successfully refused',
        })
      ),
    });

    onActionDone();
  };

  return (
    <Dialog
      title={_(msg({ id: 'dispatch-refusal-modal.title', message: 'Refuse the job' }))}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
    >
      <main>
        <Stack gap="1rem">
          <p className="paragraph-100-regular">
            {isWorkshop ? (
              <Trans id="dispatch-refusal-modal.text">
                Once you refuse the job, the item won&apos;t appear anymore on your requests
                dashboard. Please specify the reason for your refusal.
              </Trans>
            ) : (
              <Trans id="dispatch-refusal-modal.on-behalf.text">
                Please specify the reason for the workshop&apos;s refusal.
              </Trans>
            )}
          </p>
          <TextArea
            ariaLabel={_(
              msg({
                id: 'dispatch-refusal-modal.reason.label',
                message: 'Refusal reason',
              })
            )}
            placeholder={_(
              msg({
                id: 'dispatch-refusal-modal.reason.placeholder',
                message: 'Write refusal reason',
              })
            )}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            isInvalid={showErrors && !reason}
          />
        </Stack>
      </main>
      <footer>
        <Button variant="secondary" size="medium" onPress={() => setIsOpen(false)}>
          <Trans id="dispatch-refusal-modal.actions.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="danger"
          size="medium"
          onPress={save}
          isLoading={isRefuseDispatchPending || isRefuseDispatchSuccess}
        >
          <Trans id="dispatch-refusal-modal.actions.refuse">Refuse the job</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};
