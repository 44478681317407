import {
  DialogTrigger,
  OverlayArrow,
  Popover,
  Tooltip as AriaTooltip,
  TooltipTrigger,
} from 'react-aria-components';

import { createBEMClasses } from '@/utils/classname';
import { checkIfTouchDevice } from '@/utils/useViewport';

import './Tooltip.css';

const { block, element } = createBEMClasses('tooltip');

const Tooltip = ({
  variant = 'dark',
  size = 'small',
  content,
  children,
  placement = 'top',
  textAlign = 'center',
  className,
  hasOverlayArrow = true,
  hideOnTouchDevice,
}: {
  variant?: 'light' | 'dark';
  size?: 'small' | 'medium';
  content: React.ReactNode;
  children: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  textAlign?: 'center' | 'start';
  className?: string;
  hasOverlayArrow?: boolean;
  hideOnTouchDevice?: boolean;
}) => {
  const isTouchDevice = checkIfTouchDevice();

  if (!hideOnTouchDevice && isTouchDevice) {
    return (
      <DialogTrigger>
        {children}
        <Popover
          className={block({ variant, size, 'text-align': textAlign }, className)}
          placement={placement}
          offset={hasOverlayArrow ? 8 : 0}
        >
          {hasOverlayArrow && (
            <OverlayArrow className={element('overlay')}>
              <svg width={8} height={8}>
                <path d="M0 0,L4 4,L8 0" />
              </svg>
            </OverlayArrow>
          )}
          {content}
        </Popover>
      </DialogTrigger>
    );
  }

  return (
    <TooltipTrigger delay={0} closeDelay={0}>
      {children}
      <AriaTooltip
        className={block({ variant, size, 'text-align': textAlign }, className)}
        placement={placement}
        offset={hasOverlayArrow ? 8 : 0}
      >
        {hasOverlayArrow && (
          <OverlayArrow className={element('overlay')}>
            <svg width={8} height={8} viewBox="0 0 8 8">
              <path d="M0 0 L4 4 L8 0" />
            </svg>
          </OverlayArrow>
        )}
        {content}
      </AriaTooltip>
    </TooltipTrigger>
  );
};

export default Tooltip;
