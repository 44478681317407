import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Flags, PublicKeys } from '@/api';
import InputOTP from '@/components/InputOTP';
import Button from '@/design_system/Button';
import InputText from '@/design_system/InputText/InputText';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack/Stack';
import { MfaMethod, useCompleteMfa, useCreateSession, UserWithRelations } from '@/models/user';
import { useCurrentSession, useFlags, usePublicKeys } from '@/services/auth';

type CompleteLoginFn = (data: {
  user: UserWithRelations;
  flags: Flags;
  publicKeys: PublicKeys;
}) => void;

const Login = () => {
  const navigate = useNavigate();

  const { setCurrentSession } = useCurrentSession();
  const { setFlags } = useFlags();
  const { setPublicKeys } = usePublicKeys();

  const [step, setStep] = useState<'login' | 'mfa-email' | 'mfa-expired'>('login');
  const [mfaToken, setMfaToken] = useState<string>();

  const completeLogin: CompleteLoginFn = ({ user, flags, publicKeys }) => {
    setCurrentSession(user);
    setFlags(flags);
    setPublicKeys(publicKeys);

    const redirectTo = new URLSearchParams(window.location.search).get('redirect') ?? '';

    if (redirectTo === '/' || redirectTo.match(/^\/[^/]/)) {
      navigate(redirectTo, { replace: true });
      return;
    }

    navigate('/requests', { replace: true });
  };

  if (step === 'login' || step === 'mfa-expired') {
    return (
      <LoginForm
        onCompleteLogin={completeLogin}
        onMfaRequired={(mfaToken: string, mfaMethod: MfaMethod['type']) => {
          setMfaToken(mfaToken);
          setStep(`mfa-${mfaMethod}`);
        }}
        mfaHasExpired={step === 'mfa-expired'}
      />
    );
  }

  if (step === 'mfa-email' && !!mfaToken) {
    return (
      <MfaEmailForm
        mfaToken={mfaToken}
        onCompleteLogin={completeLogin}
        onMfaExpired={() => setStep('mfa-expired')}
      />
    );
  }
};

const LoginForm = ({
  onCompleteLogin,
  onMfaRequired,
  mfaHasExpired,
}: {
  onCompleteLogin: CompleteLoginFn;
  onMfaRequired: (mfaToken: string, method: 'email') => void;
  mfaHasExpired: boolean;
}) => {
  const { _ } = useLingui();

  const { mutateAsync, isPending, isSuccess } = useCreateSession();

  const mfaExpiredError = _(
    msg({
      id: 'login.error.mfa-expired',
      message: 'The 2-step verification token has expired. Please try logging in again.',
    })
  );

  const [error, setError] = useState(mfaHasExpired ? mfaExpiredError : '');
  const [email, setEmail] = useState('');

  const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    setError('');

    const data = new FormData(evt.target as HTMLFormElement);

    mutateAsync({
      email: data.get('email') as string,
      password: data.get('password') as string,
    })
      .then((response) => {
        if ('mfaToken' in response) {
          // For now there is only one MFA method (email) so the code has already been sent to the user
          // In the future, when there are other methods, we'll allow the user to choose one
          onMfaRequired(response.mfaToken, response.mfaMethods[0].type);
        } else {
          onCompleteLogin(response);
        }
      })
      .catch((err) => {
        if (err.message === 'Invalid credentials') {
          setError(
            _(
              msg({
                id: 'login.error.incorrect-email-or-password',
                message: 'Email or password incorrect',
              })
            )
          );
        } else {
          console.error(err);
          setError(
            (err.message as string) ??
              _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
          );
        }
      });
  };

  return (
    <form onSubmit={onSubmit}>
      <h1 className="headline-200-bold text-center">
        <Trans id="login.title">Log in to continue</Trans>
      </h1>
      <Stack gap="1.5rem">
        <InputText
          isRequired
          name="email"
          label={<Trans id="login.input.email.label">Email</Trans>}
          type="email"
          inputMode="email"
          placeholder={_(
            msg({
              id: 'login.input.email.placeholder',
              message: 'Enter your email address...',
            })
          )}
          messageType={error ? 'error' : undefined}
          onChange={setEmail}
        />
        <InputText
          isRequired
          name="password"
          label={<Trans id="login.input.password.label">Password</Trans>}
          type="password"
          placeholder={_(
            msg({
              id: 'login.input.password.placeholder',
              message: 'Enter your password...',
            })
          )}
          messageType={error ? 'error' : undefined}
        />
        {error && <span className="paragraph-100-medium text-danger text-center">{error}</span>}
        <Link className="text-link" to={`/forgotten-password?email=${encodeURIComponent(email)}`}>
          <Trans id="login.forget-password">Forgot your password?</Trans>
        </Link>
        <Button type="submit" isLoading={isPending || isSuccess} disabled={isPending || isSuccess}>
          <Trans id="login.continue">Continue</Trans>
        </Button>
      </Stack>
    </form>
  );
};

const MfaEmailForm = ({
  mfaToken,
  onCompleteLogin,
  onMfaExpired,
}: {
  mfaToken: string;
  onCompleteLogin: CompleteLoginFn;
  onMfaExpired: () => void;
}) => {
  const { _ } = useLingui();

  const [code, setCode] = useState('');

  const [error, setError] = useState('');

  const { mutateAsync: completeMfa, isPending: isPendingCompleteMfa } = useCompleteMfa();

  const handleVerify = () => {
    if (!code || code.length !== 6) {
      setError(
        _(
          msg({
            id: 'login.mfa-email-form.error.no-code',
            message: 'Please insert a 6-digit code',
          })
        )
      );
      return;
    }

    completeMfa({ mfaMethod: 'email', mfaToken, code })
      .then(onCompleteLogin)
      .catch((err) => {
        if (err.message.includes('Expired code')) {
          onMfaExpired();
        } else if (err.message.includes('Invalid code')) {
          setError(
            _(
              msg({
                id: 'login.mfa-email-form.error.invalid-code',
                message: 'The code is invalid, please try again',
              })
            )
          );
        } else {
          console.error(err);
          setError(
            (err.message as string) ??
              _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
          );
        }
      });
  };

  return (
    <Stack gap="1rem" alignItems="center">
      <h1 className="headline-200-bold">
        <Trans id="login.mfa-email-form.title">2-step verification</Trans>
      </h1>
      <p className="paragraph-100-regular text-center">
        <Trans id="login.mfa-email-form.text">
          We have just sent a 6-digit verification code to your email.
          <br />
          Please insert it below:
        </Trans>
      </p>
      <InputOTP value={code} onChange={setCode} onComplete={handleVerify} />
      <Button variant="primary" onPress={handleVerify} isLoading={isPendingCompleteMfa}>
        <Trans id="login.mfa-email-form.verify">Verify</Trans>
      </Button>
      {!!error && <Message type="error">{error}</Message>}
    </Stack>
  );
};

export default Login;
