import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  CustomDefectDropdownItem,
  DefectTypeDropdownItem,
} from '@/components/DefectTypeDropdownItem';
import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import InputSearch from '@/design_system/InputSearch';
import Stack from '@/design_system/Stack';
import { useCreateDefect } from '@/models/article';
import { DefectTypeOrganizationWithRelations, useDefectTypes } from '@/models/defectType';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import useDebouncedState from '@/utils/useDebouncedState';

export const DefectTypeSearchDialog = ({
  isOpen,
  setIsOpen,
  setIsOpenCustomDefectDialog,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsOpenCustomDefectDialog: (isOpen: boolean) => void;
}) => {
  const { _ } = useLingui();
  const { workflow } = useRequestContext();
  const { article, request } = useArticleContext();
  const [query, debouncedQuery, setQuery] = useDebouncedState<string>('', 500);
  const { data: defectTypes = [], isFetching } = useDefectTypes(
    {
      requestId: request.id,
      productL1: article.productL1 ?? undefined,
      productL2: article.productL2 ?? undefined,
      productL3: article.productL3 ?? undefined,
      query: debouncedQuery ?? undefined,
    },
    {
      keepPreviousData: true,
    }
  );

  const allowCustomDefects = !!workflow?.config.allowCustomDefects;

  const { mutate: createDefect } = useCreateDefect({
    requestId: request.id,
    articleId: article.id,
  });

  const handleAddDefect = ({ defectType }: { defectType: DefectTypeOrganizationWithRelations }) => {
    createDefect({ defectTypeOrganization: defectType });
    setIsOpen(false);
  };

  return (
    <Dialog
      title={_(msg({ id: 'defect-type-search-dialog.add-defect.label', message: 'Add a defect' }))}
      isOpen={isOpen}
      onOpenChange={() => setIsOpen(false)}
      fullHeight
    >
      <main>
        <Stack gap="0.5rem">
          <InputSearch
            aria-label={_(
              msg({ id: 'defect-type-search-dialog.add-defect.label', message: 'Add a defect' })
            )}
            placeholder={_(
              msg({
                id: 'defect-type-search-dialog.add-defect.placeholder',
                message: 'Search a defect to add...',
              })
            )}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            isLoading={isFetching || query !== debouncedQuery}
            value={query}
            onChange={setQuery}
          />

          {defectTypes.length === 0 && (
            <span className="paragraph-100-regular text-secondary" style={{ padding: '0.5rem 0' }}>
              <Trans id="defect-type-search-dialog.no-results">
                No results matching your search
              </Trans>
            </span>
          )}

          <DefectTypesMenu defectTypeOptions={defectTypes} onAddDefect={handleAddDefect} />
        </Stack>
      </main>

      {allowCustomDefects && (
        <footer>
          <Button variant="secondary" onPress={() => setIsOpenCustomDefectDialog(true)}>
            <CustomDefectDropdownItem style={{ display: 'flex', justifyContent: 'center' }} />
          </Button>
        </footer>
      )}
    </Dialog>
  );
};

const DefectTypesMenu = ({
  defectTypeOptions,
  onAddDefect,
}: {
  defectTypeOptions: DefectTypeOrganizationWithRelations[];
  onAddDefect: ({ defectType }: { defectType: DefectTypeOrganizationWithRelations }) => void;
}) => {
  return (
    <Stack>
      {defectTypeOptions.map((defectTypeOption) => (
        <DefectTypeMenuItem
          key={defectTypeOption.id}
          defectTypeOption={defectTypeOption}
          onAddDefect={onAddDefect}
        />
      ))}
    </Stack>
  );
};

const DefectTypeMenuItem = ({
  defectTypeOption,
  onAddDefect,
}: {
  defectTypeOption: DefectTypeOrganizationWithRelations;
  onAddDefect: ({ defectType }: { defectType: DefectTypeOrganizationWithRelations }) => void;
}) => {
  return (
    <Button
      style={{ padding: '0.5rem 1rem' }}
      variant="style-less"
      onPress={() => onAddDefect({ defectType: defectTypeOption })}
    >
      <DefectTypeDropdownItem defect={defectTypeOption} />
    </Button>
  );
};
