import { Trans } from '@lingui/macro';

import Stack from '@/design_system/Stack';
import IconAddToList from '@/icons/AddToList.svg';
import { DefectTypeOrganizationWithRelations } from '@/models/defectType';
import { useDatabaseTranslation } from '@/models/translation';

export const DefectTypeDropdownItem = ({
  defect,
}: {
  defect: DefectTypeOrganizationWithRelations;
}) => {
  const { _db } = useDatabaseTranslation();

  return (
    <Stack row style={{ flex: 1 }}>
      <p className="paragraph-100-regular">{_db(defect.defectType.name)}</p>
    </Stack>
  );
};

export const CustomDefectDropdownItem = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <Stack row gap="0.5rem" alignItems="center" style={{ flex: 1, ...style }}>
      <IconAddToList style={{ fontSize: '1.25rem' }} />
      <p className="paragraph-100-regular">
        <Trans id="defect-type-search-select.add-custom-defect">Add a custom defect</Trans>
      </p>
    </Stack>
  );
};
