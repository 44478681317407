import { useContext } from 'react';

import { ActionsTableContext } from '@/components/ActionsTable/ActionsTable';
import RefashionLogo, { RefashionStatus } from '@/components/RefashionLogo';
import Stack from '@/design_system/Stack';
import { ActionType } from '@/models/actionType';
import { useDatabaseTranslation } from '@/models/translation';
import { useFlags } from '@/services/auth';

const ActionName = ({
  actionType,
  refashionStatus,
  duplicateActionNumber,
  style,
}: {
  actionType: ActionType;
  refashionStatus?: RefashionStatus | null;
  duplicateActionNumber?: number;
  style?: React.CSSProperties;
}) => {
  const { _db } = useDatabaseTranslation();
  const { flags } = useFlags();

  const { mode } = useContext(ActionsTableContext);

  return flags['enable-defect-action-split'] ? (
    <Stack style={style}>
      <p className="paragraph-100-regular">
        {_db(actionType.name)}
        {!!duplicateActionNumber && ` #${duplicateActionNumber}`}
        {!!refashionStatus && (
          <>
            {' '}
            <RefashionLogo status={refashionStatus} />
          </>
        )}
      </p>
    </Stack>
  ) : (
    <Stack>
      <p className="paragraph-100-medium">
        {_db(mode === 'need' ? actionType.needName : actionType.name)}
        {!!duplicateActionNumber && ` #${duplicateActionNumber}`}
        {!!refashionStatus && (
          <>
            {' '}
            <RefashionLogo status={refashionStatus} />
          </>
        )}
      </p>
      {mode === 'both' && <p className="paragraph-200-regular">{_db(actionType.needName)}</p>}
    </Stack>
  );
};

export default ActionName;
