import { useCallback, useMemo, useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import debounce from 'lodash.debounce';

import { InputSearchSelect } from '@/design_system/InputSearchSelect/InputSearchSelect';
import InputText from '@/design_system/InputText';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import { type ArticleBrand, useUpdateArticle } from '@/models/article';
import { type ArticleWithRelations } from '@/models/request';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import useViewport from '@/utils/useViewport';

export const Brand = () => {
  const { _ } = useLingui();
  const { isMobile } = useViewport();

  const { request, article, errors } = useArticleContext();

  const otherLabel = _(
    msg({
      id: 'article.form.brand.other.details.label',
      message: 'Other (specify the brand)...',
    })
  );

  const [brand, setBrand] = useState<ArticleBrand | null>(
    article.data.brand?.isOther
      ? {
          isOther: true,
          name: otherLabel,
        }
      : (article.data.brand ?? null)
  );
  const [otherBrand, setOtherBrand] = useState<string>(article.data.brand?.name ?? '');

  const brandOptions: ArticleBrand[] = [
    ...request.organization.brands.map((brand) => ({ isOther: false, name: brand })),
    {
      isOther: false,
      name: _(msg({ id: 'article.form.brand.unknown.label', message: 'Unknown' })),
    },
    {
      isOther: true,
      name: otherLabel,
    },
  ];

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const updateBrand = useCallback(
    (article: ArticleWithRelations, brand: ArticleBrand | null) => {
      updateArticle({
        data: {
          data: {
            ...article.data,
            brand: brand ?? undefined,
          },
        },
      });
    },
    [updateArticle]
  );

  const debouncedUpdateBrand = useMemo(() => {
    return debounce(updateBrand, 500);
  }, [updateBrand]);

  return (
    <Stack gap="0.25rem">
      <Stack row mobileColumn gap="1rem" alignItems={!isMobile ? 'flex-end' : undefined}>
        <div style={{ flex: 1, maxWidth: '100%' }}>
          <InputSearchSelect
            variant="select"
            label={_(msg({ id: 'article.form.brand.label', message: 'Brand' }))}
            placeholder={
              article.product && !article.product?.data.brand
                ? _(msg({ id: '_general.na', message: 'N/A' }))
                : _(msg({ id: 'article.form.brand.placeholder', message: 'Select a brand' }))
            }
            isDisabled={!!article.product || !article.productL1}
            value={
              article.data.brand
                ? article.data.brand.isOther
                  ? { isOther: true, name: otherLabel }
                  : article.data.brand
                : null
            }
            options={brandOptions}
            getOptionValue={(brand) => brand.name}
            onChange={(brand) => {
              setBrand(brand);
              setOtherBrand('');
              updateBrand(
                article,
                brand
                  ? {
                      isOther: brand.isOther,
                      name: brand.isOther ? '' : brand.name,
                    }
                  : null
              );
            }}
            getOptionLabel={(brand) => brand.name}
          />
        </div>
        {brand?.isOther && (
          <InputText
            style={{ flex: 1 }}
            aria-label={_(
              msg({
                id: 'article.form.brand.other.specify-name.label',
                message: 'Specify the brand name',
              })
            )}
            placeholder={_(
              msg({
                id: 'article.form.brand.other.specify-name.label',
                message: 'Specify the brand name',
              })
            )}
            value={otherBrand}
            onChange={(otherBrand) => {
              setOtherBrand(otherBrand);
              debouncedUpdateBrand(article, {
                isOther: true,
                name: otherBrand,
              });
            }}
            isInvalid={errors.details?.otherBrandEmpty}
          />
        )}
      </Stack>
      {errors.details?.otherBrandEmpty && (
        <Message type="error" showMessageIcon>
          <Trans id="article.form.brand.other.specify-name.error">Please select a brand name</Trans>
        </Message>
      )}
    </Stack>
  );
};
