import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Drawer, { DrawerBody, DrawerFooter, DrawerHeader } from '@/design_system/Drawer';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import IconUndo from '@/icons/Undo.svg';
import { useDiscardRequalification, useRequalify } from '@/models/article';
import { ArticleServices } from '@/routes/Requests/components/ArticleServices/ArticleServices';
import {
  ArticleContextProvider,
  useArticleContext,
} from '@/routes/Requests/contexts/ArticleContext';
import useViewPort from '@/utils/useViewport';

const RequalificationDrawer = ({
  isOpen,
  setIsOpen,
  onRequalified,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onRequalified: () => void;
}) => {
  const { article } = useArticleContext();

  const {
    mutateAsync: discardRequalification,
    isPending: isPendingDiscardRequalification,
    isSuccess: isSuccessDiscardRequalification,
    reset: resetDiscardRequalification,
  } = useDiscardRequalification({ articleId: article.id });

  const discard = async () => {
    await discardRequalification();
    setIsOpen(false);

    // Wait for the Drawer CSS exit animation to finish
    setTimeout(() => {
      resetDiscardRequalification();
    }, 150);
  };

  return (
    <Drawer
      isOpen={isOpen}
      onOpenChange={(newIsOpen) => {
        if (newIsOpen) {
          setIsOpen(true);
        } else {
          discard();
        }
      }}
    >
      <DrawerHeader>
        <h1 className="headline-200-bold">
          <Trans id="requalification-drawer.title">Propose actions re-qualification</Trans>
        </h1>
      </DrawerHeader>
      {/* Special case, we want to have a different view & errors for this drawer */}
      <ArticleContextProvider article={article} isRequalification>
        <RequalificationDrawerContent
          setIsOpen={setIsOpen}
          onRequalified={onRequalified}
          discard={discard}
          isPendingDiscard={isPendingDiscardRequalification || isSuccessDiscardRequalification}
        />
      </ArticleContextProvider>
    </Drawer>
  );
};

const RequalificationDrawerContent = ({
  setIsOpen,
  onRequalified,
  discard,
  isPendingDiscard,
}: {
  setIsOpen: (isOpen: boolean) => void;
  onRequalified: () => void;
  discard: () => Promise<void>;
  isPendingDiscard: boolean;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const { article, view, state, hasError } = useArticleContext();

  const [comment, setComment] = useState(article?.requalificationComment ?? '');
  const [apiError, setApiError] = useState<string | null>(null);

  const {
    mutateAsync: requalify,
    isPending: isPendingRequalify,
    isSuccess: isSuccessRequalify,
  } = useRequalify({
    articleId: article.id,
  });

  const submit = async () => {
    const { hasError } = state.errors.check({
      services: {
        defects: view.services.defects.shown
          ? {
              defects: {
                missingDescription: true,
                missingPhotos: true,
                missingActions: view.services.actions.shown,
              },
              noDefects: true,
            }
          : undefined,
        actions: view.services.actions.shown
          ? {
              actions: {
                missingDescription: true,
                missingPhotos: !view.services.defects.shown,
                missingPrice: true,
              },
              noActions: true,
            }
          : undefined,
      },
    });

    if (hasError) {
      return;
    }

    try {
      await requalify({
        comment: comment ?? null,
      });
      setIsOpen(false);

      // Wait for the Drawer CSS exit animation to finish
      setTimeout(() => {
        onRequalified();
      }, 150);
    } catch (err: any) {
      console.error(err);
      setApiError(
        (err.message as string) ??
          _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
      );
    }
  };

  return (
    <>
      <DrawerBody>
        <Stack gap="1rem" padding={isMobile ? '1rem' : '1.5rem 2rem'}>
          <p className="paragraph-100-regular text-secondary">
            <Trans id="requalification-modal.intro">
              If the initial required actions do not match the needs of the item, please search and
              add the appropriate ones below. Once submitted, the requalification will be reviewed
              by the After Sales Manager.
            </Trans>
          </p>

          <ArticleServices />

          <TextArea
            label={_(
              msg({
                id: 'requalification-modal.comment.label',
                message: 'Comments for the brand',
              })
            )}
            placeholder={_(
              msg({
                id: 'requalification-modal.comment.placeholder',
                message: 'Add a comment for the brand...',
              })
            )}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Stack>
      </DrawerBody>
      <DrawerFooter>
        <Stack
          gap="1rem"
          row
          alignItems="center"
          justifyContent="space-between"
          style={{ flex: 1 }}
        >
          {hasError && (
            <p className="paragraph-100-medium text-danger">
              <Trans id="article.actions.submit-requalification.errors">
                Please fill the missing information in order to submit the re-qualification proposal
              </Trans>
            </p>
          )}
          {apiError && <p className="paragraph-100-medium text-danger">{apiError}</p>}
          <Stack gap="0.5rem" row={!isMobile}>
            <Button
              variant="secondary"
              size="medium"
              onPress={discard}
              isLoading={isPendingDiscard}
              disabled={isPendingRequalify || isSuccessRequalify}
            >
              <IconUndo />
              <Trans id="requalification-drawer.reset">Reset changes</Trans>
            </Button>
            <Button
              variant="primary"
              size="medium"
              onPress={submit}
              isLoading={isPendingRequalify || isSuccessRequalify}
              disabled={isPendingDiscard}
            >
              <Trans id="requalification-drawer.submit">Submit re-qualification proposal</Trans>
            </Button>
          </Stack>
        </Stack>
      </DrawerFooter>
    </>
  );
};

export default RequalificationDrawer;
