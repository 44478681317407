import { Trans } from '@lingui/macro';

import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const ServiceChoiceStepMessage = ({ article }: { article: ArticleWithRelations }) => {
  if (!article.serviceChosenAt) {
    return (
      <BaseMessage
        title={<Trans id="article.step.service-choice.title">Waiting for service selection</Trans>}
        article={article}
      />
    );
  }

  if (article.serviceChoice === 'care-repair' && !article.workshopAcceptedAt) {
    return (
      <BaseMessage
        title={
          <Trans id="article.step.service-choice.workshop-validation.title">
            Waiting for workshop validation
          </Trans>
        }
        message={
          article.workshop?.nonDigitalized ? (
            <Trans id="article.step.service-choice.workshop-validation.on-behalf.text">
              The store will contact the workshop in order to validate the job on its behalf.
            </Trans>
          ) : (
            <Trans id="article.step.service-choice.workshop-validation.text">
              The workshop is analysing the item to determine if it can handle it.
            </Trans>
          )
        }
        article={article}
      />
    );
  }

  return null;
};
