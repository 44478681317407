import { useEffect } from 'react';
import { Link } from 'react-aria-components';
import { Trans } from '@lingui/macro';
import { useQueryClient } from '@tanstack/react-query';

import AlertBar from '@/design_system/AlertBar';
import { useActivities } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';

import { BaseMessage } from './BaseMessage';

export const ArticleArchivedMessage = ({ article }: { article: ArticleWithRelations }) => {
  if (!article.archivalDetail) {
    return null;
  }

  if (article.archivalDetail.type === 'completed') {
    if (article.cancellationDetail) {
      return <BaseMessage article={article} />;
    } else {
      return <CompletedMessage />;
    }
  }

  if (article.archivalDetail.type === 'manual') {
    return <ManuallyArchivedMessage />;
  }

  if (article.archivalDetail.type === 'automatic') {
    return <AutomaticallyArchivedMessage />;
  }

  return null;
};

const CompletedMessage = () => {
  return (
    <AlertBar
      type="success"
      size="large"
      hideIcon
      title={<Trans id="article.archived-message.completed.title">The item is completed</Trans>}
    />
  );
};

const ManuallyArchivedMessage = () => {
  const { article } = useArticleContext();

  const exportedToZendesk = article.archivalDetail?.reason === 'export-to-zendesk';

  if (exportedToZendesk) {
    return <ExportedToZendesk type="manual" />;
  }

  if (article.hasIssue) {
    return (
      <AlertBar
        type="error"
        size="large"
        hideIcon
        title={
          <Trans id="article.archived-message.issue.title">
            This item has been archived after a shipment issue
          </Trans>
        }
      >
        {!!article.archivalDetail?.reason && (
          <p>
            <Trans id="article.archived-message.issue.text">Archival reason:</Trans> &quot;
            {article.archivalDetail.reason}
            &quot;
          </p>
        )}
      </AlertBar>
    );
  }

  return (
    <AlertBar
      type="error"
      size="large"
      hideIcon
      title={<Trans id="article.archived-message.manual.title">The item is archived</Trans>}
    >
      <p>
        <Trans id="article.archived-message.manual.text">
          The item has been manually archived before its completion.
        </Trans>
      </p>
    </AlertBar>
  );
};

const AutomaticallyArchivedMessage = () => {
  const { article } = useArticleContext();

  const exportedToZendesk = article.archivalDetail?.reason === 'export-to-zendesk';

  if (exportedToZendesk) {
    return <ExportedToZendesk type="automatic" />;
  }

  return (
    <AlertBar
      type="error"
      size="large"
      hideIcon
      title={
        <Trans id="article.archived-message.automatic.title">
          The item has been automatically archived
        </Trans>
      }
    />
  );
};

const ExportedToZendesk = ({ type }: { type: 'manual' | 'automatic' }) => {
  const { request } = useRequestContext();
  const { article } = useArticleContext();

  const { data: { activities } = { activities: [] } } = useActivities(
    {
      articleId: article.id,
      types: ['article_sent_to_zendesk'],
    },
    {
      refetchInterval: (query) => (query.state.data?.meta.count === 0 ? 5000 : false),
    }
  );

  const zendeskTicket =
    activities.length > 0
      ? {
          id: activities[0].data.ticket.id,
          url: `${request.organization.config.integrations?.zendesk?.baseUrl}/agent/tickets/${activities[0].data.ticket.id}`,
        }
      : null;

  const queryClient = useQueryClient();

  useEffect(() => {
    if (activities.length > 0) {
      queryClient.refetchQueries({
        queryKey: ['activities', article.id],
        exact: true,
      });
    }
  }, [queryClient, article.id, activities.length]);

  if (activities.length === 0) {
    return (
      <AlertBar
        type="error"
        size="large"
        hideIcon
        title={
          type === 'automatic' ? (
            <Trans id="article.archived-message.automatic-zendesk.title.loading">
              This item has been automatically archived and is being transferred to Zendesk
            </Trans>
          ) : (
            <Trans id="article.archived-message.manual-zendesk.title.loading">
              This item has been manually archived and is being transferred to Zendesk
            </Trans>
          )
        }
      />
    );
  }

  return (
    <AlertBar
      type="error"
      size="large"
      hideIcon
      title={
        type === 'automatic' ? (
          <Trans id="article.archived-message.automatic-zendesk.title">
            This item has been automatically archived and transferred to Zendesk
          </Trans>
        ) : (
          <Trans id="article.archived-message.manual-zendesk.title">
            This item has been manually archived and transferred to Zendesk
          </Trans>
        )
      }
    >
      {!!zendeskTicket && (
        <p>
          <Trans id="article.archived-message.zendesk.ticket">
            Please find attached the link to the related Zendesk ticket:
          </Trans>{' '}
          <Link target="_blank" href={zendeskTicket.url} style={{ color: 'inherit' }}>
            {zendeskTicket.id}
          </Link>
        </p>
      )}
    </AlertBar>
  );
};
