import { Trans } from '@lingui/macro';

import Badge from '@/design_system/Badge';
import { ShipmentWithRelations } from '@/models/shipment';

const ArticleReceivedVerificationStatusCell = ({
  shipmentArticle,
}: {
  shipmentArticle: ShipmentWithRelations['articles'][number];
}) => {
  const isVerified = shipmentArticle.verified;
  const hasIssue = !!shipmentArticle.issue;

  if (isVerified) {
    if (hasIssue) {
      return (
        <Badge color="danger" size="large">
          <Trans id="article.status.verification.issue">Shipment issue</Trans>
        </Badge>
      );
    } else {
      return (
        <Badge color="primary" size="large">
          <Trans id="article.status.verification.verified">Verified</Trans>
        </Badge>
      );
    }
  } else {
    return (
      <Badge color="neutral" size="large">
        <Trans id="article.status.verification.to-verify">To verify</Trans>
      </Badge>
    );
  }
};

export default ArticleReceivedVerificationStatusCell;
