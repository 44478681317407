import React, { createContext, CSSProperties, useContext, useState } from 'react';
import { To, useHref, useLinkClickHandler } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import { InputMoney, InputMoneyProps } from '@/design_system/InputNumber/InputNumber';
import TextArea, { TextAreaProps } from '@/design_system/TextArea/TextArea';
import Toggle, { ToggleProps } from '@/design_system/Toggle/Toggle';
import { createBEMClasses } from '@/utils/classname';

import './Table.css';

const { block, element } = createBEMClasses('table');

export const Table = ({
  ariaLabel,
  columnWidths,
  theme = 'default',
  variant = 'lines',
  bordered,
  className,
  children,
}: {
  ariaLabel?: string;
  columnWidths: string | (string | boolean | undefined | null)[];
  variant?: 'lines' | 'grid';
  theme?: 'default' | 'brand';
  bordered?: boolean;
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <table
      aria-label={ariaLabel}
      className={block(
        {
          theme,
          variant,
          bordered,
        },
        className
      )}
      style={{
        // @ts-ignore CSS variable
        '--column-widths': Array.isArray(columnWidths)
          ? columnWidths.filter((w) => !!w).join(' ')
          : columnWidths,
      }}
    >
      {children}
    </table>
  );
};

export const Header = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <thead className={className}>{children}</thead>;
};

export const Body = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <tbody className={className}>{children}</tbody>;
};

export const Footer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <tfoot className={className}>{children}</tfoot>;
};

const RowContext = createContext({
  to: undefined as To | undefined,
  navigationOptions: undefined as Parameters<typeof useLinkClickHandler>[1] | undefined,
});

const useRowOrAnchorLink = (
  to?: To,
  navigationOptions?: Parameters<typeof useLinkClickHandler>[1]
) => {
  const handleClick = useLinkClickHandler<HTMLTableRowElement | HTMLAnchorElement>(
    to ?? '',
    navigationOptions
  );

  return (event: React.MouseEvent<HTMLTableRowElement | HTMLAnchorElement, MouseEvent>) => {
    // If event was already handled, don't do it again
    if (event.defaultPrevented) {
      return;
    }

    if (!to) {
      return;
    }

    // Synthetic event in React are not related to the DOM, but the the React tree. Therefore, a portal component (e.g a modal) will still trigger an event on the original target.
    // Because we only want to trigger the navigation if the user clicks on the actual row, we need to check if the target is a DOM child of the row
    if (!(event.target as HTMLElement).closest('.table__row--clickable')) {
      return;
    }

    handleClick(event);

    event.preventDefault();
  };
};

export const Row = ({
  to,
  navigationOptions,
  isExtensionRow,
  children,
  className,
  style,
}: {
  to?: To;
  navigationOptions?: Parameters<typeof useLinkClickHandler>[1];
  isExtensionRow?: boolean;
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}) => {
  const handleClick = useRowOrAnchorLink(to ?? '', navigationOptions);

  return (
    <RowContext.Provider value={{ to, navigationOptions }}>
      <tr
        onClick={handleClick}
        className={element(
          'row',
          { clickable: !!to, 'is-extension-row': !!isExtensionRow },
          className
        )}
        style={style}
      >
        {children}
      </tr>
    </RowContext.Provider>
  );
};

export const Column = ({
  alignItems = 'center',
  justifyContent = 'flex-start',
  ariaLabel,
  children,
  className,
}: {
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  ariaLabel?: string;
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <th
      aria-label={ariaLabel}
      className={element('column', undefined, className)}
      style={{
        alignItems,
        justifyContent,
      }}
    >
      {children}
    </th>
  );
};

interface CellProps {
  isLink?: boolean;
  isWholeRow?: boolean;
  isEditable?: boolean;
  isInvalid?: boolean;
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  stretch?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}

export const Cell = ({
  isLink,
  isWholeRow,
  isEditable,
  isInvalid,
  alignItems = 'center',
  justifyContent = 'flex-start',
  stretch,
  children,
  className,
  style,
}: CellProps) => {
  const { to, navigationOptions } = useContext(RowContext);
  const href = useHref(to ?? '');
  const handleClick = useRowOrAnchorLink(to ?? '', navigationOptions);

  let cellContent = children;

  if (isLink && to) {
    cellContent = (
      <a href={href} onClick={handleClick}>
        {children}
      </a>
    );
  }

  return (
    <td
      className={element(
        'cell',
        {
          'is-link': !!isLink && !!to,
          'is-whole-row': isWholeRow,
          'is-editable': isEditable,
          'is-invalid': isInvalid,
          stretch,
        },
        className
      )}
      style={{
        alignItems,
        justifyContent,
        ...style,
      }}
    >
      {cellContent}
    </td>
  );
};

interface EditableCellProps extends Omit<CellProps, 'children'> {
  ariaLabel?: string;
  children: ({
    isEditing,
    setIsEditing,
  }: {
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
  }) => React.ReactNode;
  isReadonly?: boolean;
}

const EditableCell = ({ children, isReadonly, ...props }: EditableCellProps) => {
  const [isEditing, setIsEditing] = useState(false);

  let content = children({ isEditing, setIsEditing });

  if (!isEditing) {
    content = (
      <>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div className={element('cell__edit-button')} onClick={() => setIsEditing(true)}>
          {content}
        </div>
        <button
          className={element('cell__edit-button-aria', undefined, 'visually-hidden')}
          onClick={() => setIsEditing(true)}
        >
          <Trans id="table.input-money-cell.edit">Edit {props.ariaLabel}</Trans>
        </button>
      </>
    );
  }

  return (
    <Cell isEditable={!isReadonly} {...props}>
      {content}
    </Cell>
  );
};

export const TextAreaCell = ({
  children,
  onBlur,
  cellProps,
  ...props
}: TextAreaProps & { cellProps?: Omit<EditableCellProps, 'children'> }) => (
  <EditableCell {...cellProps}>
    {({ isEditing, setIsEditing }) =>
      isEditing && cellProps?.isReadonly !== true ? (
        <TextArea
          {...props}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onBlur={(evt) => {
            setIsEditing(false);
            onBlur?.(evt);
          }}
        />
      ) : (
        children
      )
    }
  </EditableCell>
);

export const InputMoneyCell = ({
  children,
  onBlur,
  cellProps,
  ...props
}: InputMoneyProps & {
  children: React.ReactNode;
  cellProps?: Omit<EditableCellProps, 'children'>;
}) => (
  <EditableCell {...cellProps}>
    {({ isEditing, setIsEditing }) =>
      isEditing && cellProps?.isReadonly !== true ? (
        <InputMoney
          {...props}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onBlur={(evt) => {
            setIsEditing(false);
            onBlur?.(evt);
          }}
        />
      ) : (
        children
      )
    }
  </EditableCell>
);

export const ToggleCell = ({
  cellProps,
  ...props
}: ToggleProps & {
  cellProps?: Omit<CellProps, 'children'>;
}) => (
  <Cell isEditable {...cellProps}>
    <Toggle {...props} />
  </Cell>
);
