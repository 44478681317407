import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import config from '@/config';
import Button from '@/design_system/Button';
import { type Variant } from '@/design_system/Button/Button';
import Menu, { MenuItem } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import IconChevron from '@/icons/Chevron.svg';
import IconPrint from '@/icons/Print.svg';
import IconQrCode from '@/icons/QrCode.svg';
import IconReceipt from '@/icons/Receipt.svg';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';

export const usePrintActions = ({
  request,
  article,
}: {
  request: RequestWithRelations;
  article?: ArticleWithRelations;
}) => {
  return {
    printItemSheet: () => {
      if (!article) {
        return;
      }
      window.open(`${config.apiUrl}/articles/${article.id}/recap`, '_blank');
    },
    printItemsSheets: () => {
      window.open(`${config.apiUrl}/requests/${request.id}/recap`, '_blank');
    },
    printDropOffReceipt: () => {
      window.open(`${config.apiUrl}/requests/${request.id}/deposit-slip`, '_blank');
    },
  };
};

export const PrintsButton = ({ request }: { request: RequestWithRelations }) => {
  const { _ } = useLingui();
  const { printItemsSheets, printDropOffReceipt } = usePrintActions({ request });
  const showPrintItemSheet = request.articles.every((article) => !!article.serviceChosenAt);
  const showPrintDropoffReceipt = !!(request.client && request.store);
  const showPrintsButton = showPrintItemSheet || showPrintDropoffReceipt;

  return showPrintsButton ? (
    <Menu
      onAction={(id) => {
        if (id === 'print-items-sheets') {
          printItemsSheets();
        } else if (id === 'print-dropoff-receipt') {
          printDropOffReceipt();
        }
      }}
      placement="bottom right"
      trigger={
        <Button
          variant="secondary"
          size="large"
          iconOnly
          style={{ width: 'fit-content', gap: 0 }}
          tooltip={_(msg({ id: 'requests.edit.actions.print', message: 'Print...' }))}
        >
          <IconPrint />
          <IconChevron down />
        </Button>
      }
    >
      {showPrintItemSheet && <PrintItemSheetMenuItem size="large" />}
      {showPrintDropoffReceipt && <PrintDropoffReceiptMenuItem size="large" />}
    </Menu>
  ) : null;
};

export const PrintItemSheetButton = ({
  request,
  article,
  size,
  variant,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  size?: 'small' | 'medium' | 'large';
  variant?: Variant;
}) => {
  const { _ } = useLingui();
  const { printItemSheet } = usePrintActions({ request, article });

  const label = _(
    msg({ id: 'requests.edit.actions.print-item-sheet', message: 'Print item sheet' })
  );

  return (
    <div style={{ position: 'relative' }}>
      <Button
        variant={variant}
        size={size ?? 'large'}
        iconOnly
        onPress={() => printItemSheet()}
        ariaLabel={label}
        tooltip={label}
      >
        <IconQrCode />
      </Button>
    </div>
  );
};

export const PrintItemSheetMenuItem = ({
  size = 'default',
  singleItem = false,
}: {
  size?: 'default' | 'large';
  singleItem?: boolean;
}) => (
  <MenuItem id={singleItem ? 'print-item-sheet' : 'print-items-sheets'} size={size}>
    <Stack row alignItems="center" gap="0.5rem">
      <IconQrCode style={{ fontSize: '1.25rem' }} />
      {singleItem ? (
        <Trans id="requests.edit.actions.print-item-sheet">Print item sheet</Trans>
      ) : (
        <Trans id="requests.edit.actions.print-items-sheets">Print items sheets</Trans>
      )}
    </Stack>
  </MenuItem>
);

export const PrintDropoffReceiptMenuItem = ({
  size = 'default',
}: {
  size?: 'default' | 'large';
}) => (
  <MenuItem id="print-dropoff-receipt" size={size}>
    <Stack row alignItems="center" gap="0.5rem">
      <IconReceipt style={{ fontSize: '1.25rem' }} />
      <Trans id="requests.edit.actions.print-drop-off-receipt">Print drop-off receipt</Trans>
    </Stack>
  </MenuItem>
);
