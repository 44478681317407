import { parsePhoneNumberWithError } from 'libphonenumber-js';

interface Phone {
  countryCode: string;
  number: string;
}

export const formatPhone = (phone: Phone | null) => {
  if (!phone || !phone.number || !phone.countryCode) {
    return null;
  }

  try {
    return parsePhoneNumberWithError(`+${phone.countryCode}${phone.number}`)?.formatInternational();
  } catch {
    return null;
  }
};

export default Phone;
