import { useState } from 'react';
import { msg, Plural, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CustomDefectDialog } from '@/components/CustomDefectDialog';
import { DefectPhotos } from '@/components/DefectPhotos';
import { DefectTypeSearchDialog } from '@/components/DefectTypeSearchDialog';
import { DefectTypeSearchSelect } from '@/components/DefectTypeSearchSelect';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button/Button';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import {
  Body,
  Cell,
  Column,
  Footer,
  Header,
  Row,
  Table,
  TextAreaCell,
  ToggleCell,
} from '@/design_system/Table/Table';
import Toggle from '@/design_system/Toggle';
import IconAdd from '@/icons/Add.svg';
import IconBandage from '@/icons/Bandage.svg';
import IconDone from '@/icons/Done.svg';
import IconEdit from '@/icons/Edit.svg';
import IconTrash from '@/icons/Trash.svg';
import { useDeleteDefect, useUpdateDefect } from '@/models/article';
import { computeDuplicateDefectNumber, useDefectName } from '@/models/defectType';
import { ArticleDefectWithRelations } from '@/models/request';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import useViewPort from '@/utils/useViewport';

export const DefectsTable = () => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const [isOpenCustomDefectDialog, setIsOpenCustomDefectDialog] = useState(false);
  const [customDefectToEdit, setCustomDefectToEdit] = useState<ArticleDefectWithRelations>();
  const [showAddDefectOptions, setShowAddDefectOptions] = useState(false);

  const { article, view } = useArticleContext();

  const defects = article.snapshot.articleDefects;

  const handleCloseCustomDefectDialog = () => {
    setIsOpenCustomDefectDialog(false);
    setShowAddDefectOptions(false);
    setCustomDefectToEdit(undefined);
  };

  const handleStartEditCustomDefect = (customDefect: ArticleDefectWithRelations) => {
    setIsOpenCustomDefectDialog(true);
    setCustomDefectToEdit(customDefect);
  };

  return (
    <Stack gap="0.25rem">
      <p className="label-100 text-primary">
        {view.services.defects.editable ? (
          <Trans id="defects-table.title.label">What defects have been noted?</Trans>
        ) : (
          <Trans id="defects-table.title.label-readonly">Noted defects</Trans>
        )}
      </p>
      {isMobile ? (
        <Stack gap="0.5rem">
          {defects.length === 0 ? (
            <span className="paragraph-100-regular text-secondary">
              <Trans id="defects-table.column.header.no-defect-added">No defects added yet</Trans>
            </span>
          ) : (
            defects.map((defect) => (
              <DefectCard
                key={defect.id}
                defect={defect}
                duplicateDefectNumber={computeDuplicateDefectNumber(defect, defects)}
                onEdit={() => handleStartEditCustomDefect(defect)}
              />
            ))
          )}

          {view.services.defects.editable && (
            <>
              <CustomDefectDialog
                isOpen={isOpenCustomDefectDialog}
                onClose={handleCloseCustomDefectDialog}
                initialCustomDefect={customDefectToEdit}
              />

              <DefectTypeSearchDialog
                isOpen={showAddDefectOptions}
                setIsOpen={setShowAddDefectOptions}
                setIsOpenCustomDefectDialog={setIsOpenCustomDefectDialog}
              />

              <AddDefectButton setShowAddDefectOptions={setShowAddDefectOptions} />
            </>
          )}
        </Stack>
      ) : (
        <Table
          ariaLabel={_(
            msg({
              id: 'defects-table.title.label',
              message: 'What defects have been noted?',
            })
          )}
          bordered
          variant="grid"
          columnWidths={[
            'minmax(250px, 1fr)',
            'minmax(250px, 1fr)',
            '120px',
            view.services.defects.editable && '56px',
          ]}
        >
          <Header>
            <Row>
              {defects.length === 0 ? (
                <Cell isWholeRow>
                  <span className="paragraph-200-regular">
                    <Trans id="defects-table.column.header.no-defect-added">
                      No defects added yet
                    </Trans>
                  </span>
                </Cell>
              ) : (
                <>
                  <Column>
                    <Trans id="defects-table.column.header.defect">Defect</Trans>
                  </Column>
                  <Column>
                    <Trans id="defects-table.column.header.defect-photos">Defect photos</Trans>
                  </Column>
                  <Column justifyContent="center">
                    <Trans id="defects-table.column.header.to-be-repaird">To be repaired?</Trans>
                  </Column>
                  {view.services.defects.editable && <Column justifyContent="flex-end" />}
                </>
              )}
            </Row>
          </Header>
          <Body>
            {defects.map((defect) => (
              <DefectRow
                key={defect.id}
                defect={defect}
                duplicateDefectNumber={computeDuplicateDefectNumber(defect, defects)}
              />
            ))}
          </Body>
          {view.services.defects.editable && (
            <Footer>
              <Row>
                <Cell isWholeRow>
                  {!showAddDefectOptions ? (
                    <AddDefectButton setShowAddDefectOptions={setShowAddDefectOptions} />
                  ) : (
                    <DefectTypeSearchSelect onBlur={() => setShowAddDefectOptions(false)} />
                  )}
                </Cell>
              </Row>
            </Footer>
          )}
        </Table>
      )}

      <DefectsTableErrors />
    </Stack>
  );
};

const DefectRow = ({
  defect,
  duplicateDefectNumber,
}: {
  defect: ArticleDefectWithRelations;
  duplicateDefectNumber?: number;
}) => {
  const { view } = useArticleContext();

  return (
    <Row>
      <DefectNameCell defect={defect} duplicateDefectNumber={duplicateDefectNumber} />
      <DefectPhotosCell defect={defect} />
      <DefectToBeRepairedCell defect={defect} />
      {view.services.defects.editable && <DeleteDefectCell defect={defect} />}
    </Row>
  );
};

const DefectCard = ({
  defect,
  duplicateDefectNumber,
  onEdit,
}: {
  defect: ArticleDefectWithRelations;
  duplicateDefectNumber?: number;
  onEdit: () => void;
}) => {
  const { view: articleView, errors } = useArticleContext();
  const defectName = useDefectName(defect);

  const hasError = !!errors.services?.defects?.defects?.find((def) => defect.id === def.id)
    ?.hasError;

  return (
    <Box
      padding="0"
      ariaLabel={defectName}
      style={
        hasError
          ? {
              // eslint-disable-next-line lingui/no-unlocalized-strings
              border: '1px solid var(--color-danger-700)',
            }
          : undefined
      }
    >
      <Stack padding="16px" gap="0.75rem" className="bg-neutral-0" style={{ borderRadius: '8px' }}>
        <CardDefectName defect={defect} duplicateDefectNumber={duplicateDefectNumber} />
        <hr />
        <Stack gap="0.5rem">
          <CardDefectPhotos defect={defect} />
          <CardDefectToBeRepaired defect={defect} />
        </Stack>

        {articleView.services.defects.editable && (
          <Stack gap="0.5rem">
            {defect.isCustom && <EditDefectButton onPress={onEdit} />}
            {<DeleteDefectButton defect={defect} />}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

const AddDefectButton = ({
  setShowAddDefectOptions,
}: {
  setShowAddDefectOptions: (show: boolean) => void;
}) => {
  return (
    <Button
      size="medium"
      variant="secondary"
      // eslint-disable-next-line lingui/no-unlocalized-strings
      style={{ flex: '0 auto' }}
      onPress={() => setShowAddDefectOptions(true)}
    >
      <IconAdd />
      <Trans id="defects-table.column.footer.add-defect">Add a defect</Trans>
    </Button>
  );
};

const DefectNameCell = ({
  defect,
  duplicateDefectNumber,
}: {
  defect: ArticleDefectWithRelations;
  duplicateDefectNumber?: number;
}) => {
  const { _ } = useLingui();
  const { request, article, view, errors } = useArticleContext();

  const { mutateAsync: updateDefect } = useUpdateDefect({
    requestId: request.id,
    articleId: article.id,
  });

  const [customDescription, setCustomDescription] = useState(defect.customDescription ?? '');
  const trimmedCustomDescription = customDescription.trim();

  return (
    <TextAreaCell
      cellProps={{
        isReadonly: !defect.isCustom || !view.services.defects.editable,
        isInvalid: errors.services?.defects?.defects?.find((def) => def.id === defect.id)
          ?.missingDescription,
      }}
      ariaLabel={_(
        msg({
          id: 'defects-table.column.description.custom-defect',
          message: 'Description of custom defect',
        })
      )}
      onChange={(event) => setCustomDescription(event.target.value)}
      value={customDescription}
      onBlur={() => {
        const currentDescription = defect.customDescription;
        updateDefect({
          defectId: defect.id,
          body: {
            description: trimmedCustomDescription,
          },
        }).catch(() => setCustomDescription(currentDescription ?? ''));
      }}
    >
      <DefectName defect={defect} duplicateDefectNumber={duplicateDefectNumber} />
    </TextAreaCell>
  );
};

const CardDefectName = ({
  defect,
  duplicateDefectNumber,
}: {
  defect: ArticleDefectWithRelations;
  duplicateDefectNumber?: number;
}) => {
  return (
    <Stack row gap="0.5rem" flexWrap="nowrap">
      <IconBandage style={{ fontSize: '1.5rem' }} />
      <DefectName defect={defect} duplicateDefectNumber={duplicateDefectNumber} />
    </Stack>
  );
};

const DefectName = ({
  defect,
  duplicateDefectNumber,
}: {
  defect: ArticleDefectWithRelations;
  duplicateDefectNumber?: number;
}) => {
  const { isMobile } = useViewPort();
  const { errors } = useArticleContext();
  const defectName = useDefectName(defect);

  const isMissingDescription = !!errors.services?.defects?.defects?.find(
    (def) => defect.id === def.id
  )?.missingDescription;

  const trimmedCustomDescription = (defect.customDescription || '').trim();

  const nbAssociatedActions = defect.articleActions.length;

  return defect.isCustom && !trimmedCustomDescription ? (
    isMobile ? (
      <p
        className="paragraph-100-regular"
        style={isMissingDescription ? { color: 'var(--color-danger-700)' } : undefined}
      >
        -
      </p>
    ) : (
      <span className="text-disabled">
        <Trans id="defects-table.column.defect.custom-placeholder">Write a description...</Trans>
      </span>
    )
  ) : (
    <Stack className="paragraph-100-regular">
      <div>
        {defect.isCustom ? trimmedCustomDescription : defectName}{' '}
        {!!duplicateDefectNumber && `#${duplicateDefectNumber}`}
      </div>

      {nbAssociatedActions > 0 && (
        <Stack row gap="0.25rem" alignItems="center" className="text-success">
          <IconDone />
          <span>
            <Trans id="defects-table.column.defect.actions-associated">
              {nbAssociatedActions}{' '}
              <Plural
                value={nbAssociatedActions}
                one="action associated"
                other="actions associated"
              />
            </Trans>
          </span>
        </Stack>
      )}
    </Stack>
  );
};

const DefectPhotosCell = ({ defect }: { defect: ArticleDefectWithRelations }) => {
  const { view, errors } = useArticleContext();

  return (
    <Cell stretch>
      <DefectPhotos
        defect={defect}
        size="small"
        isDisabled={!view.services.defects.editable}
        isInvalid={
          errors.services?.defects?.defects?.find((def) => def.id === defect.id)?.missingPhotos
        }
      />
    </Cell>
  );
};

const CardDefectPhotos = ({ defect }: { defect: ArticleDefectWithRelations }) => {
  const { view: articleView, errors } = useArticleContext();

  return (
    <DefectPhotos
      defect={defect}
      size="medium"
      isDisabled={!articleView.services.defects.editable}
      isInvalid={
        errors.services?.defects?.defects?.find((def) => def.id === defect.id)?.missingPhotos
      }
    />
  );
};

const DefectToBeRepairedCell = ({ defect }: { defect: ArticleDefectWithRelations }) => {
  const { article, request, view, errors } = useArticleContext();

  const { mutate: updateDefect } = useUpdateDefect({
    requestId: request.id,
    articleId: article.id,
  });

  if (!view.services.defects.editable) {
    return (
      <Cell justifyContent="center">
        {defect.toBeRepaired ? (
          <Trans id="defects-table.column.to-be-repaired.yes">Yes</Trans>
        ) : (
          <Trans id="defects-table.column.to-be-repaired.no">No</Trans>
        )}
      </Cell>
    );
  }

  return (
    <ToggleCell
      isSelected={defect.toBeRepaired}
      onChange={(toBeRepaired) => {
        updateDefect({
          defectId: defect.id,
          body: {
            toBeRepaired,
          },
        });
      }}
      cellProps={{
        isInvalid: errors.services?.defects?.defects?.find((def) => def.id === defect.id)
          ?.missingActions,
      }}
    />
  );
};

const CardDefectToBeRepaired = ({ defect }: { defect: ArticleDefectWithRelations }) => {
  const { article, request, view: articleView } = useArticleContext();

  const { mutate: updateDefect } = useUpdateDefect({
    requestId: request.id,
    articleId: article.id,
  });

  return (
    <Stack row padding="0.5rem 0" justifyContent="space-between" className="paragraph-100-regular">
      <span>
        <Trans id="defects-table.column.to-be-repaired.label">To be repaired?</Trans>
      </span>
      {articleView.services.defects.editable ? (
        <Toggle
          size="large"
          isSelected={defect.toBeRepaired}
          onChange={(toBeRepaired) => {
            updateDefect({
              defectId: defect.id,
              body: {
                toBeRepaired,
              },
            });
          }}
        />
      ) : (
        <Stack>
          {defect.toBeRepaired ? (
            <Trans id="defects-table.column.to-be-repaired.yes">Yes</Trans>
          ) : (
            <Trans id="defects-table.column.to-be-repaired.no">No</Trans>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const EditDefectButton = ({ onPress }: { onPress: () => void }) => {
  const { _ } = useLingui();

  return (
    <Button
      variant="secondary"
      size="medium"
      ariaLabel={_(msg({ id: 'defects-table.column.edit', message: 'Edit' }))}
      onPress={onPress}
    >
      <IconEdit />
      <Trans id="defects-table.column.edit">Edit</Trans>
    </Button>
  );
};

const DeleteDefectCell = ({ defect }: { defect: ArticleDefectWithRelations }) => {
  return (
    <Cell>
      <DeleteDefectButton defect={defect} iconOnly />
    </Cell>
  );
};

const DeleteDefectButton = ({
  defect,
  iconOnly,
}: {
  defect: ArticleDefectWithRelations;
  iconOnly?: boolean;
}) => {
  const { article, request } = useArticleContext();

  const { _ } = useLingui();

  const { mutateAsync: deleteDefect } = useDeleteDefect({
    requestId: request.id,
    articleId: article.id,
  });

  return (
    <Button
      variant="secondary"
      size="medium"
      iconOnly={iconOnly}
      tooltip={_(msg({ id: 'defects-table.column.deletion', message: 'Remove' }))}
      ariaLabel={_(msg({ id: 'defects-table.column.deletion', message: 'Remove' }))}
      onPress={() => {
        if (
          defect.media.length > 0 &&
          !confirm(
            _(
              msg({
                id: 'defects-table.column.deletion.confirmation',
                message:
                  'Are you sure you want to delete this defect? The associated photos will be deleted as well.',
              })
            )
          )
        ) {
          return;
        }

        deleteDefect(defect.id);
      }}
    >
      <IconTrash />
      {!iconOnly && <Trans id="defects-table.column.deletion">Remove</Trans>}
    </Button>
  );
};

const DefectsTableErrors = () => {
  const { errors } = useArticleContext();

  const tableErrors = [];

  if (errors.services?.defects?.noDefects) {
    tableErrors.push(
      <Trans id="defects-table.errors.no-defects">Please select at least one defect.</Trans>
    );
  }

  if (errors.services?.defects?.defects?.some((defect) => defect.missingDescription)) {
    tableErrors.push(
      <Trans id="defects-table.errors.missing-description">
        Please add a description for each defect.
      </Trans>
    );
  }

  if (errors.services?.defects?.defects?.some((defect) => defect.missingPhotos)) {
    tableErrors.push(
      <Trans id="defects-table.errors.missing-photos">
        Please add at least one photo per defect.
      </Trans>
    );
  }

  if (errors.services?.defects?.defects?.some((defect) => defect.missingActions)) {
    tableErrors.push(
      <Trans id="defects-table.errors.missing-actions">
        Please associate an action to each defect or mark them as not to be repaired.
      </Trans>
    );
  }

  if (tableErrors.length === 0) {
    return null;
  }

  return (
    <Stack gap="0.25rem" style={{ paddingTop: '0.5rem' }}>
      {tableErrors.map((error, index) => (
        <Message key={index} type="error">
          {error}
        </Message>
      ))}
    </Stack>
  );
};
