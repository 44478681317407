import { useContext } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ActionsTableContext } from '@/components/ActionsTable/ActionsTable';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { useActionTypes } from '@/models/actionType';
import { useCreateAction } from '@/models/article';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import useDebouncedState from '@/utils/useDebouncedState';

import { ActionDropdownItem, CustomActionDropdownItem } from './ActionDropdownItem';
import { PackActionDropdownItem } from './PackActionDropdownItem';

export const ActionTypeSearchSelect = ({ onBlur }: { onBlur: () => void }) => {
  const { _ } = useLingui();
  const { workflow } = useRequestContext();
  const { article, request, view: articleView } = useArticleContext();

  const { mode } = useContext(ActionsTableContext);

  const [query, debouncedQuery, setQuery] = useDebouncedState<string>('', 500);

  const allowCustomActions = !!workflow?.config.allowCustomActions;
  const actionTypeQueryMode = mode === 'need' ? 'needName' : 'both';

  const {
    data: { actionTypes, packActionTypes } = { actionTypes: [], packActionTypes: [] },
    isFetching,
  } = useActionTypes(
    {
      requestId: article.requestId,
      query: debouncedQuery || undefined,
      queryMode: debouncedQuery ? actionTypeQueryMode : undefined,
      productL1: article.productL1 ?? undefined,
      productL2: article.productL2 ?? undefined,
      productL3: article.productL3 ?? undefined,
    },
    {
      enabled: articleView.services.actions.editable,
      keepPreviousData: true,
    }
  );

  const { mutate: createAction } = useCreateAction({
    articleId: article.id,
    requestId: request.id,
  });

  const handleSelectionChange = (id: string) => {
    if (id) {
      const newAction = actionTypes?.find(
        (actionTypeOrganization) => actionTypeOrganization.id === id
      );
      const newPackAction = packActionTypes?.find(
        (packActionTypeOrganization) => packActionTypeOrganization.id === id
      );

      if (newAction) {
        createAction({
          actionTypeOrganization: newAction,
        });
      } else if (newPackAction) {
        createAction({
          packActionTypeOrganization: newPackAction,
        });
      } else {
        createAction({
          description: '',
        });
      }

      setQuery('');
      onBlur();
    }
  };

  const actionTypeOptions = [...packActionTypes, ...actionTypes];

  // isStickyOption lets know InputSelect that this option should stick to the bottom of the options list
  const customOption = { id: 'custom', isStickyOption: true } as const;

  const actionTypeAndCustomOptions = allowCustomActions
    ? [...actionTypeOptions, customOption]
    : actionTypeOptions;

  return (
    <InputSelect
      variant="add"
      aria-label={_(
        msg({ id: 'action-type-search-dialog.add-action.label', message: 'Add an action' })
      )}
      placeholder={_(
        mode === 'need'
          ? msg({
              id: 'article.form.actions.placeholder.defect',
              message: 'Search a need or defect...',
            })
          : msg({
              id: 'article.form.actions.placeholder',
              message: 'Search a care or repair action...',
            })
      )}
      isLoading={isFetching || query !== debouncedQuery}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      menuIsOpen
      value={null} // Allows to automatically clear the input when selecting a value
      inputValue={query}
      onInputChange={setQuery}
      filterOption={null} // Avoid react-select to filter options only based on their label
      onBlur={onBlur}
      options={actionTypeAndCustomOptions}
      getOptionValue={(actionType) =>
        !!actionType && 'id' in actionType ? actionType.id : 'custom'
      }
      formatOptionLabel={(actionType) => {
        if (actionType) {
          if ('isStickyOption' in actionType) {
            return <CustomActionDropdownItem key="custom" />;
          }
          if ('actions' in actionType) {
            return <PackActionDropdownItem key={actionType.id} packAction={actionType} />;
          }
          return <ActionDropdownItem key={actionType.id} action={actionType} />;
        }
      }}
      onChange={(actionType) => {
        if (actionType) {
          handleSelectionChange(actionType.id);
        }
      }}
    />
  );
};
