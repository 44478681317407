import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import useViewPort from '@/utils/useViewport';

export const CreateShipmentAction = () => {
  const { isMobile } = useViewPort();
  const navigate = useNavigate();

  const { article } = useArticleContext();

  return (
    <Stack
      row={!isMobile}
      alignItems={isMobile ? 'stretch' : 'center'}
      style={{ flex: 1 }}
      justifyContent="flex-end"
    >
      <Button
        variant="primary"
        size="medium"
        onPress={() =>
          navigate('/shipments/new', {
            state: { requestId: article.requestId, articleId: article.id },
          })
        }
      >
        <Trans id="article.actions.create-shipment">Create a shipment</Trans>
      </Button>
    </Stack>
  );
};
