import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { PriceAmountWithDetails } from '@/api';
import { usePriceDetailsRows } from '@/components/PriceWithDetails/usePriceDetailsRows';
import Stack from '@/design_system/Stack';
import Toggle from '@/design_system/Toggle';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { createBEMClasses } from '@/utils/classname';
import { formatCurrency } from '@/utils/number';

import './RequestOrganizationPrice.css';

const { block, element } = createBEMClasses('request-organization-price');

export const RequestOrganizationPrice = ({
  price,
  label,
}: {
  price: PriceAmountWithDetails | null | undefined;
  label?: string;
}) => {
  const { _ } = useLingui();
  const { view: requestView } = useRequestContext();
  const [showDetails, setShowDetails] = useState(false);

  const priceDetailsRows = usePriceDetailsRows(price, {
    showTaxDetails: showDetails,
    showAmountBeforeTaxes: requestView.price.showAmountBeforeTaxes,
  });

  if (!price) return null;

  return (
    <Stack
      ariaLabel={label}
      className={block({}, 'bg-neutral-300')}
      gap="0.5rem"
      padding="0.75rem 1rem"
      style={{ borderRadius: '0.5rem' }}
    >
      <Stack row gap="0.5rem" justifyContent="space-between">
        <p>{label && <span className="paragraph-100-medium text-primary">{label}</span>}</p>
        <Stack row gap="0.25rem" alignItems="center">
          <Toggle
            label={<Trans id="request.articles.price-details">Details</Trans>}
            size="small"
            isSelected={showDetails}
            onChange={() => setShowDetails(!showDetails)}
          />
        </Stack>
      </Stack>
      {priceDetailsRows.map((section, sectionIndex) => {
        const isTotalSection = sectionIndex === priceDetailsRows.length - 1;

        return (
          <Stack gap="0.125rem" key={sectionIndex}>
            {priceDetailsRows.length > 1 && isTotalSection && (
              <div className={element('separator')} />
            )}
            {section.map((row) => {
              return (
                <Stack
                  key={row.label.id}
                  ariaLabel={_(row.label)}
                  row
                  flexWrap="nowrap"
                  justifyContent="space-between"
                  className={element(
                    'row',
                    { negative: row.amount < 0 },
                    `text-secondary ${row.highlight ? (isTotalSection ? 'paragraph-100-regular' : 'paragraph-200-regular') : 'paragraph-400-regular'}`
                  )}
                >
                  <p>{_(row.label)}</p>
                  <p>{formatCurrency(row.amount, price.currency)}</p>
                </Stack>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
};
