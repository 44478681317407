import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { differenceInYears } from 'date-fns/differenceInYears';

import BasicRadioGroup, {
  yesNoRadioOptions,
} from '@/design_system/BasicRadioGroup/BasicRadioGroup';
import Box from '@/design_system/Box';
import InputDate from '@/design_system/InputDate';
import Stack from '@/design_system/Stack';
import { useUpdateArticle } from '@/models/article';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import ProofOfPurchase from '@/routes/Requests/Request/Article/ProofOfPurchase';
import useViewPort from '@/utils/useViewport';

const Warranty = () => {
  const { _ } = useLingui();

  const { isMobile } = useViewPort();

  const { request, article, view } = useArticleContext();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const [purchaseDate, setPurchaseDate] = useState(article.purchaseDate ?? undefined);

  const handleBlurInputDate = () => {
    if (purchaseDate && purchaseDate !== article.purchaseDate) {
      updateArticle({
        data: {
          purchaseDate,
        },
      });
    } else if (!purchaseDate) {
      setPurchaseDate(article.purchaseDate ?? undefined);
    }
  };

  const setWarranty = (warranty: boolean) => {
    updateArticle({
      data: {
        warranty,
      },
    });
  };

  if (!view.warranty.shown || !view.warranty.editable) {
    return null;
  }

  return (
    <Stack gap="0.5rem">
      <h2 className="headline-400-bold">
        <Trans id="requests.new.articles.drawer.warranty.title">Warranty</Trans>
      </h2>

      <Box
        padding={isMobile ? '1rem' : '1rem 1.5rem'}
        gap="1rem"
        style={{
          backgroundColor: 'var(--color-neutral-0)',
        }}
      >
        <Stack gap="1rem">
          <InputDate
            label={_(msg({ id: 'article.form.purchase-date.label', message: 'Purchase date' }))}
            value={purchaseDate}
            onChange={(date) => {
              setPurchaseDate(date);

              if (date) {
                setWarranty(differenceInYears(new Date(), new Date(date)) < 2);
              } else {
                setWarranty(false);
              }
            }}
            maxValue={new Date().toISOString()}
            style={{ flex: 1 }}
            onBlur={handleBlurInputDate}
          />
          <ProofOfPurchase />
          <BasicRadioGroup
            label={_(
              msg({
                id: 'article.form.warranty.label',
                message: 'Is the product still on warranty?',
              })
            )}
            value={article.warranty ? 'yes' : 'no'}
            onChange={(value) => setWarranty(value === 'yes')}
            options={yesNoRadioOptions.map((option) => ({
              ...option,
              children: _(option.children),
            }))}
            style={{ flex: 1 }}
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default Warranty;
