import { Trans } from '@lingui/macro';

import Stack from '@/design_system/Stack';
import { useActionTypes } from '@/models/actionType';
import { useDefectTypes } from '@/models/defectType';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import ColorSize from '@/routes/Requests/Request/Article/ColorSize';
import { Brand } from '@/routes/Requests/Request/Article/components/Brand';
import {
  ProductCategoryL1,
  ProductCategoryL2,
  ProductCategoryL3,
} from '@/routes/Requests/Request/Article/components/ProductCategories';
import ProductInfo from '@/routes/Requests/Request/Article/ProductInfo';
import { ProductPhoto } from '@/routes/Requests/Request/Article/ProductPhoto';

import { ArticlePhotoUpload } from './ArticlePhotoUpload';

export const ArticleDetails = () => {
  const { request, article } = useArticleContext();

  return (
    <>
      <Stack row mobileColumn gap="1rem">
        <ProductInfo />

        <div className="requests__new__articles__new__product-or paragraph-100-regular">
          <hr />
          <span>
            <Trans id="_general.or">or</Trans>
          </span>
          <hr />
        </div>

        <ProductCategoryL1 />
      </Stack>

      <ProductCategoryL2 />

      {!!request.organization.brands.length && <Brand />}

      <ProductCategoryL3 />

      <ColorSize request={request} article={article} />

      <Stack row gap="1rem">
        <div style={{ flex: '1 1 200px' }}>
          <ArticlePhotoUpload />
        </div>

        {!!article.product?.data.image && (
          <div style={{ flex: '1 1 200px' }}>
            <ProductPhoto
              productName={article.product.name}
              productImage={article.product.data.image}
            />
          </div>
        )}
      </Stack>
    </>
  );
};

export const useHasNoAvailableDefectsOrActions = () => {
  const { article, view } = useArticleContext();

  const defectsQueryEnabled =
    view.services.defects.shown &&
    article.snapshot.articleDefects.length === 0 &&
    !!article.productL1;

  const { data: defects } = useDefectTypes(
    {
      requestId: article.requestId,
      productL1: article.productL1 ?? undefined,
      productL2: article.productL2 ?? undefined,
      productL3: article.productL3 ?? undefined,
    },
    {
      enabled: defectsQueryEnabled,
      keepPreviousData: true,
    }
  );

  const hasNoAvailableDefects = defectsQueryEnabled && defects?.length === 0;

  const actionsQueryEnabled =
    view.services.actions.shown &&
    article.snapshot.articleActions.length === 0 &&
    !!article.productL1;

  const { data: actionTypesData } = useActionTypes(
    {
      requestId: article.requestId,
      productL1: article.productL1 ?? undefined,
      productL2: article.productL2 ?? undefined,
      productL3: article.productL3 ?? undefined,
    },
    {
      enabled: actionsQueryEnabled,
      keepPreviousData: true,
    }
  );

  const hasNoAvailableActions =
    actionsQueryEnabled &&
    actionTypesData?.actionTypes?.length === 0 &&
    actionTypesData?.packActionTypes?.length === 0;

  return {
    hasNoAvailableDefects,
    hasNoAvailableActions,
  };
};
