import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CardItem } from '@/components/Card/Card';
import { PriceWithDetailsTooltip } from '@/components/PriceWithDetails/PriceWithDetails';
import Badge from '@/design_system/Badge';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconNoConnection from '@/icons/NoConnection.svg';
import IconStoreRepair from '@/icons/StoreRepair.svg';
import { ArticleWithRelations } from '@/models/request';
import { formatCurrency } from '@/utils/number';

const ArticleWorkshopCell = ({
  article,
  showWorkshopPrice,
  showAmountBeforeTaxes,
}: {
  article: ArticleWithRelations;
  showWorkshopPrice?: boolean;
  showAmountBeforeTaxes: boolean;
}) => {
  const { _ } = useLingui();

  if (!article.workshop) {
    return <p className="paragraph-100-medium">-</p>;
  }

  const workshopCost = article.snapshot.cost?.amountPerEntity.find(
    (amount) => amount.entityId === article.workshopId
  );

  return (
    <Stack style={{ minWidth: 0 }}>
      <Stack row gap="0.5rem" alignItems="center" flexWrap="nowrap">
        <p className="paragraph-100-medium text-ellipsis" title={article.workshop.name}>
          {article.workshop.name}
        </p>
        {!!article.workshop.nonDigitalized && (
          <Tooltip
            content={_(
              msg({
                id: 'article-workshop-cell.non-digitalized.tooltip',
                message: 'Non-digitalized',
              })
            )}
            hasOverlayArrow
          >
            <Button variant="style-less">
              <Badge size="small" color="secondary" hasBorder borderRadius="soft" iconOnly>
                <IconNoConnection />
              </Badge>
            </Button>
          </Tooltip>
        )}
      </Stack>

      <Stack row className="paragraph-200-regular text-secondary" gap="0.25rem">
        <p className="text-ellipsis" title={article.workshop.address?.city}>
          {article.workshop.external && (
            <>
              <Trans id="workshop.external">External</Trans>
              {article.workshop.address?.city && ' • '}
            </>
          )}
          {article.workshop.address?.city}
          {showWorkshopPrice && !!workshopCost && <> • </>}
        </p>
        <p>
          {showWorkshopPrice && !!workshopCost && (
            <>
              {workshopCost.cancelled ? (
                <s className="text-disabled">
                  {formatCurrency(
                    showAmountBeforeTaxes ? workshopCost.amountBeforeTaxes : workshopCost.amount,
                    workshopCost.currency
                  )}
                </s>
              ) : (
                <PriceWithDetailsTooltip
                  price={workshopCost}
                  size="small"
                  className="paragraph-200-regular text-secondary"
                  showAmountBeforeTaxes={showAmountBeforeTaxes}
                />
              )}
            </>
          )}
        </p>
      </Stack>
    </Stack>
  );
};

export default ArticleWorkshopCell;

export const ArticleWorkshopCardItem = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <CardItem>
      <IconStoreRepair style={{ fontSize: '1rem' }} />
      <span>{article.workshop?.name ?? '-'}</span>
    </CardItem>
  );
};
