import config from '@/config';
import { Organization } from '@/models/organization';
import { useClientToken, useCurrentOrganization, useStoreToken } from '@/services/auth';
import { captureMessage } from '@/services/sentry';
import { NotFoundError } from '@/utils/error/NotFoundError';

type ContentType = 'application/json' | 'multipart/form-data';

export const useFetch = <
  T extends {
    response: any;
    body: any;
    query: any;
  } = any,
>() => {
  const [currentOrganization] = useCurrentOrganization();
  const clientToken = useClientToken();
  const storeToken = useStoreToken();

  return fetchApi<T['response'], T['body'], T['query']>(
    currentOrganization,
    clientToken,
    storeToken
  );
};

const fetchApi =
  <
    Response = any,
    Body = any,
    Params =
      | Record<string, string | string[] | ReadonlyArray<string> | number | boolean | undefined>
      | undefined,
  >(
    currentOrganization: Organization | null,
    clientToken?: string,
    storeToken?: string
  ) =>
  <ResponseOverride = Response>(
    path: string,
    params?: Params,
    options: Omit<RequestInit, 'body'> & { body?: Body } = {},
    contentType: ContentType = 'application/json'
  ) => {
    const searchParams = [];

    for (const [key, value] of Object.entries(params || {})) {
      if (Array.isArray(value)) {
        for (const item of value) {
          searchParams.push(`${key}[]=${item}`);
        }
      } else if (typeof value === 'boolean') {
        if (value === false) {
          searchParams.push(`${key}=0`);
        } else {
          searchParams.push(`${key}=1`);
        }
      } else if (value !== undefined) {
        searchParams.push(`${key}=${value?.toString()}`);
      }
    }

    let formattedBody: string | FormData | undefined;

    if (options?.body && contentType === 'application/json') {
      formattedBody = JSON.stringify(options.body);
      options.headers = {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        'Content-Type': contentType,
        ...options.headers,
      };
    } else if (options?.body && contentType === 'multipart/form-data') {
      const formData = new FormData();

      for (const key in options.body) {
        formData.append(key, options.body[key] as string | Blob);
      }

      formattedBody = formData;
    }

    if (currentOrganization) {
      options.headers = {
        'prolong-organization': currentOrganization.slug,
        ...options.headers,
      };
    }

    if (clientToken) {
      options.headers = {
        'client-token': clientToken,
        ...options.headers,
      };
    }

    if (storeToken) {
      options.headers = {
        'store-token': storeToken,
        ...options.headers,
      };
    }

    const pathAndParams = `${!path.startsWith('/') ? '/' : ''}${path}${searchParams.length ? `?${searchParams.join('&')}` : ''}`;
    const url = `${config.apiUrl}${pathAndParams}`;

    return window
      .fetch(url, {
        credentials: 'include',
        ...options,
        body: formattedBody,
      } as RequestInit)
      .then(async (res) => {
        const textBody = await res.text();
        let body;

        try {
          body = JSON.parse(textBody);
        } catch {
          body = textBody;
        }

        if (!res.ok) {
          // 403 and 401 are special cases; we don't want to log it as it's a normal "error" users can
          // have if they don't have the right permissions or password
          if (res.status !== 403 && res.status !== 401) {
            // eslint-disable-next-line lingui/no-unlocalized-strings
            captureMessage(`Error: ${options.method ?? 'GET'} ${pathAndParams}`, (context) => {
              context.setLevel('error');
              context.setExtra('request-body', options.body);
              context.setExtra('response-body', body);
              return context;
            });
          }
          if (res.status === 404) {
            // Throw a specific error because some components have a dedicated state/view for this
            throw new NotFoundError(`${body?.message ?? body}`);
          }
          throw new Error(`${body?.message ?? body}`);
        }

        return body as ResponseOverride;
      });
  };

export type PaginatedResults<T extends string> = {
  meta: {
    limit: number;
    offset: number;
    count: number;
  };
} & Record<T, any[]>;
