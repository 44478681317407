import { type MessageDescriptor } from '@lingui/core';
import { msg, t } from '@lingui/macro';
import type { DefaultError, QueryKey } from '@tanstack/query-core';
import {
  InfiniteData,
  Query,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { max } from 'date-fns';

import { Endpoints, PriceAggregate } from '@/api';
import { Comment, CommentVisibility } from '@/models/comment/comment';
import { useFetch } from '@/utils/fetch';
import { Currency } from '@/utils/number';

import {
  ActionType,
  ActionTypeOrganization,
  ArticleAction,
  PackActionTypeOrganization,
  PackActionTypeOrganizationAction,
} from './actionType';
import { Address } from './address';
import { Article, ArticleSnapshot } from './article';
import { Client } from './client';
import { ArticleDefect, DefectType, DefectTypeOrganization } from './defectType';
import { Medium } from './medium';
import { Model } from './model';
import { Organization } from './organization';
import { Product } from './product';
import { Store } from './store';
import { TransactionStatus } from './transaction';
import { User, UserWithRelations } from './user';
import { Workshop } from './workshop';

export class Request extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  reference!: string;
  data!: Record<string, any>;

  workflowId!: string | null;

  vip!: boolean;
  priority!: boolean;

  externalPaymentReference?: string | null;

  draft!: boolean;

  source!: string | null;

  feedback!: Feedback | null;

  storeId!: string | null;
  storeUserId!: string | null;
  organizationId!: string;
  creatorId!: string | null;
  hasSubscribed?: boolean;

  defaultPriceCurrency?: Currency;

  createdAt!: string;

  dueAtType!: string | null;
  dueAt!: string | null;
  statusDueAt!: string | null;

  price?: PriceAggregate | null;
  cost?: PriceAggregate | null;
  previousPrice?: PriceAggregate | null;
  previousCost?: PriceAggregate | null;

  get createdAtDate() {
    return new Date(this.createdAt);
  }

  get dueAtDate() {
    return this.dueAt ? new Date(this.dueAt) : null;
  }

  get statusDueAtDate() {
    return this.statusDueAt ? new Date(this.statusDueAt) : null;
  }

  get estimatedDueAtDate() {
    if ('articles' in this) {
      const articleEstimatedDueAtDates = (this.articles as ArticleWithRelations[])
        .filter((article) => !!article.estimatedDueAtDate)
        .map((article) => article.estimatedDueAtDate!);

      if (articleEstimatedDueAtDates.length) {
        return max(articleEstimatedDueAtDates);
      }
    }

    return null;
  }

  get archivedAtDate() {
    if ('allArticles' in this) {
      const articlesArchiveDates = (this.allArticles as ArticleWithRelations[])
        .filter((article) => article.archivedAtDate)
        .map((article) => article.archivedAtDate as Date);

      // Find the article with the latest archivedAtDate
      return articlesArchiveDates.length === 0
        ? null
        : articlesArchiveDates.sort((a, b) => b.getTime() - a.getTime())[0];
    }
  }

  get name() {
    if (!('client' in this) || !('store' in this)) {
      throw new Error('Cannot get name of request without client or store');
    }

    const client = (this as unknown as RequestWithRelations).client;
    const store = (this as unknown as RequestWithRelations).store;

    if (client && store) {
      return {
        major: client.name,
        minor: store.name,
      };
    }

    if (client) {
      return {
        major: client.name,
        minor: null,
      };
    }

    if (store) {
      return {
        major: store.name,
        minor: null,
      };
    }

    return {
      major: t({ id: 'request.requestor.unknown', message: 'Unknown requester' }),
      minor: null,
    };
  }

  get requestorType(): RequestorType {
    if (!('client' in this)) {
      throw new Error('Cannot get requestorType of request without client');
    }

    const client = (this as unknown as RequestWithRelations).client;

    return client && this.storeId ? 'client-via-store' : client ? 'client' : 'store';
  }

  get isInDraftStep() {
    if ('articles' in this) {
      return (
        this.hasActiveArticles &&
        (this.articles as ArticleWithRelations[]).every(
          (article) => article.step?.step === 'creation'
        )
      );
    }
  }

  get isInFirstServiceChoiceStep() {
    if ('articles' in this) {
      return (
        this.hasActiveArticles &&
        (this.articles as ArticleWithRelations[]).some(
          (article) =>
            article.step?.step === 'service-choice' &&
            (article.steps ?? []).filter((step) => step.step === 'service-choice').length === 1
        )
      );
    }
  }

  get isInLaterServiceChoiceStep() {
    if ('articles' in this) {
      return (
        this.hasActiveArticles &&
        (this.articles as ArticleWithRelations[]).some(
          (article) =>
            article.step?.step === 'service-choice' &&
            (article.steps ?? []).filter((step) => step.step === 'service-choice').length > 1
        )
      );
    }
  }

  get isInValidationStep() {
    if ('articles' in this) {
      return (
        this.hasActiveArticles &&
        (this.articles as ArticleWithRelations[]).some(
          (article) => article.step?.step === 'validation' && !article.quoteRefusedAt
        )
      );
    }
  }

  get isInTransitStep() {
    if ('articles' in this) {
      return (
        this.hasActiveArticles &&
        (this.articles as ArticleWithRelations[]).every(
          (article) => article.step?.step === 'transit'
        )
      );
    }
  }

  get isInAnalysisStep() {
    if ('articles' in this) {
      return (
        this.hasActiveArticles &&
        (this.articles as ArticleWithRelations[]).some(
          (article) => article.step?.step === 'analysis'
        )
      );
    }
  }

  get isInPaymentStep() {
    if ('articles' in this) {
      return (
        this.hasActiveArticles &&
        (this.articles as ArticleWithRelations[]).some(
          (article) => article.step?.step === 'payment'
        )
      );
    }
  }

  get isInRepairStep() {
    if ('articles' in this) {
      return (
        this.hasActiveArticles &&
        (this.articles as ArticleWithRelations[]).some((article) => article.step?.step === 'repair')
      );
    }
  }

  /**
   * @deprecated Use isInTransitStep instead
   */
  get isInLegacyReceivedDeliveryStep() {
    if ('articles' in this) {
      return (
        !this.workflowId &&
        this.hasActiveArticles &&
        (this.articles as ArticleWithRelations[]).every(
          (article) => !!article.toStoreId && article.inTransit && article.inTransitVerification
        )
      );
    }
  }

  /**
   * @deprecated Use isInTransitStep instead
   */
  get isInLegacyPendingClientDeliveryStep() {
    if ('articles' in this) {
      return (
        !this.workflowId &&
        this.hasActiveArticles &&
        (this.articles as ArticleWithRelations[]).every(
          (article) => !!article.atStoreId && article.toClient
        )
      );
    }
  }

  get isInCompletedStep() {
    if ('allArticles' in this) {
      return (this.allArticles as ArticleWithRelations[]).every((article) =>
        article.step
          ? article.step.step === 'archival'
          : article.archived && article.archivalDetail?.type === 'completed'
      );
    }
  }

  get isArchived() {
    if ('allArticles' in this) {
      return (this.allArticles as ArticleWithRelations[]).every((article) => article.archived);
    }
  }

  get isManuallyArchived() {
    if ('allArticles' in this) {
      return (this.allArticles as ArticleWithRelations[]).every(
        (article) => article.archivalDetail?.type === 'manual'
      );
    }
  }

  get isAutomaticallyArchived() {
    if ('allArticles' in this) {
      return (this.allArticles as ArticleWithRelations[]).every(
        (article) => article.archivalDetail?.type === 'automatic'
      );
    }
  }

  get isArchivedAndExportedToZendesk() {
    if ('allArticles' in this) {
      return (
        (this.isManuallyArchived || this.isAutomaticallyArchived) &&
        (this.allArticles as ArticleWithRelations[]).every(
          (article) => article.archivalDetail?.reason === 'export-to-zendesk'
        )
      );
    }
  }

  get isCancelled() {
    if ('allArticles' in this) {
      return (this.allArticles as ArticleWithRelations[]).every((article) => article.cancelled);
    }
  }

  get isInReceivedStepWithIssue() {
    if ('articles' in this) {
      return (this.articles as ArticleWithRelations[]).some(
        (article) => article.step?.step === 'transit' && article.hasIssue
      );
    }
  }

  get hasActiveArticles() {
    if ('articles' in this) {
      return (this.articles as ArticleWithRelations[]).length > 0;
    }
  }

  get actionQuantity() {
    if ('articles' in this) {
      return (this.articles as ArticleWithRelations[]).reduce(
        (requestQuantity, article) => requestQuantity + article.numberOfActions,
        0
      );
    }
  }

  get defectQuantity() {
    if ('articles' in this) {
      return (this.articles as ArticleWithRelations[]).reduce(
        (requestQuantity, article) => requestQuantity + article.numberOfDefects,
        0
      );
    }
  }
}

export const REQUESTOR_TYPES = [
  {
    id: 'client',
    label: msg({ id: 'request.requestor.type.client', message: 'Online client' }),
  },
  {
    id: 'store',
    label: msg({ id: 'request.requestor.type.store', message: 'Store' }),
  },
  {
    id: 'client-via-store',
    label: msg({ id: 'request.requestor.type.client-via-store', message: 'In-store client' }),
  },
] as const;

export type RequestorType = (typeof REQUESTOR_TYPES)[number]['id'];

export const getRequestorTypeLabel = (
  requestRequestorType: 'client' | 'store' | 'client-via-store'
): MessageDescriptor => {
  return REQUESTOR_TYPES.find((requestType) => requestType.id === requestRequestorType)!.label;
};

export type FeedbackRating = 1 | 2 | 3 | 4 | 5;
export type Feedback = {
  global?: FeedbackRating;
  articlesFeedback?: { articleId: string; quality: FeedbackRating }[];
  quality?: FeedbackRating;
  speed?: FeedbackRating;
  communication?: FeedbackRating;
  comment?: string;
  acceptContact?: boolean;
};

export const instanciateArticleDefect = (
  defect: Endpoints['GET /requests/:id']['response']['articles'][number]['snapshot']['articleDefects'][number],
  media: Medium[]
) =>
  new ArticleDefect(defect)
    .with(
      'defectTypeOrganization',
      defect.defectTypeOrganization
        ? new DefectTypeOrganization(defect.defectTypeOrganization).with(
            'defectType',
            new DefectType(defect.defectTypeOrganization.defectType)
          )
        : null
    )
    .with('media', media);

export const instanciateArticleAction = (
  action: Endpoints['GET /requests/:id']['response']['articles'][number]['snapshot']['articleActions'][number] & {
    price?: PriceAggregate;
    cost?: PriceAggregate;
  },
  media: Medium[]
) =>
  new ArticleAction(action)
    .with(
      'packActionTypeOrganization',
      action.packActionTypeOrganization
        ? new PackActionTypeOrganization(action.packActionTypeOrganization).with(
            'actions',
            action.packActionTypeOrganization.actions.map((subAction) =>
              new PackActionTypeOrganizationAction(subAction).with(
                'actionType',
                new ActionType(subAction.actionType)
              )
            )
          )
        : null
    )
    .with(
      'actionTypeOrganization',
      action.actionTypeOrganization
        ? new ActionTypeOrganization(action.actionTypeOrganization).with(
            'actionType',
            new ActionType(action.actionTypeOrganization.actionType)
          )
        : null
    )
    .with('media', media);

const instanciateArticleSnapshotWithRelations = (
  snapshot: Endpoints['GET /requests/:id']['response']['articles'][number]['snapshot'] & {
    price: PriceAggregate | undefined;
    cost: PriceAggregate | undefined;
  },
  articleMedia: Medium[]
) => {
  return new ArticleSnapshot(snapshot)
    .with(
      'articleDefects',
      snapshot.articleDefects.map((defect) =>
        instanciateArticleDefect(
          defect,
          articleMedia.filter((medium) => medium.articleDefects?.find(({ id }) => id === defect.id))
        )
      )
    )
    .with(
      'articleActions',
      snapshot.articleActions.map((action) =>
        instanciateArticleAction(
          {
            ...action,
            price: getPriceComponent(snapshot.price, action.id),
            cost: getPriceComponent(snapshot.cost, action.id),
          },
          articleMedia.filter((medium) => medium.articleActions?.find(({ id }) => id === action.id))
        )
      )
    );
};

const instanciateArticleWithRelations = (
  article: Endpoints['GET /requests/:id']['response']['articles'][number],
  request: Endpoints['GET /requests/:id']['response']
) => {
  const media = article.media.map((medium) => new Medium(medium));

  return new Article(article)
    .with('media', media)
    .with('product', article.product === null ? null : new Product(article.product))
    .with('workshop', article.workshop === null ? null : new Workshop(article.workshop))
    .with('atWorkshop', article.atWorkshop === null ? null : new Workshop(article.atWorkshop))
    .with(
      'snapshot',
      instanciateArticleSnapshotWithRelations(
        {
          ...article.snapshot,

          price: getPriceComponent(request.price, article.id),
          cost: getPriceComponent(request.cost, article.id),
        },
        media
      )
    )
    .with(
      'previousSnapshot',
      article.previousSnapshot
        ? instanciateArticleSnapshotWithRelations(
            {
              ...article.previousSnapshot,

              price: getPriceComponent(request.previousPrice, article.id),
              cost: getPriceComponent(request.previousCost, article.id),
            },
            media
          )
        : undefined
    );
};

export const instanciateRequestWithRelations = (
  request: Endpoints['GET /requests/:id']['response']
) => {
  const instanciatedRequest = new Request(request)
    .with(
      'articles',
      request.articles.map((article) => instanciateArticleWithRelations(article, request))
    )
    .with(
      'archivedArticles',
      request.archivedArticles.map((article) => instanciateArticleWithRelations(article, request))
    )
    .with(
      'collaborators',
      (request.collaborators ?? []).map((collaborator) => new User(collaborator))
    )
    .with('supervisor', request.supervisor ? new User(request.supervisor) : null)
    .with(
      'client',
      request.client
        ? new Client(request.client)
            .with(
              'address',
              request.client.address ? new Address(request.client.address) : undefined
            )
            .with(
              'billingAddress',
              request.client.billingAddress ? new Address(request.client.billingAddress) : undefined
            )
        : null
    )
    .with(
      'store',
      request.store
        ? new Store(request.store).with(
            'address',
            request.store.address ? new Address(request.store.address) : undefined
          )
        : null
    )
    .with('organization', new Organization(request.organization));

  return instanciatedRequest.with('allArticles', [
    ...instanciatedRequest.articles,
    ...instanciatedRequest.archivedArticles,
  ]);
};

const instanciateClientArticleWithRelations = (
  article: Endpoints['GET /requests/:id']['response']['articles'][number],
  request: Endpoints['GET /requests/:id']['response']
) => {
  const media = article.media?.map((medium) => new Medium(medium));

  return new Article(article)
    .with('product', article.product === null ? null : new Product(article.product))
    .with(
      'snapshot',
      instanciateArticleSnapshotWithRelations(
        {
          ...article.snapshot,

          price: getPriceComponent(request.price, article.id),
          cost: getPriceComponent(request.cost, article.id),
        },
        media
      )
    )
    .with('media', media);
};

export const instanciateClientRequestWithRelations = (
  request: Endpoints['GET /requests/:id']['response']
) => {
  const instanciatedRequest = new Request(request)
    .with(
      'articles',
      request.articles.map((article) => instanciateClientArticleWithRelations(article, request))
    )
    .with(
      'archivedArticles',
      request.archivedArticles.map((article) =>
        instanciateClientArticleWithRelations(article, request)
      )
    )
    .with(
      'client',
      request.client
        ? new Client(request.client)
            .with(
              'address',
              request.client.address ? new Address(request.client.address) : undefined
            )
            .with(
              'billingAddress',
              request.client.billingAddress ? new Address(request.client.billingAddress) : undefined
            )
        : null
    )
    .with(
      'store',
      request.store
        ? new Store(request.store).with(
            'address',
            request.store.address ? new Address(request.store.address) : undefined
          )
        : null
    )
    .with('organization', new Organization(request.organization));

  return instanciatedRequest.with('allArticles', [
    ...instanciatedRequest.articles,
    ...instanciatedRequest.archivedArticles,
  ]);
};

const getPriceComponent = (price: PriceAggregate | null | undefined, id: string) =>
  price?.components.find((component) => component.componentId === id);

export type RequestWithRelations = ReturnType<typeof instanciateRequestWithRelations>;
export type ClientRequestWithRelations = ReturnType<typeof instanciateClientRequestWithRelations>;

export type ArticleWithRelations = RequestWithRelations['articles'][number];
export type ClientArticleWithRelations = ClientRequestWithRelations['articles'][number];

export type ArticleSnapshotWithRelations = ArticleWithRelations['snapshot'];

export type ArticleDefectWithRelations = ArticleSnapshotWithRelations['articleDefects'][number];
export type ArticleActionWithRelations = ArticleSnapshotWithRelations['articleActions'][number];

export const useRequests = (
  params: Endpoints['GET /requests']['query'] = {},
  options: {
    enabled?: boolean;
  } = {}
) => {
  const fetch = useFetch<Endpoints['GET /requests']>();

  return useQuery({
    queryKey: ['requests', params],
    queryFn: () =>
      fetch('/requests', params).then(({ requests, meta }) => ({
        requests: requests.map(instanciateRequestWithRelations),
        meta,
      })),
    enabled: options.enabled,
  });
};

export const useRequest = (id?: string) => {
  const fetch = useFetch<Endpoints['GET /requests/:id']>();

  return useQuery({
    queryKey: ['requests', id],
    queryFn: () => fetch(`/requests/${id!}`).then(instanciateRequestWithRelations),
    enabled: !!id,
  });
};

export const useClientRequest = (id?: string) => {
  const fetch = useFetch<Endpoints['GET /requests/:id']>();

  return useQuery({
    queryKey: ['requests', id],
    queryFn: () => fetch(`/requests/${id!}`).then(instanciateClientRequestWithRelations),
    enabled: !!id,
  });
};

export const useCreateDraftRequest = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /requests/draft']>();

  return useMutation({
    mutationFn: () => fetch('/requests/draft', undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};

export const useUpdateDraftRequest = (id: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /requests/draft/:id']>();

  return useMutation({
    mutationFn: (data: Endpoints['PATCH /requests/draft/:id']['body']) =>
      fetch(`/requests/draft/${id}`, undefined, {
        method: 'PATCH',
        body: data,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};

export const useUpdateRequestType = (id: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /requests/:id/type']>();

  return useMutation({
    mutationFn: (data: Endpoints['PATCH /requests/:id/type']['body']) =>
      fetch(`/requests/${id}/type`, undefined, {
        method: 'PATCH',
        body: data,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};

export const useUpdateRequestExternalPaymentReference = (requestId: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /requests/:id/external-payment-reference']>();

  return useMutation({
    mutationFn: (data: Endpoints['PATCH /requests/:id/external-payment-reference']['body']) =>
      fetch(`/requests/${requestId}/external-payment-reference`, undefined, {
        method: 'PATCH',
        body: data,
      }),
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: ['requests'] });

      queryClient
        .getQueriesData<RequestWithRelations | { requests: RequestWithRelations[] }>({
          queryKey: ['requests'],
        })
        .forEach(([queryKey, queryData]) => {
          if (queryData && 'requests' in queryData) {
            // Optimistic update for useRequests query
            queryClient.setQueryData(queryKey, {
              ...queryData,
              requests: queryData.requests.map((request) =>
                request.id === requestId
                  ? Object.assign(request, {
                      externalPaymentReference: data.externalPaymentReference,
                    })
                  : request
              ),
            });
          } else if (queryData && queryKey[1] === requestId) {
            // Optimistic update for useRequest query
            queryClient.setQueryData(
              queryKey,
              Object.assign(queryData, { externalPaymentReference: data.externalPaymentReference })
            );
          }
        });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useUpdateRequestSupervisor = (requestId: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /requests/:id/supervisor']>();

  return useMutation({
    mutationFn: ({ supervisor }: { supervisor: UserWithRelations | null }) =>
      fetch(`/requests/${requestId}/supervisor`, undefined, {
        method: 'PATCH',
        body: { supervisorId: supervisor?.id || null },
      }),
    onMutate: async ({ supervisor }) => {
      await queryClient.cancelQueries({ queryKey: ['requests'] });

      queryClient
        .getQueriesData<RequestWithRelations | { requests: RequestWithRelations[] }>({
          queryKey: ['requests'],
        })
        .forEach(([queryKey, queryData]) => {
          if (queryData && 'requests' in queryData) {
            // Optimistic update for useRequests query
            queryClient.setQueryData(queryKey, {
              ...queryData,
              requests: queryData.requests.map((request) =>
                request.id === requestId ? Object.assign(request, { supervisor }) : request
              ),
            });
          } else if (queryData && queryKey[1] === requestId) {
            // Optimistic update for useRequest query
            queryClient.setQueryData(queryKey, Object.assign(queryData, { supervisor }));
          }
        });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useCreateDraftRequestArticle = (id: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /requests/draft/:id/articles']>();

  return useMutation({
    mutationFn: () => fetch(`/requests/draft/${id}/articles`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};

export const useDeleteDraftRequestArticle = (id: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();

  return useMutation({
    mutationFn: (articleId: string) =>
      fetch(`/requests/draft/${id}/articles/${articleId}`, undefined, { method: 'DELETE' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};

export const useSendDraftRequest = (id: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /requests/draft/:id/send']>();

  return useMutation({
    mutationFn: (data: Endpoints['POST /requests/draft/:id/send']['body']) =>
      fetch(`/requests/draft/${id}/send`, undefined, { method: 'POST', body: data }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

export const useCreateDraftGuestRequest = () => {
  const fetch = useFetch<Endpoints['POST /requests/draft-guest']>();

  return useMutation({
    mutationFn: (data: Endpoints['POST /requests/draft-guest']['body']) =>
      fetch('/requests/draft-guest', undefined, {
        method: 'POST',
        body: data,
      }),
  });
};

export const useCreateDraftGuestRequestArticle = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /requests/draft-guest/:id/articles']>();

  return useMutation({
    mutationFn: ({
      id,
      articleData,
    }: {
      id: string;
      articleData: Endpoints['POST /requests/draft-guest/:id/articles']['body'];
    }) =>
      fetch(`/requests/draft-guest/${id}/articles`, undefined, {
        method: 'POST',
        body: articleData,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};

export const useSendClientRequest = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /requests/:id/send']>();

  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      fetch(`/requests/${id}/send`, undefined, {
        method: 'POST',
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};

export const useUpdateRequestClient = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /requests/:id/client']>();

  return useMutation({
    mutationFn: ({
      id,
      body,
    }: {
      id: string;
      body: Endpoints['PATCH /requests/:id/client']['body'];
    }) => fetch(`/requests/${id}/client`, undefined, { method: 'PATCH', body }),
    onMutate: async ({ id, body }) => {
      await queryClient.cancelQueries({ queryKey: ['requests'] });

      queryClient
        .getQueriesData<RequestWithRelations>({
          queryKey: ['requests', id],
        })
        .forEach(([queryKey, queryData]) => {
          if (queryData && queryKey[1] === id) {
            const newData = { ...body };

            if (body.client && queryData.client) {
              newData.client = Object.assign(queryData.client, {
                ...body.client,
                address: Object.assign(queryData.client.address ?? {}, body.client?.address ?? {}),
                billingAddress: Object.assign(
                  queryData.client.billingAddress ?? {},
                  body.client?.billingAddress ?? {}
                ),
              });
            }

            // Optimistic update for useRequest query
            queryClient.setQueryData(queryKey, Object.assign(queryData, newData));
          }
        });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useArchiveRequest = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['DELETE /requests/:id']>();

  return useMutation({
    mutationFn: ({
      id,
      query,
    }: {
      id: string;
      query?: Endpoints['DELETE /requests/:id']['query'];
    }) => fetch(`/requests/${id}`, query, { method: 'DELETE' }),
    onMutate: async ({ id }) => {
      await queryClient.cancelQueries({ queryKey: ['requests'] });

      queryClient
        .getQueriesData<{ requests: RequestWithRelations[] }>({ queryKey: ['requests'] })
        .forEach(([queryKey, queryData]) => {
          if (queryData && 'requests' in queryData) {
            // Optimistic update for useRequests query
            queryClient.setQueryData(queryKey, {
              ...queryData,
              requests: queryData.requests.filter((request) => request.id !== id),
            });
          }
        });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export type RequestActivity =
  Endpoints['GET /requests/:id/activities']['response']['activities'][number];
type RequestActivityType = RequestActivity['type'];
type RequestActivityOfType<T extends RequestActivityType> = Extract<RequestActivity, { type: T }>;

type UseActivitiesParams<T extends RequestActivityType> = {
  limit?: number;
  types?: T[];
};

type UseActivitiesData<T extends RequestActivityType> = {
  activities: RequestActivityOfType<T>[];
  meta: Endpoints['GET /requests/:id/activities']['response']['meta'];
};

export const useActivities = <T extends RequestActivityType>(
  { requestId, ...params }: { requestId: string } & UseActivitiesParams<T>,
  options?: {
    enabled?: boolean;
    refetchInterval?:
      | number
      | false
      | ((
          query: Query<
            UseActivitiesData<T>,
            Error,
            UseActivitiesData<T>,
            (string | UseActivitiesParams<T>)[]
          >
        ) => number | false | undefined);
  }
) => {
  const fetch = useFetch<Endpoints['GET /requests/:id/activities']>();

  return useQuery({
    queryKey: ['activities', requestId, params],
    queryFn: () => fetch<UseActivitiesData<T>>(`/requests/${requestId}/activities`, params),
    refetchInterval: options?.refetchInterval,
    enabled: options?.enabled,
  });
};

export const useInfiniteActivities = ({ requestId }: { requestId: string }) => {
  const fetch = useFetch<Endpoints['GET /requests/:id/activities']>();

  return useInfiniteQuery<
    Endpoints['GET /requests/:id/activities']['response'],
    DefaultError,
    InfiniteData<Endpoints['GET /requests/:id/activities']['response']>,
    QueryKey,
    string | null
  >({
    queryFn: ({ pageParam }) => {
      const requestParams = pageParam ? { before: pageParam } : undefined;

      return fetch(`/requests/${requestId}/activities`, requestParams);
    },
    queryKey: ['activities', requestId],
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage.meta.next,
  });
};

export const useComments = ({
  requestId,
  articleId,
  ...params
}: {
  requestId: string;
  articleId?: string;
  limit?: number;
  offset?: number;
}) => {
  const fetch = useFetch<Endpoints['GET /requests/:id/comments']>();

  return useQuery({
    queryKey: ['comments', requestId, articleId, params],
    queryFn: async () => {
      // FIXME: Is this limit ok? Should we handle pagination?
      const defaultParams = { limit: 250, offset: 0 };

      return fetch(
        articleId
          ? `/requests/${requestId}/articles/${articleId}/comments`
          : `/requests/${requestId}/comments`,
        { ...defaultParams, ...params }
      ).then(({ comments, meta }) => ({
        comments: comments.map((comment) => new Comment(comment)),
        meta,
      }));
    },
  });
};

export const useUpdateComment = ({
  requestId,
  articleId,
}: {
  requestId: string;
  articleId?: string;
}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /requests/:id/comments/:commentId']>();

  return useMutation({
    mutationFn: ({ commentId, content }: { commentId: string; content: string }) => {
      return fetch(
        articleId
          ? `/requests/${requestId}/articles/${articleId}/comments/${commentId}`
          : `/requests/${requestId}/comments/${commentId}`,
        undefined,
        {
          method: 'PATCH',
          body: { content },
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['comments', requestId] });
    },
  });
};

export const useDeleteComment = ({
  requestId,
  articleId,
}: {
  requestId: string;
  articleId?: string;
}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['DELETE /requests/:id/comments/:commentId']>();

  return useMutation({
    mutationFn: ({ commentId }: { commentId: string }) => {
      return fetch(
        articleId
          ? `/requests/${requestId}/articles/${articleId}/comments/${commentId}`
          : `/requests/${requestId}/comments/${commentId}`,
        undefined,
        {
          method: 'DELETE',
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['comments', requestId] });
    },
  });
};

export const useCreateComment = ({
  requestId,
  articleId,
}: {
  requestId: string;
  articleId?: string;
}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /requests/:id/comments']>();

  return useMutation({
    mutationFn: ({ content, visibility }: { content: string; visibility: CommentVisibility }) => {
      return fetch(
        articleId
          ? `/requests/${requestId}/articles/${articleId}/comments`
          : `/requests/${requestId}/comments`,
        undefined,
        {
          method: 'POST',
          body: { content, visibility },
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['comments', requestId] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useValidateExternalPayment = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /requests/:id/validate-external-payment']>();

  return useMutation({
    mutationFn: (id: string) =>
      fetch(`/requests/${id}/validate-external-payment`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useNotifyPendingValidation = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /requests/:id/notify-pending-validation']>();

  return useMutation({
    mutationFn: (id: string) =>
      fetch(`/requests/${id}/notify-pending-validation`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['activities'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useNotifyPendingBillPayment = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /requests/:id/notify-pending-bill-payment']>();

  return useMutation({
    mutationFn: (id: string) =>
      fetch(`/requests/${id}/notify-pending-bill-payment`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['activities'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useValidationChoice = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /requests/:id/validation-choice']>();

  return useMutation({
    mutationFn: ({
      id,
      choices,
    }: {
      id: string;
      choices: Endpoints['POST /requests/:id/validation-choice']['body']['choices'];
    }) =>
      fetch(`/requests/${id}/validation-choice`, undefined, {
        method: 'POST',
        body: { choices },
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const usePaymentChoice = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /requests/:id/payment-choice']>();

  return useMutation({
    mutationFn: ({
      id,
      choices,
    }: {
      id: string;
      choices: Endpoints['POST /requests/:id/payment-choice']['body']['choices'];
    }) =>
      fetch(`/requests/${id}/payment-choice`, undefined, {
        method: 'POST',
        body: { choices },
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};

type Payment = {
  status: TransactionStatus;
};

export const usePayment = (
  {
    requestId,
  }: {
    requestId: string;
  },
  options?: {
    refetchInterval?:
      | number
      | false
      | ((query: Query<Payment, Error, Payment, string[]>) => number | false | undefined);
  }
) => {
  const fetch = useFetch<Endpoints['GET /requests/:id/payment']>();

  return useQuery({
    queryKey: ['requests', requestId, 'payment'],
    queryFn: () => fetch(`/requests/${requestId}/payment`, undefined),
    refetchInterval: options?.refetchInterval,
    ...options,
  });
};

export const useStartPayment = () => {
  const fetch = useFetch<Endpoints['POST /requests/:id/payment']>();

  return useMutation({
    mutationFn: ({ requestId }: { requestId: string }) =>
      fetch(`/requests/${requestId}/payment`, undefined, {
        method: 'POST',
      }),
  });
};

export const useCompletePendingClientPickup = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /requests/:id/complete-pending-client-pickup']>();

  return useMutation({
    mutationFn: (id: string) =>
      fetch(`/requests/${id}/complete-pending-client-pickup`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

export const useRequestInvoice = (requestId: string, { enabled }: { enabled?: boolean } = {}) => {
  const fetch = useFetch<Endpoints['GET /requests/:id/invoice']>();

  return useQuery({
    queryKey: ['requests', requestId, 'invoice'],
    queryFn: () => fetch(`/requests/${requestId}/invoice`),
    enabled,
  });
};

export const useSubmitFeedback = (requestId: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /requests/:id/feedback']>();

  return useMutation({
    mutationFn: (feedback: Feedback) =>
      fetch(`/requests/${requestId}/feedback`, undefined, {
        method: 'POST',
        body: feedback,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};

export const useSubscribeToRequest = (requestId: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();
  const requestQueryKey = ['requests', requestId];

  return useMutation({
    mutationFn: () => fetch(`/requests/${requestId}/subscribe`, undefined, { method: 'POST' }),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: requestQueryKey });

      const previousRequest = queryClient.getQueryData<RequestWithRelations>(requestQueryKey);

      if (!previousRequest) {
        return;
      }

      const newRequest = Object.assign(previousRequest, { hasSubscribed: true });

      queryClient.setQueryData<RequestWithRelations>(requestQueryKey, newRequest);

      return { previousRequest, newRequest };
    },
    onError: (_error, _newRequest, context) => {
      if (context) {
        queryClient.setQueryData<RequestWithRelations>(requestQueryKey, context.previousRequest);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};

export const useUnsubscribeFromRequest = (requestId: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();
  const requestQueryKey = ['requests', requestId];

  return useMutation({
    mutationFn: () => fetch(`/requests/${requestId}/unsubscribe`, undefined, { method: 'POST' }),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: requestQueryKey });

      const previousRequest = queryClient.getQueryData<RequestWithRelations>(requestQueryKey);

      if (!previousRequest) {
        return;
      }

      const newRequest = Object.assign(previousRequest, { hasSubscribed: false });

      queryClient.setQueryData<RequestWithRelations>(requestQueryKey, newRequest);

      return { previousRequest, newRequest };
    },
    onError: (_error, _newRequest, context) => {
      if (context) {
        queryClient.setQueryData<RequestWithRelations>(requestQueryKey, context.previousRequest);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};

export const useCanClaimRequest = ({
  requestId,
  clientToken,
}: {
  requestId?: string | null;
  clientToken?: string | null;
}) => {
  const fetch = useFetch<Endpoints['GET /requests/:id/claim']>();

  return useQuery({
    queryKey: ['requests', requestId, 'claim', clientToken],
    queryFn: () =>
      fetch(`/requests/${requestId}/claim`, { clientToken: clientToken! }, { method: 'GET' }),
    enabled: !!requestId && !!clientToken,
    retry: false,
  });
};

export const useClaimRequest = ({ requestId }: { requestId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /requests/:id/claim']>();

  return useMutation({
    mutationFn: ({ clientToken }: { clientToken: string }) =>
      fetch(`/requests/${requestId}/claim`, undefined, {
        method: 'POST',
        body: { clientToken },
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};
