import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Drawer from '@/design_system/Drawer';
import { DrawerBody, DrawerFooter, DrawerHeader } from '@/design_system/Drawer/Drawer';
import Stack from '@/design_system/Stack';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { ArticlePhotoUpload } from '@/routes/Requests/New/components/ArticlePhotoUpload';
import { useCurrentSession } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';

import { CustomsFields } from './components/CustomsFields';
import {
  ProductCategoryL1,
  ProductCategoryL2,
  ProductCategoryL3,
} from './components/ProductCategories';
import ColorSize from './ColorSize';
import { Brand } from './components';
import ProductInfo from './ProductInfo';

export const ArticleInformationFormDrawer = () => {
  const { currentSession } = useCurrentSession();
  const { request, article, state } = useArticleContext();

  return (
    <Drawer
      isOpen={state.infoPanel.isOpen}
      onOpenChange={() => state.infoPanel.setIsOpen(false)}
      style={{
        maxWidth: '31.25rem',
      }}
    >
      <DrawerHeader>
        <h1 className="headline-300-bold">
          <Trans id="article.edit.title">Edit information</Trans>
        </h1>
      </DrawerHeader>

      <DrawerBody>
        <ErrorBoundary>
          <Stack gap="1rem" padding="1rem 1.5rem">
            <ArticlePhotoUpload />

            {!currentSession?.workshop?.external && <ProductInfo row={false} showReference />}

            <ProductCategoryL1 />

            <ProductCategoryL2 />

            {!!request.organization.brands.length && <Brand />}

            <ProductCategoryL3 />

            <ColorSize request={request} article={article} />

            <CustomsFields request={request} article={article} />
          </Stack>
        </ErrorBoundary>
      </DrawerBody>

      <DrawerFooter>
        <Button onPress={() => state.infoPanel.setIsOpen(false)}>
          <Trans id="article.edit.save">Save</Trans>
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
