import isNil from 'lodash/isNil';

import { getBrowserLocale } from '@/services/i18n';
export const formatNumber = (value?: number | null, options: Intl.NumberFormatOptions = {}) => {
  if (isNil(value)) {
    return '-';
  }

  const locale = getBrowserLocale();

  return new Intl.NumberFormat(locale, options).format(value);
};

export const formatWeight = (value?: number | null) => {
  if (isNil(value)) {
    return '-';
  }

  return formatNumber(value / 1000, { style: 'unit', unit: 'kilogram', unitDisplay: 'short' });
};

export const formatCurrency = (
  value?: number | null,
  currency?: string | null,
  options: Omit<Intl.NumberFormatOptions, 'currency' | 'style'> = {}
) => {
  if (isNil(value) || isNil(currency)) {
    return '-';
  }

  // We handle everything in cents.
  // In the future, we may have to handle zero decimal currencies.
  // See https://stripe.com/docs/currencies
  value /= 100;

  const locale = getBrowserLocale();

  return new Intl.NumberFormat(locale, {
    ...options,
    style: 'currency',
    currency,
  }).format(value);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CURRENCIES = ['EUR', 'GBP', 'NOK', 'USD', 'CHF'] as const;
export type Currency = (typeof CURRENCIES)[number];
