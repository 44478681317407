import { Fragment, useContext } from 'react';
import { GridList, GridListItem } from 'react-aria-components';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  ArticleDeleteArticleActionCell,
  ArticleIssueCell,
  ArticleNameCell,
  ArticlePrintArticleSheetActionCell,
  ArticleReceivedVerificationActionCell,
  ArticleReceivedVerificationStatusCell,
  ArticleRequestCardItem,
  ArticleRequestCell,
  ArticleWeightCardItem,
  ArticleWeightCell,
  StartAnalysis,
} from '@/components/ArticlesTableCells';
import { Card, CardActions, CardContent, CardItems } from '@/components/Card/Card';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import { useArticleName } from '@/models/article';
import { ShipmentWithRelations } from '@/models/shipment';
import { ShipmentArticlesContext } from '@/routes/Shipments/Shipment/components/ShipmentArticles/ShipmentArticles';
import { useCurrentSession } from '@/services/auth';

type ShipmentArticle = ShipmentWithRelations['articles'][number];

export const ArticlesTable = ({ shipmentArticles }: { shipmentArticles: ShipmentArticle[] }) => {
  const { _ } = useLingui();
  const {
    showVerificationStatus,
    showVerificationActions,
    showPrintArticleSheetActions,
    showDeleteArticleAction,
    showStartAnalysisColumn,
  } = useContext(ShipmentArticlesContext);

  const columnWidths = {
    items: 'minmax(150px, 3fr)',
    status: showVerificationStatus && 'minmax(150px, 2fr)',
    request: 'minmax(200px, 2fr)',
    weight: 'auto',
    actions:
      showVerificationActions || showPrintArticleSheetActions || showDeleteArticleAction
        ? 'minmax(120px, 1fr)'
        : showStartAnalysisColumn
          ? 'minmax(120px, 2fr)'
          : undefined,
  };

  return (
    <Table
      ariaLabel={_(msg({ id: 'shipment.articles.table.label', message: 'Items' }))}
      columnWidths={[
        columnWidths['items'],
        columnWidths['status'],
        columnWidths['request'],
        columnWidths['weight'],
        columnWidths['actions'],
      ]}
    >
      <ArticlesTableHeader />
      <Body>
        {shipmentArticles.map((shipmentArticle) => (
          <Fragment key={shipmentArticle.articleId}>
            <ArticlesTableRow shipmentArticle={shipmentArticle} />
            {shipmentArticle.issue && <ArticleIssueRow shipmentArticle={shipmentArticle} />}
          </Fragment>
        ))}
      </Body>
    </Table>
  );
};

const ArticlesTableHeader = () => {
  const { _ } = useLingui();
  const { showVerificationStatus, showActionsColumn } = useContext(ShipmentArticlesContext);

  return (
    <Header>
      <Row>
        <Column>
          <Trans id="shipment.articles.table.articles.label">Items</Trans>
        </Column>
        {showVerificationStatus && (
          <Column>
            <Trans id="shipment.articles.table.status.label">Status</Trans>
          </Column>
        )}
        <Column>
          <Trans id="shipment.articles.table.request.label">Request</Trans>
        </Column>
        <Column>
          <Trans id="shipment.articles.table.weight.label">Weight</Trans>
        </Column>
        {showActionsColumn && (
          <Column
            ariaLabel={_(
              msg({
                id: 'shipment.articles.table.actions.label',
                message: 'Actions',
              })
            )}
          />
        )}
      </Row>
    </Header>
  );
};

const ArticlesTableRow = ({ shipmentArticle }: { shipmentArticle: ShipmentArticle }) => {
  const { currentSession } = useCurrentSession();
  const {
    showVerificationStatus,
    showVerificationActions,
    showPrintArticleSheetActions,
    showDeleteArticleAction,
    showActionsColumn,
    shipment,
  } = useContext(ShipmentArticlesContext);

  const article = shipmentArticle.article;

  const canAnalyseArticles = currentSession?.hasPermission('analyze_article', {
    workshopId: shipmentArticle.article.workshopId,
  });

  const showStartAnalysis = canAnalyseArticles && shipmentArticle.article.step?.step === 'analysis';

  return (
    <Row>
      <Cell>
        <ArticleNameCell article={article} id={article.id} />
      </Cell>
      {showVerificationStatus && (
        <Cell>
          <ArticleReceivedVerificationStatusCell shipmentArticle={shipmentArticle} />
        </Cell>
      )}
      <Cell>
        <ArticleRequestCell article={article} organization={shipment.organization} />
      </Cell>
      <Cell>
        <ArticleWeightCell article={article} />
      </Cell>

      {showActionsColumn && (
        <Cell justifyContent={showStartAnalysis ? 'center' : 'end'}>
          {showVerificationActions && (
            <ArticleReceivedVerificationActionCell
              shipmentArticle={shipmentArticle}
              shipment={shipment}
              key={`${shipmentArticle.verified}`}
            />
          )}

          {(showPrintArticleSheetActions || showDeleteArticleAction) && (
            <Stack row gap="0.5rem">
              {showPrintArticleSheetActions && (
                <ArticlePrintArticleSheetActionCell article={article} />
              )}
              {showDeleteArticleAction && (
                <ArticleDeleteArticleActionCell
                  articleId={article.id}
                  shipmentId={shipment.id}
                  isDisabled={shipment.articles.length === 1}
                />
              )}
            </Stack>
          )}

          {showStartAnalysis && (
            <StartAnalysis requestId={article.requestId} articleId={article.id} />
          )}
        </Cell>
      )}
    </Row>
  );
};

const ArticleIssueRow = ({ shipmentArticle }: { shipmentArticle: ShipmentArticle }) => {
  return (
    <Row isExtensionRow>
      <Cell isWholeRow>
        <ArticleIssueCell issue={shipmentArticle.issue ?? ''} />
      </Cell>
    </Row>
  );
};

export const ArticlesCardList = ({ shipmentArticles }: { shipmentArticles: ShipmentArticle[] }) => {
  const { _ } = useLingui();

  return (
    <GridList
      aria-label={_(
        msg({
          id: 'shipment.articles.table.label',
          message: 'Items',
        })
      )}
    >
      {shipmentArticles.map((shipmentArticle) => (
        <ArticleCard key={shipmentArticle.articleId} shipmentArticle={shipmentArticle} />
      ))}
    </GridList>
  );
};

const ArticleCard = ({ shipmentArticle }: { shipmentArticle: ShipmentArticle }) => {
  const { currentSession } = useCurrentSession();
  const {
    showVerificationStatus,
    showVerificationActions,
    showPrintArticleSheetActions,
    showDeleteArticleAction,
    shipment,
  } = useContext(ShipmentArticlesContext);
  const articleName = useArticleName({ article: shipmentArticle.article });

  const article = shipmentArticle.article;

  const canAnalyseArticles = currentSession?.hasPermission('analyze_article', {
    workshopId: shipmentArticle.article.workshopId,
  });

  const showStartAnalysis = canAnalyseArticles && shipmentArticle.article.step?.step === 'analysis';

  const showActions =
    showVerificationActions ||
    showPrintArticleSheetActions ||
    showDeleteArticleAction ||
    showStartAnalysis;

  return (
    <GridListItem textValue={articleName} style={{ marginBottom: '0.5rem' }}>
      <Card>
        <CardContent>
          <ArticleNameCell
            id={article.id}
            article={article}
            badge={
              showVerificationStatus && (
                <ArticleReceivedVerificationStatusCell shipmentArticle={shipmentArticle} />
              )
            }
          />
        </CardContent>
        <CardItems>
          <ArticleRequestCardItem article={article} />
          <ArticleWeightCardItem article={article} />
        </CardItems>
        {!!shipmentArticle.issue && <ArticleIssueCell issue={shipmentArticle.issue} />}
        {showActions && (
          <CardActions>
            {showVerificationActions && (
              <ArticleReceivedVerificationActionCell
                shipmentArticle={shipmentArticle}
                shipment={shipment}
                key={`${shipmentArticle.verified}`}
              />
            )}
            {showDeleteArticleAction && (
              <ArticleDeleteArticleActionCell
                articleId={article.id}
                shipmentId={shipment.id}
                isDisabled={shipment.articles.length === 1}
              />
            )}
            {showPrintArticleSheetActions && (
              <ArticlePrintArticleSheetActionCell article={article} />
            )}
            {showStartAnalysis && (
              <StartAnalysis requestId={article.requestId} articleId={article.id} />
            )}
          </CardActions>
        )}
      </Card>
    </GridListItem>
  );
};
