import { useEffect } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import { GenericError } from '@/components/GenericError/GenericError';
import IllustrationError from '@/components/IllustrationError';
import Loader from '@/components/Loader';
import {
  PageLayout,
  PageLayoutCenter,
  PageLayoutContent,
  PageLayoutHeader,
  PageLayoutLeftPart,
  PageLayoutLeftPartContent,
  PageLayoutRightPart,
  PageLayoutTopPart,
} from '@/components/PageLayout';
import { ScrollToAnchor } from '@/components/ScrollToAnchor/ScrollToAnchor';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import { useRequest } from '@/models/request';
import { useWorkflow } from '@/models/workflow';
import { RequestContextProvider } from '@/routes/Requests/contexts/RequestContext';
import { RequestActivities } from '@/routes/Requests/Request/components/Activities/RequestActivities';
import { RequestArticles } from '@/routes/Requests/Request/components/RequestArticles';
import { RequestBreadcrumb } from '@/routes/Requests/Request/components/RequestBreadcrumb/RequestBreadcrumb';
import { RequestHeader } from '@/routes/Requests/Request/components/RequestHeader';
import {
  RequestInfoSidePanel,
  RequestInfoTopPanel,
} from '@/routes/Requests/Request/components/RequestInfo';
import ShipmentsTable from '@/routes/Requests/Request/components/ShipmentsTable';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';
import { NotFoundError } from '@/utils/error/NotFoundError';
import { useResetSimpleBlocker } from '@/utils/navigation';
import { refreshBrowserTabTitle } from '@/utils/refreshBrowserTabTitle';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import { RequestActionsBar } from './components/RequestActionsBar/RequestActionsBar';
import { RequestComments } from './components/RequestComments';
import { RequestStepActions } from './components/RequestStepActions/RequestStepActions';
import { RequestStepMessage } from './components/RequestStepMessage/RequestStepMessage';

import './Request.css';

const { block, element } = createBEMClasses('request');

export const Request = () => {
  const [commentsRef, scrollCommentsIntoView] = useScrollIntoView<HTMLDivElement>();
  const [containerRef, scrollContainerIntoView] = useScrollIntoView<HTMLDivElement>();

  const { isMobile } = useViewPort();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { data: request, isLoading: isRequestLoading, isError, error } = useRequest(id);
  const { data: workflow, isLoading: isWorkflowLoading } = useWorkflow(request?.workflowId);

  const redirectToMonoArticle = searchParams.get('redirectToMonoArticle') === 'true';

  // Display the request reference in the browser tab title
  useEffect(() => {
    if (!request) {
      return;
    }

    const requestDetails = request.client?.name ?? request.store?.name;
    const requestReference = requestDetails
      ? `${request.reference} - ${requestDetails}`
      : request.reference;

    document.querySelector('meta[name="subtitle"]')?.setAttribute('content', requestReference);
    refreshBrowserTabTitle();

    return () => {
      document.querySelector('meta[name="subtitle"]')?.setAttribute('content', '');
      refreshBrowserTabTitle();
    };
  }, [request]);

  // Clear router blocker state after coming from requests/new
  useResetSimpleBlocker();

  if (isRequestLoading || isWorkflowLoading) {
    return (
      <PageLayoutCenter>
        <Loader style={{ height: '40px', width: '40px' }} />
        <p className="paragraph-100-regular">
          <Trans id="_general.loading">Loading...</Trans>
        </p>
      </PageLayoutCenter>
    );
  }

  if (isError || !request) {
    return <RequestError error={error} />;
  }

  if (redirectToMonoArticle && request.allArticles.length === 1) {
    return <Navigate to={`/requests/${request.id}/articles/${request.allArticles[0].id}`} />;
  }

  return (
    <RequestContextProvider request={request} workflow={workflow}>
      <PageLayout className={block()}>
        <PageLayoutHeader>
          <RequestHeader request={request} onCommentButtonPress={scrollCommentsIntoView} />
        </PageLayoutHeader>

        <PageLayoutContent>
          <PageLayoutLeftPart>
            <PageLayoutLeftPartContent>
              {isMobile && (
                <PageLayoutTopPart>
                  <RequestInfoTopPanel request={request} />
                </PageLayoutTopPart>
              )}
              <Stack className={element('main')} ref={containerRef}>
                <Stack gap="1rem">
                  {!isMobile && <RequestBreadcrumb request={request} />}
                  <ErrorBoundary>
                    <RequestStepMessage request={request} />
                  </ErrorBoundary>
                  <RequestStepActions request={request} />

                  <ErrorBoundary>
                    <RequestArticles />
                  </ErrorBoundary>
                </Stack>
                {!(
                  request.isInDraftStep ||
                  request.isInFirstServiceChoiceStep ||
                  request.isInValidationStep
                ) && (
                  <Stack gap="0.5rem">
                    <h2 className="headline-400-bold">
                      <Trans id="request.related-shipments">Deliveries</Trans>
                    </h2>

                    <ErrorBoundary>
                      <Box
                        padding="0"
                        removeStyleOnMobile
                        className={element('shipments', { 'no-background': isMobile })}
                        gap="1rem"
                      >
                        <ShipmentsTable request={request} />
                      </Box>
                    </ErrorBoundary>
                  </Stack>
                )}
                <Stack gap={isMobile ? undefined : '0.5rem'} ref={commentsRef}>
                  <h2 className="headline-400-bold">
                    <Trans id="request.comment.title">Comments</Trans>
                  </h2>

                  <ErrorBoundary>
                    <Box
                      className={element('comment', { 'no-background': isMobile })}
                      padding="0"
                      removeStyleOnMobile
                    >
                      <RequestComments requestId={request.id} />
                    </Box>
                  </ErrorBoundary>
                </Stack>
                <Stack gap={isMobile ? undefined : '0.5rem'}>
                  <h2 className="headline-400-bold" id="activity-section">
                    <Trans id="request.activity.title">Activity</Trans>
                  </h2>

                  <ErrorBoundary>
                    <Box
                      className={element('activity', { 'no-background': isMobile })}
                      padding="0"
                      removeStyleOnMobile
                    >
                      <RequestActivities request={request} />
                    </Box>
                  </ErrorBoundary>
                </Stack>
              </Stack>
            </PageLayoutLeftPartContent>

            <ErrorBoundary>
              <RequestActionsBar request={request} onActionDone={scrollContainerIntoView} />
            </ErrorBoundary>
          </PageLayoutLeftPart>

          {!isMobile && (
            <PageLayoutRightPart>
              <RequestInfoSidePanel request={request} />
            </PageLayoutRightPart>
          )}
        </PageLayoutContent>

        <ScrollToAnchor />
      </PageLayout>
    </RequestContextProvider>
  );
};

const RequestError = ({ error }: { error: Error | null }) => {
  let errorMessage = <GenericError />;

  if (error instanceof NotFoundError) {
    errorMessage = (
      <p className="paragraph-100-regular">
        <Trans id="request.error.not-found">Unknown request or you do not have access to it.</Trans>
      </p>
    );
  }

  return (
    <PageLayoutCenter>
      <IllustrationError />
      {errorMessage}
    </PageLayoutCenter>
  );
};
