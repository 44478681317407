import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import TextArea from '@/design_system/TextArea';
import { useCreateDefect, useUpdateDefect } from '@/models/article';
import { ArticleDefectWithRelations } from '@/models/request';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';

export const CustomDefectDialog = ({
  isOpen,
  onClose,
  initialCustomDefect,
}: {
  isOpen: boolean;
  onClose: () => void;
  initialCustomDefect?: ArticleDefectWithRelations;
}) => {
  const { _ } = useLingui();

  return (
    <Dialog
      title={
        initialCustomDefect
          ? _(msg({ id: 'article.form.custom-defect.dialog.title.edit', message: 'Edit defect' }))
          : _(msg({ id: 'article.form.custom-defect.dialog.title', message: 'Add a new defect' }))
      }
      isOpen={isOpen}
      onOpenChange={onClose}
    >
      <CustomDefectForm onClose={onClose} initialCustomDefect={initialCustomDefect} />
    </Dialog>
  );
};

const CustomDefectForm = ({
  onClose,
  initialCustomDefect,
}: {
  onClose: () => void;
  initialCustomDefect?: ArticleDefectWithRelations;
}) => {
  const { _ } = useLingui();

  const { request } = useRequestContext();
  const { article } = useArticleContext();

  const { mutate: createDefect } = useCreateDefect({
    articleId: article.id,
    requestId: request.id,
  });
  const { mutate: updateDefect } = useUpdateDefect({
    articleId: article.id,
    requestId: request.id,
  });

  const [description, setDescription] = useState(initialCustomDefect?.customDescription ?? '');

  const [showErrors, setShowErrors] = useState(false);

  const isDescriptionInvalid = !description;

  const onSave = () => {
    if (isDescriptionInvalid) {
      setShowErrors(true);
      return;
    }

    if (initialCustomDefect) {
      updateDefect({
        defectId: initialCustomDefect.id,
        body: {
          description,
        },
      });
    } else {
      createDefect({ description });
    }

    onClose();
  };

  return (
    <>
      <main>
        <TextArea
          label={_(
            msg({
              id: 'article.form.custom-defect.dialog.description.label',
              message: 'Description',
            })
          )}
          placeholder={_(
            msg({
              id: 'article.form.custom-defect.dialog.description.placeholder',
              message: `Describe the item's defect...`,
            })
          )}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          error={
            showErrors && isDescriptionInvalid
              ? _(
                  msg({
                    id: 'article.form.custom-defect.dialog.description.error',
                    message: 'Please add a defect description',
                  })
                )
              : undefined
          }
        />
      </main>
      <footer>
        <Button variant="secondary" onPress={onClose}>
          <Trans id="article.form.custom-defect.dialog.cancel">Cancel</Trans>
        </Button>
        <Button variant="primary" onPress={onSave}>
          {initialCustomDefect ? (
            <Trans id="article.form.custom-defect.dialog.save">Save</Trans>
          ) : (
            <Trans id="article.form.custom-defect.dialog.add">Add a new defect</Trans>
          )}
        </Button>
      </footer>
    </>
  );
};
