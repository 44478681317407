import { useContext, useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNil from 'lodash/isNil';

import { ActionsTableContext } from '@/components/ActionsTable/ActionsTable';
import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import { InputMoney } from '@/design_system/InputNumber';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import { getCustomPriceAmount } from '@/models/actionType';
import { useCreateAction, useUpdateAction } from '@/models/article';
import { ArticleActionWithRelations } from '@/models/request';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { useCurrentSession } from '@/services/auth';

const CustomActionDialog = ({
  isOpen,
  onClose,
  initialCustomAction,
}: {
  isOpen: boolean;
  onClose: () => void;
  initialCustomAction?: ArticleActionWithRelations;
}) => {
  const { _ } = useLingui();

  return (
    <Dialog
      title={
        initialCustomAction
          ? _(msg({ id: 'article.form.custom-action.dialog.title.edit', message: 'Edit action' }))
          : _(msg({ id: 'article.form.custom-action.dialog.title', message: 'Add a new action' }))
      }
      isOpen={isOpen}
      onOpenChange={onClose}
    >
      <CustomActionForm onClose={onClose} initialCustomAction={initialCustomAction} />
    </Dialog>
  );
};

const CustomActionForm = ({
  onClose,
  initialCustomAction,
}: {
  onClose: () => void;
  initialCustomAction?: ArticleActionWithRelations;
}) => {
  const { _ } = useLingui();
  const { isWorkshop } = useCurrentSession();

  const { request, view: requestView } = useRequestContext();
  const { article, view: articleView } = useArticleContext();
  const { customActionWorkshopPriceCurrency, customActionOrganizationPriceCurrency } =
    useContext(ActionsTableContext);

  const { mutate: createAction } = useCreateAction({
    articleId: article.id,
    requestId: request.id,
  });
  const { mutate: updateAction } = useUpdateAction({
    articleId: article.id,
    requestId: request.id,
  });

  const showWorkshopPrice = isWorkshop
    ? requestView.price.enabled && articleView.services.actions.canEditPrice
    : requestView.cost.enabled && articleView.services.actions.canEditCost;
  const showOrganizationPrice =
    !isWorkshop && requestView.price.enabled && articleView.services.actions.canEditPrice;

  const showWorkshopAmountBeforeTaxes = isWorkshop
    ? requestView.price.showAmountBeforeTaxes
    : requestView.cost.showAmountBeforeTaxes;
  const showOrganizationAmountBeforeTaxes = requestView.price.showAmountBeforeTaxes;

  const workshopPriceLabel = isWorkshop ? requestView.price.label : requestView.cost.label;
  const organizationPriceLabel = requestView.price.label;

  const [description, setDescription] = useState(initialCustomAction?.customDescription ?? '');
  const [workshopPrice, setWorkshopPrice] = useState<number | undefined>(
    getCustomPriceAmount({
      customPrice: initialCustomAction?.customWorkshopPrice,
      showAmountBeforeTaxes: showWorkshopAmountBeforeTaxes,
    })
  );
  const [organizationPrice, setOrganizationPrice] = useState<number | undefined>(
    getCustomPriceAmount({
      customPrice: initialCustomAction?.customOrganizationPrice,
      showAmountBeforeTaxes: showOrganizationAmountBeforeTaxes,
    })
  );

  const [showErrors, setShowErrors] = useState(false);

  const isDescriptionInvalid = !description;
  const isWorkshopPriceInvalid = isNil(workshopPrice) || Number.isNaN(workshopPrice);
  const isOrganizationPriceInvalid = isNil(organizationPrice) || Number.isNaN(organizationPrice);

  const onSave = () => {
    if (
      isDescriptionInvalid ||
      (showWorkshopPrice && isWorkshopPriceInvalid) ||
      (showOrganizationPrice && isOrganizationPriceInvalid)
    ) {
      setShowErrors(true);
      return;
    }

    const fields = {
      description,
      customWorkshopPrice:
        showWorkshopPrice && !isWorkshopPriceInvalid
          ? showWorkshopAmountBeforeTaxes
            ? { amountBeforeTaxes: workshopPrice }
            : { amount: workshopPrice }
          : undefined,
      customOrganizationPrice:
        showOrganizationPrice && !isOrganizationPriceInvalid
          ? showOrganizationAmountBeforeTaxes
            ? { amountBeforeTaxes: organizationPrice }
            : { amount: organizationPrice }
          : undefined,
    };

    if (initialCustomAction) {
      updateAction({ actionId: initialCustomAction.id, body: fields });
    } else {
      createAction(fields);
    }

    onClose();
  };

  return (
    <>
      <main>
        <Stack gap="1rem">
          <TextArea
            label={_(
              msg({
                id: 'article.form.custom-action.dialog.description.label',
                message: 'Description',
              })
            )}
            placeholder={_(
              msg({
                id: 'article.form.custom-action.dialog.description.placeholder',
                message: 'Describe the action needed for the item...',
              })
            )}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={
              showErrors && isDescriptionInvalid
                ? _(
                    msg({
                      id: 'article.form.custom-action.dialog.description.error',
                      message: 'Please add an action description',
                    })
                  )
                : undefined
            }
          />
          {showWorkshopPrice && (
            <InputMoney
              label={workshopPriceLabel}
              placeholder={
                isWorkshop
                  ? _(
                      msg({
                        id: 'article.form.custom-action.dialog.client-price.placeholder',
                        message: 'Add a price for the action...',
                      })
                    )
                  : _(
                      msg({
                        id: 'article.form.custom-action.dialog.internal-cost.placeholder',
                        message: 'Add a cost for the action...',
                      })
                    )
              }
              value={workshopPrice}
              onChange={setWorkshopPrice}
              currency={customActionWorkshopPriceCurrency}
              error={
                showErrors && isWorkshopPriceInvalid
                  ? isWorkshop
                    ? _(
                        msg({
                          id: 'article.form.custom-action.dialog.price.error',
                          message: 'Please add a price',
                        })
                      )
                    : _(
                        msg({
                          id: 'article.form.custom-action.dialog.internal-cost.error',
                          message: 'Please add an internal cost',
                        })
                      )
                  : undefined
              }
            />
          )}
          {showOrganizationPrice && (
            <InputMoney
              label={organizationPriceLabel}
              placeholder={_(
                msg({
                  id: 'article.form.custom-action.dialog.client-price.placeholder',
                  message: 'Add a price for the action...',
                })
              )}
              value={organizationPrice}
              onChange={setOrganizationPrice}
              currency={customActionOrganizationPriceCurrency}
              error={
                showErrors && isOrganizationPriceInvalid
                  ? _(
                      msg({
                        id: 'article.form.custom-action.dialog.client-price.error',
                        message: 'Please add a client price',
                      })
                    )
                  : undefined
              }
            />
          )}
        </Stack>
      </main>
      <footer>
        <Button variant="secondary" onPress={onClose}>
          <Trans id="article.form.custom-action.dialog.cancel">Cancel</Trans>
        </Button>
        <Button variant="primary" onPress={onSave}>
          {initialCustomAction ? (
            <Trans id="article.form.custom-action.dialog.save">Save</Trans>
          ) : (
            <Trans id="article.form.custom-action.dialog.add">Add a new action</Trans>
          )}
        </Button>
      </footer>
    </>
  );
};

export default CustomActionDialog;
