import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import IconFile from '@/icons/File.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const PendingDispatchAcceptationNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'pending_dispatch_acceptation'>;
}) => {
  const { _ } = useLingui();

  const requestReference = notification.request.reference;
  const workshopName = notification.data.onBehalfOfWorkshop?.name;

  return (
    <BaseNotification
      notificationLabel={
        notification.data.onBehalfOfWorkshop
          ? _(
              msg({
                id: 'notification.pending-dispatch-acceptation.label.on-behalf',
                message: `New job to validate for request ${requestReference} on behalf of ${workshopName}`,
              })
            )
          : _(
              msg({
                id: 'notification.pending-dispatch-acceptation.label',
                message: `New job to validate for request ${requestReference}`,
              })
            )
      }
      icon={<IconFile />}
      type="new"
      notification={notification}
      notificationHeader={
        notification.data.onBehalfOfWorkshop ? (
          <Trans id="notification.pending-dispatch-acceptation.on-behalf">
            <span className="paragraph-100-medium">You</span> have a new job to validate on behalf
            of {workshopName}.
          </Trans>
        ) : (
          <Trans id="notification.pending-dispatch-acceptation">
            <span className="paragraph-100-medium">You</span> have a new job to validate
          </Trans>
        )
      }
    />
  );
};
